import { ref } from 'vue';
import { toPng } from 'html-to-image';

const loadingImage = ref(false);
const openShareModal = ref(false);
const imageDataUrl = ref('');

const imageDimensions = ref(null);

const uploadedImageUrl = ref(null);

const filter = (node) => {
  const exclusionClasses = ['exclude'];
  return !exclusionClasses.some((classname) =>
    node.classList?.contains(classname),
  );
};

const style = {
  bg_warm: {
    background: 'linear-gradient(137.36deg, #FF3D00 -59.82%, #1A0917 76.03%)',
  },
  bg_lukewarm: {
    background:
      'linear-gradient(192.25deg, rgb(255, 184, 0) -64.04%, rgb(190, 134, 2) -19.31%, #1C0C06 91.08%)',
  },
  bg_chilly: {
    background:
      'linear-gradient(180deg, #028C8C -28.51%, rgb(7, 36, 43) 50.87%)',
  },
  bg_frozen: {
    background: 'linear-gradient(180deg, #394988 -27.16%, #020E24 100%)',
  },
  bg_subzero: {
    background: 'linear-gradient(359.05deg, #000000 0.77%, #09002C 80.84%)',
  },
};

const gradiantClasses = [
  'warm-text-gradient',
  'lukewarm-text-gradient',
  'chilly-text-gradient',
  'frozen-text-gradient',
  'subzero-text-gradient',
];

const makePng = async (
  id,
  background_class = null,
  modalOpener = null,
  dimensions = null,
  shareElemId = null,
) => {
  if (dimensions) imageDimensions.value = dimensions;
  overrideXML();
  try {
    loadingImage.value = true;
    let node = document.getElementById(id);
    let shareNode = document.getElementById(shareElemId);
    imageDataUrl.value = await toPng(node, {
      style: style[background_class],
      quality: 1,
      filter,
    });
    uploadedImageUrl.value = await toPng(shareNode || node, {
      style: style[background_class],
      quality: 1,
      filter,
      canvasWidth: 800,
      canvasHeight: 418,
    });
  } catch (e) {
    loadingImage.value = false;
  } finally {
    loadingImage.value = false;
  }
};

const overrideXML = () => {
  const { serializeToString } = XMLSerializer.prototype;
  const clearTextBackgroundClip = (element) => {
    if (gradiantClasses.some((x) => element.classList?.contains(x))) {
      element.style.setProperty('-webkit-background-clip', 'unset');
      element.style.setProperty('--background-clip-replace', 'text');
    }
    element.childNodes.forEach((child) => {
      clearTextBackgroundClip(child);
    });
  };
  XMLSerializer.prototype.serializeToString = function (node) {
    clearTextBackgroundClip(node);
    return serializeToString
      .call(this, node)
      .replaceAll('--background-clip-replace', '-webkit-background-clip');
  };
};

export {
  makePng,
  loadingImage,
  openShareModal,
  imageDataUrl,
  imageDimensions,
  uploadedImageUrl,
};
