<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M11.43 3.57145C11.3454 3.49769 11.2408 3.45074 11.1295 3.43655C11.0181 3.42236 10.9051 3.44158 10.8047 3.49177L8.45109 4.66364L6.48609 1.39973C6.43558 1.31598 6.36428 1.2467 6.27912 1.19861C6.19395 1.15052 6.0978 1.12524 6 1.12524C5.90219 1.12524 5.80604 1.15052 5.72088 1.19861C5.63571 1.2467 5.56442 1.31598 5.5139 1.39973L3.5489 4.66505L1.19625 3.49317C1.0961 3.44343 0.983483 3.42438 0.872543 3.43841C0.761603 3.45244 0.657277 3.49892 0.572657 3.57202C0.488038 3.64513 0.426895 3.74159 0.3969 3.84932C0.366906 3.95705 0.369397 4.07123 0.404061 4.17755L2.13844 9.4913C2.15587 9.54468 2.18508 9.59346 2.2239 9.63405C2.26271 9.67463 2.31015 9.70598 2.3627 9.72578C2.41525 9.74558 2.47158 9.75332 2.52753 9.74843C2.58348 9.74355 2.63761 9.72616 2.68594 9.69755C2.69765 9.69052 3.89625 9.00005 6 9.00005C8.10375 9.00005 9.30234 9.69052 9.31312 9.69708C9.36147 9.72596 9.41569 9.74359 9.47178 9.74865C9.52787 9.75371 9.58437 9.74607 9.6371 9.7263C9.68983 9.70653 9.73744 9.67514 9.77637 9.63446C9.81531 9.59377 9.84459 9.54484 9.86203 9.4913L11.5964 4.17895C11.6321 4.07261 11.6353 3.95806 11.6056 3.84988C11.576 3.7417 11.5149 3.64478 11.43 3.57145ZM9.28125 8.85145C8.71875 8.61286 7.5975 8.25005 6 8.25005C4.4025 8.25005 3.28125 8.61286 2.71875 8.85145L1.25203 4.35942L3.36469 5.4127C3.49256 5.47577 3.63942 5.48866 3.77633 5.44883C3.91324 5.40901 4.03027 5.31936 4.10437 5.19755L6 2.04661L7.89562 5.19661C7.96971 5.31831 8.08666 5.40787 8.22346 5.44769C8.36027 5.48751 8.50701 5.47469 8.63484 5.41177L10.748 4.35942L9.28125 8.85145Z"
      fill="currentColor"
    />
  </svg>
</template>
