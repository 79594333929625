<template>
  <div>
    <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import moment from 'moment';
import { formatNumber } from '../composeables/filters.js';

const props = defineProps({
  series: {
    type: Array,
    default: () => [],
  },
  config: {
    type: Object,
    default: () => {},
  },
});

const chartOptions = computed(() => {
  return {
    chart: {
      backgroundColor: 'transparent',
      type: 'line',
      animation: false,
      height: props.config?.height || '365',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      tickInterval: props.config?.tickInterval || '',
      endOnTick: props.config?.endOnTick || false,
      labels: {
        enabled: true,
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
        formatter: function () {
          return props.config?.useUTC
            ? moment(this.value).utc().format('MMM DD')
            : moment(this.value).format('MMM DD');
        },
      },
      lineWidth: 0,
      tickLength: 0,
    },
    yAxis: props.config?.yAxis,
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: 'transparent',
      borderWidth: 0,
      color: '#ffff',
      style: {
        color: '#ffff',
      },
      formatter() {
        let title = this.x;
        title = typeof title === 'string' ? parseInt(title) : title;
        title = props.config?.useUTC
          ? moment(title).utc().format('MMM DD, YYYY')
          : moment(title).format('MMM DD, YYYY');
        const tipHtml = `<div><b>${title}</b></div>`;
        const formattedPoints = this.points
          .map((point) => {
            const roundTo = point.y > 1 ? 2 : 6;
            return `<div class="py-1">
              <span>${point.series.name}:</span>
              <span style="color:${
                point.color
              }; margin-left: 0.5rem;">${formatNumber(point.y, roundTo)}</span>
              </div>
            `;
          })
          .join('');
        return `<div class="custom-tooltip"> ${
          tipHtml + formattedPoints
        } </div>`;
      },
    },
    legend: {
      ...(props.config?.legend
        ? props.config?.legend
        : {
            symbolWidth: -15, // Adjust the size of the legend symbol as needed
            symbolHeight: -15,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            itemStyle: {
              color: '#F5F5F5', // Set the color of the legend text
              fontWeight: 'bold', // Set the font weight of the legend text
            },
            itemHoverStyle: {
              color: '#646666', // Set the color of the legend text on hover
            },
            itemMarginBottom: 10, // Adjust the margin between legend items as needed
            labelFormatter() {
              // Custom label formatter function to display legend items with color circle symbols
              return `<span style="color: ${this.color}; font-weight: bold;">●</span> ${this.name}`;
            },
          }),
    },
    series: props.series,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 768,
          },
          chartOptions: {
            chart: {
              height: props.config?.height ? props.config?.height : '350',
            },
            legend: props.config?.legend
              ? { ...props.config.legend }
              : { enabled: true },
          },
        },
      ],
    },
  };
});
</script>
