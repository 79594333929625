<template>
  <div class="flex justify-between sm:justify-center space-x-2">
    <span class="flex space-x-2">
      <img src="../images/snowIcon.svg" class="w-4" />
      <p>Precipitate</p>
    </span>
    <label class="relative inline-flex cursor-pointer items-center">
      <input
        id="switch"
        type="checkbox"
        class="peer sr-only"
        v-model="snowFalling"
        @click="toggleSnow()"
      />
      <div
        class="relative h-4 w-8 rounded-full border bg-[#0A0D18] pb-4 after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:border after:border-[#0A0D18] after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#0A0D18] peer-checked:after:translate-x-full peer-checked:after:border-[#0A0D18] peer-focus:ring-[#5268B8]"
      ></div>
    </label>
  </div>
</template>
<script setup>
import { ref } from 'vue';
// Retrieve the value from localStorage and convert it to a boolean
const snowFalling = ref(
  localStorage.getItem('animationEffect')?.length > 0
    ? localStorage.getItem('animationEffect')
    : true,
);
function toggleSnow() {
  snowFalling.value = snowFalling.value == 'true' ? 'false' : 'true';
  localStorage.setItem('animationEffect', snowFalling.value.toString());
  setTimeout(() => {
    window.location.reload();
  }, 1000);
}
</script>
