<template>
  <div class="h-full w-full">
    <img :src="imageSource" class="flex-shrink-0" :key="imageSource" />
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import defaultIndicator from '../images/defaultIndicator.svg';
import warmIndicator from '../images/warmIndicator.svg';
import lukewarmIndicator from '../images/lukewarmIndicator.svg';
import chillyIndicator from '../images/chillyIndicator.svg';
import frozenIndicator from '../images/frozenIndicator.svg';
import subzeroIndicator from '../images/subzeroIndicator.svg';

const props = defineProps({
  currentSnapshot: {
    type: Object,
    default: {},
  },
});


const imageComp = {
  default: defaultIndicator,
  warm: warmIndicator,
  lukewarm: lukewarmIndicator,
  chilly: chillyIndicator,
  frozen: frozenIndicator,
  subzero: subzeroIndicator
};
const imageSource = computed(() => {
  let url = imageComp['default'];
  if (props.currentSnapshot?.tier) {
    url = imageComp[props.currentSnapshot?.tier?.toLowerCase()]
  }
  return url;
});
</script>
