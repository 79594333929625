<template>
  <svg
    class="w-5 h-5 text-gray-500"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.969 1.01131C11.9926 0.54322 10.9455 0.19835 9.85069 0.000828577C9.83076 -0.00298353 9.81084 0.00654313 9.80057 0.0255969C9.66591 0.275832 9.51674 0.602284 9.41228 0.858874C8.23476 0.674694 7.06328 0.674694 5.90989 0.858874C5.80542 0.596581 5.65084 0.275832 5.51557 0.0255969C5.5053 0.00717885 5.48538 -0.0023478 5.46544 0.000828577C4.37125 0.197719 3.32416 0.542589 2.34711 1.01131C2.33865 1.01512 2.3314 1.02148 2.32659 1.02973C0.340482 4.12978 -0.203597 7.15363 0.06331 10.14C0.0645177 10.1546 0.0723678 10.1686 0.0832374 10.1775C1.39361 11.1828 2.66294 11.7932 3.90869 12.1978C3.92863 12.2041 3.94975 12.1965 3.96244 12.1794C4.25713 11.7589 4.51981 11.3156 4.74504 10.8494C4.75833 10.8221 4.74564 10.7897 4.71847 10.7789C4.30181 10.6138 3.90507 10.4124 3.52343 10.1838C3.49324 10.1654 3.49082 10.1203 3.51859 10.0987C3.59891 10.0358 3.67924 9.97039 3.75592 9.90434C3.7698 9.89228 3.78913 9.88973 3.80544 9.89735C6.31266 11.0933 9.02702 11.0933 11.5047 9.89735C11.521 9.8891 11.5403 9.89164 11.5548 9.90371C11.6315 9.96976 11.7118 10.0358 11.7927 10.0987C11.8205 10.1203 11.8187 10.1654 11.7885 10.1838C11.4068 10.4169 11.0101 10.6138 10.5928 10.7783C10.5657 10.7891 10.5536 10.8221 10.5669 10.8494C10.7969 11.315 11.0596 11.7583 11.3489 12.1787C11.3609 12.1965 11.3827 12.2041 11.4026 12.1978C12.6544 11.7932 13.9237 11.1828 15.2341 10.1775C15.2456 10.1686 15.2528 10.1552 15.254 10.1406C15.5735 6.68806 14.719 3.68901 12.9889 1.03036C12.9847 1.02148 12.9775 1.01512 12.969 1.01131ZM5.11945 8.3216C4.3646 8.3216 3.74263 7.59757 3.74263 6.70839C3.74263 5.81921 4.35254 5.09518 5.11945 5.09518C5.89237 5.09518 6.50832 5.82557 6.49624 6.70839C6.49624 7.59757 5.88633 8.3216 5.11945 8.3216ZM10.21 8.3216C9.45516 8.3216 8.83319 7.59757 8.83319 6.70839C8.83319 5.81921 9.44307 5.09518 10.21 5.09518C10.9829 5.09518 11.5989 5.82557 11.5868 6.70839C11.5868 7.59757 10.9829 8.3216 10.21 8.3216Z"
      fill="currentColor"
    ></path>
  </svg>
</template>
