<template>
  <div class="flex h-full w-full">
    <div
      v-if="latestTradeTableData?.length == 0"
      class="flex h-full w-full items-center justify-center"
    >
      <IconLoading class="h-12 w-12" />
    </div>
    <Table
      :config="latestTradeTableConfig"
      :data="latestTradeTableData"
      :key="latestTradeTableData?.length"
      v-else
    ></Table>
  </div>
</template>

<script setup>
import { ref, inject, computed, onMounted, watch } from 'vue';
import Table from '../../Shared/Table.vue';
import { NftApi } from '../Nfts/NftApi';
import _ from 'lodash';
import IconLoading from '../../icons/IconLoading.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
const api = new NftApi();
const latestTraders = ref([]);
onMounted(async () => {
  if (!_.isEmpty(props.collection)) {
    latestTraders.value = await fetchLatestTraders();
  }
});

const props = defineProps({
  collection: Object,
});

async function fetchLatestTraders() {
  return api.fetchCollectionLatestTraders(props.collection?.collection);
}

// Latest Trades
const latestTradeTableConfig = ref({
  cols: [
    {
      type: 'date',
      name: 'Time',
      id: 'dateTime',
      textLeft: 'left',
      style: 'text-left',
      noSeparator: true,
    },
    { type: 'number', name: 'Price', id: 'price' },
    { type: 'number', name: 'Token', id: 'token' },
    { type: 'addresses', name: 'Buyer', id: 'buyer' },
    { type: 'addresses', name: 'Seller', id: 'seller' },
    { type: 'linkIcon', name: 'Link', style: 'text-right', id: 'link_url' },
  ],
  paddingLeftRight: 'px-3',
});

const noData = ref(false);

const latestTradeTableData = computed(() => {
  let data = [];
  if (typeof latestTraders?.value == String) {
    noData.value = true;
  } else {
    latestTraders?.value.map((item) => {
      data.push({
        dateTime: item.block_date,
        price: item.price,
        token: item.token_id,
        buyer: item.from_address,
        seller: item.to_address,
        link_url: {
          name: 'Link',
          link: `https://snowtrace.io/tx/${item.transaction_hash}`,
        },
      });
    });
  }

  return data;
});
</script>
