<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 0C4.47833 0 0 4.47667 0 10C0 15.5225 4.47833 20 10 20C15.5225 20 20 15.5225 20 10C20 4.47667 15.5225 0 10 0ZM12.6867 14.8925C12.8433 15.0033 13.045 15.0308 13.225 14.9633C13.4042 14.895 13.5367 14.7408 13.5767 14.5542C13.9992 12.5692 15.0242 7.54417 15.4083 5.73917C15.4375 5.6025 15.3892 5.46083 15.2825 5.37C15.175 5.27917 15.0267 5.25333 14.895 5.30167C12.8567 6.05667 6.57917 8.41167 4.01333 9.36083C3.85083 9.42167 3.745 9.5775 3.75 9.75C3.75583 9.92167 3.87167 10.0708 4.03833 10.1208C5.18917 10.465 6.69917 10.9442 6.69917 10.9442C6.69917 10.9442 7.405 13.0758 7.7725 14.1592C7.81917 14.2958 7.92583 14.4025 8.06583 14.4392C8.20667 14.4758 8.35583 14.4375 8.46083 14.3383C9.05167 13.7808 9.965 12.9183 9.965 12.9183C9.965 12.9183 11.7017 14.1908 12.6867 14.8925ZM7.33417 10.6742L8.15083 13.3667L8.3325 11.6617C8.3325 11.6617 11.485 8.8175 13.2833 7.19667C13.3358 7.14917 13.3425 7.06917 13.2992 7.01333C13.2558 6.9575 13.1758 6.94417 13.1167 6.9825C11.0333 8.3125 7.33417 10.6742 7.33417 10.6742Z"
      fill="currentColor"
    />
    <defs>
      <clipPath id="clip0_122_649">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
