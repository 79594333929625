<template>
  <BaseCard class="flex h-96 flex-shrink flex-col px-4" label-length="15">
    <template #header>Holder Distribution</template>
    <template #controls>
      <BaseRadio
        v-model="selectedFilter"
        multiple
        button-width-class="w-32"
        :options="filterTypes"
        placeholder="Select Type"
        :control-length="5"
      />
    </template>
    <div
      class="flex-1 overflow-hidden rounded-lg px-3 pt-4 pb-0 shadow"
      :class="loading ? 'flex' : ''"
    >
      <IconLoading class="m-auto h-8 w-8" v-if="loading" />
      <BarGraph :data-series="series" :config="chartConfig" v-else />
    </div>
  </BaseCard>
</template>

<script setup>
import LineChart from '../../../Widgets/LineChart.vue';
import { ref, onMounted, computed } from 'vue';
import BarGraph from '../../../Widgets/BarChart.vue';
import moment from 'moment';
import { NftApi } from '../../Nfts/NftApi';
import { filterToDateTime, timeToUnix } from '../../../composeables/filters.js';
import IconLoading from '../../../icons/IconLoading.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../../Shared/Tooltip.vue';
import BaseCard from '../../../Shared/BaseCard.vue';
import BaseRadio from '../../../Shared/BaseRadio.vue';
const api = new NftApi();
const chartData = ref([]);
const loading = ref(true);

const selectedFilter = ref('7D');
const filterTypes = ref(['7D', '30D', '90D']);

const props = defineProps({
  collection: Object,
});

onMounted(async () => {
  chartData.value = await fetchHolderDistribution();
  loading.value = false;
});

async function fetchHolderDistribution() {
  return api.fetchHolderDistribution(props.collection?.collection);
}

const series = computed(() => {
  let data = [
    {
      name: '1',
      data: [],
    },
    {
      name: '2-6',
      data: [],
    },
    {
      name: '7-24',
      data: [],
    },
    {
      name: '25+',
      data: [],
    },
  ];
  chartData.value
    .filter(
      (x) => moment(x.day).format() >= filterToDateTime(selectedFilter.value),
    )
    .forEach((x) => {
      data[0].data.push([timeToUnix(x.day, true), x.holder_grp_one]);
      data[1].data.push([timeToUnix(x.day, true), x.holder_grp_two]);
      data[2].data.push([timeToUnix(x.day, true), x.holder_grp_three]);
      data[3].data.push([timeToUnix(x.day, true), x.holder_grp_four]);
    });
  return data;
});

const chartConfig = ref({
  plotOptions: {
    series: {
      stacking: 'normal',
      animation: false,
    },
    column: {
      pointPadding: 0.1,
      borderWidth: 0,
    },
  },
  type: 'column',
  legend: {
    itemMarginBottom: 3,
    itemMarginTop: 3,
    itemStyle: {
      color: 'white',
      fontWeight: '400',
      fontSize: '12px',
      fontFamily: 'Inter',
      letterSpacing: 0,
      textTransform: 'capitalize',
    },
    itemHoverStyle: {
      color: 'rgba(255,255,255,0.85)',
    },
    itemHiddenStyle: {
      color: 'rgba(255,255,255,0.25)',
    },
  },
  height: '295',
  // colors: ['#93c5fd', '#3b82f6', '#1d4ed8', '#1e3a8a'],
  colors: [
    '#3fd3f0',
    '#4871F8',
    '#A464E4',
    '#F780D0',
    '#FFa366',
    '#3a68ff',
    '#41da5b',
    '#5146b8',
    '#1ffce3',
    '#913831',
  ],
  xAxis: {
    labels: {
      enabled: true,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
      },
      formatter: function () {
        return moment(this.value).format('MMM DD');
      },
    },
    type: 'datetime',
    lineWidth: 0,
    tickLength: 0,
  },
  yAxis: {
    gridLineColor: '#262626',
    title: {
      text: '',
    },
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 350,
        },
        chartOptions: {
          plotOptions: {
            column: {
              pointPadding: 0.1,
              borderWidth: 0,
            },
          },
        },
      },
    ],
  },
});
</script>
