<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 13"
    fill="none"
  >
    <path
      d="M10.125 1.6875H4.125C4.02554 1.6875 3.93016 1.72701 3.85984 1.79734C3.78951 1.86766 3.75 1.96304 3.75 2.0625V3.9375H1.875C1.77554 3.9375 1.68016 3.97701 1.60984 4.04734C1.53951 4.11766 1.5 4.21304 1.5 4.3125V10.3125C1.5 10.412 1.53951 10.5073 1.60984 10.5777C1.68016 10.648 1.77554 10.6875 1.875 10.6875H7.875C7.97446 10.6875 8.06984 10.648 8.14017 10.5777C8.21049 10.5073 8.25 10.412 8.25 10.3125V8.4375H10.125C10.2245 8.4375 10.3198 8.39799 10.3902 8.32767C10.4605 8.25734 10.5 8.16196 10.5 8.0625V2.0625C10.5 1.96304 10.4605 1.86766 10.3902 1.79734C10.3198 1.72701 10.2245 1.6875 10.125 1.6875ZM7.5 9.9375H2.25V4.6875H7.5V9.9375ZM9.75 7.6875H8.25V4.3125C8.25 4.21304 8.21049 4.11766 8.14017 4.04734C8.06984 3.97701 7.97446 3.9375 7.875 3.9375H4.5V2.4375H9.75V7.6875Z"
      fill="currentColor"
    />
  </svg>
</template>
