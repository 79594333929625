<template>
  <div class="mx-auto space-y-4 text-sm">
    <IndividualCoinHeader :project="project" />

    <section class="w-full grid-cols-10 gap-4 space-y-4 lg:grid lg:space-y-0">
      <BaseCard class="col-span-7 flex flex-shrink flex-grow flex-col rounded">
        <template #header>Activity Growth</template>
        <template #controls>
          <BaseRadio
            v-model="selectedTimeframe"
            multiple
            button-width-class="w-32"
            :options="timeframes"
            placeholder="Select Type"
          />
        </template>
        <div class="py-2">
          <!-- <div class="flex justify-between">
            <span
              class="flex items-center py-2 pr-2 text-base font-normal text-textColor"
              ><span class="mr-1 text-lg">Activity Growth</span>
              <Tooltip
                text="TVL data provided by DeFiLlama"
                id="dashboard_tvls"
              >
                <InformationCircleIcon class="h-5 w-5 text-infoIconColor" />
              </Tooltip>
            </span>
            <div class="flex items-center space-x-4">
              <span class="py-3 text-sm" v-for="tf in timeframes" :key="tf">
                <span
                  :class="`cursor-pointer ${
                    selectedTimeframe == tf
                      ? 'rounded bg-gray-700/50 p-2 text-selectedFilterTextColor'
                      : ''
                  }`"
                  @click="selectedTimeframe = tf"
                  >{{ tf }}</span
                >
              </span>
            </div>
          </div> -->

          <div class="flex-1 overflow-hidden rounded-lg pb-0 shadow">
            <MultiLineChart
              :series="chartSeries"
              :config="chartConfig"
              :key="selectedTimeframe"
            />
          </div>
        </div>
      </BaseCard>

      <!-- NewsSection  -->

      <BaseCard
        class="col-span-3 col-start-8 flex flex-col overflow-x-auto rounded"
      >
        <template #header>Latest News</template>
        <div class="py-2">
          <!-- <span
            class="flex items-center py-2 text-base font-normal text-textColor"
            ><span class="mr-1 text-lg">Latest News </span>
            <Tooltip text="TVL data provided by DeFiLlama" id="dashboard_tvls">
              <InformationCircleIcon class="h-5 w-5 text-infoIconColor" />
            </Tooltip>
          </span> -->
          <div class="wrap h-96 space-y-2 overflow-y-auto">
            <div v-if="newsLoading">
              <div v-for="i in 5" :key="i">
                <NewsTemplate />
              </div>
            </div>
            <div v-if="newsItems.length > 0">
              <div
                v-for="item in newsItems"
                :key="item.id"
                class="flex h-[7rem] items-center border-b border-gray-700"
              >
                <NewsWidget :news-item="item" />
              </div>
            </div>
            <div
              v-else-if="!newsLoading && newsItems"
              class="mt-auto flex h-full w-full justify-center"
            >
              <NoDataFound />
            </div>
          </div>
        </div>
      </BaseCard>
    </section>

    <section class="w-full grid-cols-10 gap-8 lg:grid">
      <!-- Table Section -->
      <div
        class="flex w-full flex-shrink flex-col rounded lg:col-span-6 xl:col-span-7"
      >
        <BaseCard class="relative overflow-x-auto">
          <template #header>Key Metrics</template>
          <Table
            :config="keyMetricsTableConfig"
            :data="keyMetricsTableData"
          ></Table>
        </BaseCard>
      </div>

      <!-- Token section -->
      <div
        class="flex w-full max-w-sm flex-col rounded lg:col-span-4 xl:col-span-3"
      >
        <div
          class="flex items-center py-2 pr-2 text-base font-normal text-textColor"
        >
          <span class="mr-1">Tokens</span>
          <Tooltip text="TVL data provided by DeFiLlama" id="dashboard_tvls">
            <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
          </Tooltip>
        </div>
        <TokensWidget :tokens="tokens" :tokens-loading="false" />
      </div>
    </section>
  </div>
</template>

<script setup>
import StatsWidget from '../../Widgets/Stats.vue';
import NewsWidget from '../../Widgets/News.vue';
import IndividualCoinHeader from '../../Shared/IndividualCoinHeader.vue';
import { computed, ref, watch, onMounted, inject } from 'vue';
import MultiLineChart from '../../Widgets/MultiLineChart.vue';
import Table from '../../Shared/Table.vue';
import NewsTemplate from '../../load_templates/NewsTemplateCard.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../Shared/Tooltip.vue';
import BaseCard from '../../Shared/BaseCard.vue';
import BaseRadio from '../../Shared/BaseRadio.vue';
import {
  formatNumber,
  rounded,
  percentage,
  convertUnderScoreToDash,
} from '../../composeables/filters.js';
import TokensWidget from '../../Shared/Tokens.vue';
import IconCoin from '../../icons/IconCoin.vue';
import { ProjectApi } from './ProjectApi';
import { filterToDateTime } from '../../composeables/filters.js';
import moment from 'moment';
const timeframes = ['7D', '30D', '3M', '6M', '1Y', 'ALL'];
const selectedTimeframe = ref('30D');
const api = new ProjectApi();

const props = defineProps({
  project: Object,
});

const keyMetricsTableConfig = ref({
  cols: [
    {
      type: 'text',
      name: 'Metrics',
      id: 'metrics',
      textLeft: 'left',
      noSeparator: true,
    },
    { type: 'dollar_number', name: 'Total', id: 'total' },
    { type: 'dollar_number', name: '1d', id: '1d' },
    { type: 'percent', name: '1d Growth', id: '1d_growth', showZero: true },
    { type: 'dollar_number', name: '7d', id: '7d' },
    { type: 'percent', name: '7d Growth', id: '7d_growth', showZero: true },
    { type: 'dollar_number', name: '30d', id: '30d' },
    { type: 'percent', name: '30d Growth', id: '30d_growth', showZero: true },
  ],
  paddingLeftRight: 'px-3',
});
const keyMetricsTableData = computed(() => {
  let project = props.project;

  return [
    {
      metrics: 'Total Transactions',
      total: `${project.total_game_num_transactions}`,
      '1d': `${project.num_transactions_one_day}`,
      '1d_growth': project.num_transactions_growth_one_day,
      '7d': `${project.num_transactions_seven_day}`,
      '7d_growth': project.num_transactions_growth_seven_day,
      '30d': `${project.num_transactions_thirty_day}`,
      '30d_growth': project.num_transactions_growth_thirty_day,
    },
    {
      metrics: 'Total Wallets',
      total: `${project.total_game_unique_wallets}`,
      '1d': `${project.unique_wallets_one_day}`,
      '1d_growth': project.unique_wallets_growth_one_day,
      '7d': `${project.unique_wallets_seven_day}`,
      '7d_growth': project.unique_wallets_growth_seven_day,
      '30d': `${project.unique_wallets_thirty_day}`,
      '30d_growth': project.unique_wallets_growth_thirty_day,
    },
    {
      metrics: 'MarketCap',
      total: `$${project?.market_cap_total?.market_cap}`,
      '1d': `$${project?.market_cap_one_day?.market_cap}`,
      '1d_growth': project?.market_cap_one_day?.market_cap
        ? parseFloat(project?.market_cap_one_day?.market_cap) /
            parseFloat(project?.market_cap_total?.market_cap) -
          1
        : '',
      '7d': `$${project?.market_cap_seven_day?.market_cap}`,
      '7d_growth': project?.market_cap_seven_day?.market_cap
        ? parseFloat(project?.market_cap_seven_day?.market_cap) /
            parseFloat(project?.market_cap_total?.market_cap) -
          1
        : '',
      '30d': `$${project?.market_cap_thirty_day?.market_cap}`,
      '30d_growth': project?.market_cap_thirty_day?.market_cap
        ? parseFloat(project?.market_cap_thirty_day?.market_cap) /
            parseFloat(project?.market_cap_total?.market_cap) -
          1
        : '',
    },
  ];
});

const activtyGrowthSeries = ref([]);
const universeTokens = inject('universeTokens');
const newsItems = ref([]);

onMounted(() => {
  Promise.all([fetchActivityGrowthChartData(), fetchNews()]);
});

async function fetchActivityGrowthChartData() {
  activtyGrowthSeries.value = await api.fetchActivityGrowthChartData(
    props.project.game_api,
  );
}
const newsLoading = ref('true');
async function fetchNews() {
  newsItems.value = await api.fetchNews(props.project.game_api);
  newsLoading.value = false;
}

const tokens = computed(() => {
  let tokens = universeTokens.value || [];
  return tokens?.filter(
    (x) =>
      x.game?.replace(/_/g, ' ') == props.project.game_api?.replace(/_/g, ' '),
  );
});

const chartConfig = ref({
  yAxis: [
    {
      // Transactions
      labels: {
        style: {
          color: '#3b82f6',
        },
      },
      title: {
        text: '',
      },
      opposite: true,
      gridLineColor: '#262626',
    },
    {
      // Volume
      gridLineWidth: 0,
      title: {
        text: '',
      },
      labels: {
        style: {
          color: '#10b981',
        },
      },
      gridLineColor: '#262626',
    },
    {
      // Wallets
      gridLineWidth: 0,
      title: {
        text: '',
      },
      labels: {
        style: {
          color: '#f59e0b',
        },
      },
      opposite: true,
      gridLineColor: '#262626',
    },
  ],
  useUTC: false,
});

const chartSeries = computed(() => {
  return [
    {
      name: 'Wallets',
      type: 'line',
      yAxis: 1,
      data: walletSeries.value,
      tooltip: {
        valueSuffix: '',
      },
      marker: {
        enabled: false,
      },
      color: '#10b981',
      animation: false,
    },
    {
      name: 'Transactions',
      type: 'line',
      data: transactionSeries.value,
      tooltip: {
        valueSuffix: '',
      },
      marker: {
        enabled: false,
      },
      color: '#3b82f6',
      animation: false,
    },
    {
      name: 'MarketCap',
      type: 'line',
      yAxis: 2,
      data: marketCapSeries.value,
      // data: volumeSeries.value,
      marker: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: '',
      },
      color: '#f59e0b',
      animation: false,
    },
  ];
});
// const marketCapSeries = ref([]);
const marketCapSeries = computed(() => {
  let mcSeries = activtyGrowthSeries.value
    ?.filter((x) =>
      selectedTimeframe.value == 'ALL'
        ? true
        : moment(x.date).format() >= filterToDateTime(selectedTimeframe.value),
    )
    .map((x) => {
      return [
        moment(moment.utc(x.date).format('YYYY-MM-DD')).unix() * 1000,
        parseFloat(x.market_cap),
      ];
    });

  const uniqueData = [];
  const seen = new Set();

  mcSeries.forEach((record) => {
    const key = record.join('-'); // Create a unique key for each record
    if (!seen.has(key)) {
      seen.add(key);
      uniqueData.push(record);
    }
  });
  return uniqueData;
});
const transactionSeries = computed(() => {
  let tSeries = activtyGrowthSeries.value
    ?.filter((x) =>
      selectedTimeframe.value == 'ALL'
        ? true
        : moment(x.date).format() >= filterToDateTime(selectedTimeframe.value),
    )
    .map((x) => {
      return [
        moment(moment.utc(x.date).format('YYYY-MM-DD')).unix() * 1000,
        parseInt(x.num_transactions),
      ];
    });

  const uniqueData = [];
  const seen = new Set();

  tSeries.forEach((record) => {
    const key = record.join('-'); // Create a unique key for each record
    if (!seen.has(key)) {
      seen.add(key);
      uniqueData.push(record);
    }
  });
  return uniqueData;
});
const walletSeries = computed(() => {
  let wSeries = activtyGrowthSeries.value
    ?.filter((x) =>
      selectedTimeframe.value == 'ALL'
        ? true
        : moment(x.date).format() >= filterToDateTime(selectedTimeframe.value),
    )
    .map((x) => {
      return [
        moment(moment.utc(x.date).format('YYYY-MM-DD')).unix() * 1000,
        parseInt(x.unique_wallets),
      ];
    });

  const uniqueData = [];
  const seen = new Set();

  wSeries.forEach((record) => {
    const key = record.join('-'); // Create a unique key for each record
    if (!seen.has(key)) {
      seen.add(key);
      uniqueData.push(record);
    }
  });

  return uniqueData;
});
</script>
