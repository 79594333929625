<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script setup>
import { ref, computed, reactive, onMounted, onUnmounted } from 'vue';
import { formatNumber } from '../composeables/filters.js';
import moment from 'moment';

const props = defineProps({
  series: Array,
  lineColor: String,
  gradiantColor: Array,
  timeFormat: String,
  useUtc: {
    type: Boolean,
    default: true,
  },
  dollarSign: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  window.addEventListener('resize', getDimensions);
  getDimensions();
});

onUnmounted(() => {
  window.removeEventListener('resize', getDimensions);
});

const width = ref();
const height = ref();

function getDimensions() {
  width.value = document.getElementById('lineGraphContainer')?.clientWidth;
  height.value = document.getElementById('lineGraphContainer')?.clientHeight;
}

const chartOptions = computed(() => {
  return {
    chart: {
      width: width.value / 2 - 20,
      height: 60,
      zoomType: '',
      backgroundColor: 'transparent',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      animation: false,
      type: 'area'
    },
    exporting: {
      enabled: false,
    },
    series: [
      {
        data: props.series,
        marker: {
          enabled: false,
        },
        animation: false,
      },
    ],
    yAxis: {
      visible: false,
      gridLineColor: 'transparent',
      title: {
        text: '',
      },
    },
    xAxis: {
      visible: false,
      gridLineColor: 'transparent',
      resize: {
        enabled: true,
      },
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: 'transparent',
      color: '#ffff',
      borderWidth: 0,
      shadow: false,
      outside: true,
      style: {
        color: '#fff',
      },
      formatter() {
        let title = this.x;
        title = typeof title === 'string' ? parseInt(title) : title;
        title = props.useUtc
          ? moment(title)
              .utc()
              .format(props?.timeFormat || 'MMM DD, YYYY')
          : moment(title).format(props?.timeFormat || 'MMM DD, YYYY');
        const tipHtml = `<b>${title}</b> `;
        const formattedPoints = this.points
          .map((point) => {
            const roundTo = point.y > 1 ? 2 : 6;
            const dollarSign = props.dollarSign ? '$' : '';
            return `<span style="color:${
              point.color
            }; margin-left: 0.5rem;">${dollarSign}${formatNumber(
              point.y,
              roundTo,
            )}</span>`;
          })
          .join('<br/>');
        return `<div class="custom-tooltip" >${
          tipHtml + formattedPoints
        }</div>`;
      },
    },
    plotOptions: {
      area: {
        lineWidth: 1,
        marker: {
          enabled: false,
        },
        color: props.lineColor,
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: props.gradiantColor,
        },
      },
      series: {
        animation: false,
      },
      dataLabels: {
        useHTML: true,
        color: 'white',
        formatter: function () {
          let title = this.key;
          let roundTo = this.point.y > 1 ? 2 : 6;
          const fromattedPnts = `
                <span><b>${title}</b><br/> ${formatNumber(
            this.point.y,
            roundTo,
          )}</span>`;
          return fromattedPnts + '<br/>';
        },
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
  };
});
</script>
