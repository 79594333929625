<template>
  <BaseCard class="flex h-96 flex-shrink flex-col px-4">
    <template #header>Unique Holders</template>
    <template #controls>
      <BaseRadio
        v-model="selectedFilter"
        multiple
        button-width-class="w-32"
        :options="filterTypes"
        placeholder="Select Type"
      />
    </template>
    <div
      class="flex-1 overflow-hidden rounded-lg px-3 pt-4 pb-0 shadow"
      :class="loading ? 'flex' : ''"
    >
      <IconLoading class="m-auto h-8 w-8" v-if="loading" />
      <LineChart :series="series" :config="chartConfig" v-else />
    </div>
  </BaseCard>
</template>

<script setup>
import LineChart from '../../../Widgets/LineChart.vue';
import { ref, onMounted, computed } from 'vue';
import { NftApi } from '../../Nfts/NftApi';
import { filterToDateTime, timeToUnix } from '../../../composeables/filters.js';
import moment from 'moment';
import IconLoading from '../../../icons/IconLoading.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../../Shared/Tooltip.vue';
import BaseCard from '../../../Shared/BaseCard.vue';
import BaseRadio from '../../../Shared/BaseRadio.vue';
const api = new NftApi();
const loading = ref(true);
const chartData = ref([]);

const props = defineProps({
  collection: Object,
});

onMounted(async () => {
  chartData.value = await fetchHolderDistribution();
  loading.value = false;
});

async function fetchHolderDistribution() {
  return api.fetchHolderDistribution(props.collection?.collection);
}

const selectedFilter = ref('7D');
const filterTypes = ref(['7D', '30D', '90D']);
const chartConfig = ref({
  height: '265',
  legend: {
    enabled: false,
    itemStyle: {
      color: '#F5F5F5',
      fontWeight: 'bold',
    },
    itemHoverStyle: {
      color: '#646666',
    },
  },
  name: 'Unique Holders',
});
const series = computed(() => {
  return chartData.value
    .filter(
      (x) => moment(x.day).format() >= filterToDateTime(selectedFilter.value),
    )
    .map((x) => {
      return [timeToUnix(x.day, true), x.total_holders];
    });
});
</script>
