<template>
  <div class="my-4 flex h-full w-full flex-col gap-4">
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
      <div
        class="w-full"
        v-for="item in statsItems"
        :key="item.id"
        id="StatsContainer"
      >
        <StatsWidget
          :stat-item="item"
          :show-loading="statsLoading"
          :tooltip-text="item.tooltip"
        />
      </div>
    </div>
    <div
      class="flex flex-col space-y-4 sm:flex-row sm:items-center sm:justify-between"
    >
      <div class="flex w-48 flex-col">
        <button
          @click="showSubnetDropdown = !showSubnetDropdown"
          class="flex items-center rounded-md text-xs font-semibold text-gray-500"
        >
          <div
            class="flex h-8 w-48 items-center justify-between space-x-2 rounded border border-gray-800 px-2 py-1"
          >
            <div class="flex items-center space-x-2 text-xs">
              <span class="mr-2 flex-shrink-0 text-white">{{
                titleize(selectedSubnetOption.title, ' ')
              }}</span>
            </div>
            <svg
              class="flex-shrink-0"
              :class="showSubnetDropdown ? '' : 'rotate-180'"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M13.354 10.3541C13.3076 10.4006 13.2524 10.4375 13.1917 10.4627C13.131 10.4878 13.066 10.5008 13.0003 10.5008C12.9346 10.5008 12.8695 10.4878 12.8088 10.4627C12.7481 10.4375 12.693 10.4006 12.6465 10.3541L8.00028 5.70727L3.35403 10.3541C3.26021 10.448 3.13296 10.5007 3.00028 10.5007C2.8676 10.5007 2.74035 10.448 2.64653 10.3541C2.55271 10.2603 2.5 10.1331 2.5 10.0004C2.5 9.86771 2.55271 9.74046 2.64653 9.64664L7.64653 4.64664C7.69296 4.60015 7.74811 4.56328 7.80881 4.53811C7.86951 4.51295 7.93457 4.5 8.00028 4.5C8.06599 4.5 8.13105 4.51295 8.19175 4.53811C8.25245 4.56328 8.30759 4.60015 8.35403 4.64664L13.354 9.64664C13.4005 9.69308 13.4374 9.74822 13.4626 9.80892C13.4877 9.86962 13.5007 9.93469 13.5007 10.0004C13.5007 10.0661 13.4877 10.1312 13.4626 10.1919C13.4374 10.2526 13.4005 10.3077 13.354 10.3541Z"
                fill="#EEEEF0"
              />
            </svg>
          </div>
        </button>
        <div class="relative !left-0 flex-shrink-0">
          <Dropdown
            :show="showSubnetDropdown"
            :items="allSubnetOptions"
            :configuration="subnetDropdownConfig"
            @selected="(x) => (selectedSubnetOption = x)"
            @close="showSubnetDropdown = false"
          />
        </div>
      </div>
      <BaseRadio
        class="flex justify-end sm:pb-2"
        v-model="selectedTimeframe"
        button-width-class="w-32"
        :options="timeframes"
      />
    </div>
    <div class="grid grid-cols-1 gap-4 lg:w-full lg:grid-cols-2">
      <ActiveAddresses
        :subnet="selectedSubnetOption"
        :timeframe="selectedTimeframe"
      />
      <Transactions
        :subnet="selectedSubnetOption"
        :timeframe="selectedTimeframe"
      />
    </div>
    <div class="grid grid-cols-1 gap-4 lg:w-full lg:grid-cols-2 xl:grid-cols-4">
      <TotalAddresses
        :subnet="selectedSubnetOption"
        :timeframe="selectedTimeframe"
      />
      <Contracts
        :subnet="selectedSubnetOption"
        :timeframe="selectedTimeframe"
      />
      <TpsAndMaxTps
        :subnet="selectedSubnetOption"
        :timeframe="selectedTimeframe"
      />
      <GasUsed :subnet="selectedSubnetOption" :timeframe="selectedTimeframe" />
    </div>

    <div>
      <SubnetComparison class="w-full" />
    </div>
    <div
      class="w-full space-y-4 sm:space-y-4 lg:flex lg:space-x-4 lg:space-y-0"
    >
      <section
        class="h-full w-full max-w-full space-y-2 space-x-0 rounded sm:max-w-full md:space-y-7 lg:flex lg:max-w-md lg:flex-col lg:space-x-0 xl:max-w-md"
        id="lineGraphContainer"
      >
        <BaseCard class="h-[500px]">
          <template #header
            >L1 News
            <Tooltip
              class="pl-2"
              text="The latest news headlines related to all relevant L1 on the Avalanche network."
              :id="1"
            >
              <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
            </Tooltip>
          </template>
          <div
            class="flex h-[30rem] flex-shrink flex-grow flex-col overflow-x-auto rounded-lg text-textColor"
          >
            <div class="wrap h-full space-y-2 overflow-y-auto pt-1 lg:h-full">
              <div v-if="newsLoading">
                <div v-for="i in 5" :key="i">
                  <NewsTemplate />
                </div>
              </div>
              <div v-if="newsItems.length > 0">
                <div
                  v-for="item in newsItems"
                  :key="item.id"
                  class="flex h-[7rem] items-center border-b border-gray-700"
                >
                  <NewsWidget :news-item="item" sourceNameClass="w-1/2" />
                </div>
              </div>
              <div
                v-else-if="!newsLoading && newsItems?.length == 0"
                class="mt-auto flex h-80 w-full justify-center"
              >
                <NoDataFound />
              </div>
            </div>
          </div>
        </BaseCard>
      </section>

      <!-- Table  -->
      <SubnetValidatorStats class="w-full" />
    </div>
  </div>
</template>

<script setup>
import NewsWidget from '../../Widgets/News.vue';
import NewsTemplate from '../../load_templates/NewsTemplateCard.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import BaseCard from '../../Shared/BaseCard.vue';
import StatsWidget from '../../Widgets/Stats.vue';
import Dropdown from '../../Shared/Dropdown.vue';
import BaseRadio from '../../Shared/BaseRadio.vue';
import ActiveAddresses from './charts/ActiveAddresses.vue';
import Transactions from './charts/Transactions.vue';
import TotalAddresses from './charts/TotalAddresses.vue';
import Contracts from './charts/Contracts.vue';
import TpsAndMaxTps from './charts/TpsAndMaxTps.vue';
import GasUsed from './charts/GasUsed.vue';
import SubnetComparison from './tables/SubnetComparison.vue';
import SubnetValidatorStats from './tables/SubnetValidatorStats.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../Shared/Tooltip.vue';
import { SubnetApi } from './SubnetApi';
import { ref, reactive, onMounted } from 'vue';
import { simpleNumberFormatter, titleize } from '../../composeables/filters.js';

const subnetApi = new SubnetApi();

// STATS
const statsItems = ref([
  {
    id: 1,
    title: 'Total L1s',
    tooltip:
      'The total number of L1 currently active on the Avalanche network.',
    total: 0,
    chart: [],
    noGrowth: true,
    rawValue: true,
  },
  {
    id: 2,
    title: 'Total Blockchains',
    tooltip:
      'The total number of blockchains currently operating within all L1s on the Avalanche network.',
    total: 0,
    chart: [],
    noGrowth: true,
    rawValue: true,
  },
  {
    id: 3,
    title: 'Total Validators',
    tooltip:
      'The total number of validators participating in securing the Avalanche network.',
    total: 0,
    chart: [],
    noGrowth: true,
    rawValue: true,
  },
  {
    id: 4,
    title: 'Total Stake Amount',
    tooltip:
      'The total amount of AVAX staked by validators on the Avalanche network.',
    total: 0,
    chart: [],
    noGrowth: true,
    suffix: 'AVAX',
    rawValue: true,
  },
]);
const statsLoading = ref(false);

async function getStats() {
  statsLoading.value = true;
  const response = await subnetApi.fetchStatsData();
  statsItems.value[0].total = response.subnets;
  statsItems.value[1].total = response.blockchains;
  statsItems.value[2].total = response.validators;
  statsItems.value[3].total = simpleNumberFormatter(response.stake_amount);

  // TODO ~ FILL THE STATSITEMS WITH VALUES
  statsLoading.value = false;
}

onMounted(() => {
  getStats();
  fetchNews();
  fetchSubnetComparison();
});

// SUBNETS DROPDOWN
const showSubnetDropdown = ref(false);
const subnetDropdownConfig = ref({
  classes:
    'bg-[#f3f2f614] backdrop-blur-[12px] box-shadow left-0 h-56 overflow-y-auto flex-shrink-0',
  textColor: 'text-[#f3f2f68f] hover:text-white',
});
const allSubnetOptions = ref([]);
const selectedSubnetOption = ref({ id: 'mainnet', title: 'All L1s' });

// BASE RADIO FILTERS
const selectedTimeframe = ref('90D');
const timeframes = reactive(['7D', '14D', '30D', '90D', '1Y', 'MAX']);

// NEWS
const newsItems = ref([]);
const newsLoading = ref(false);
const newsTooltipText = ref('newsTooltipText');

async function fetchNews() {
  newsLoading.value = true;
  newsItems.value = await subnetApi.fetchSubnetNews();
  newsItems.value.sort((a, b) => new Date(b.date) - new Date(a.date));
  newsLoading.value = false;
}

const subnetComparisonLoading = ref(false);
const subnetComparisonData = ref(false);
async function fetchSubnetComparison() {
  subnetComparisonLoading.value = true;
  subnetComparisonData.value = await subnetApi.fetchSubnetComparison();
  subnetComparisonData.value.map((chain) => {
    allSubnetOptions.value.push({
      title: titleize(chain?.name, ' '),
      id: chain?.chain_id,
    });
  });

  allSubnetOptions.value = allSubnetOptions.value.sort((a, b) =>
    a.title === 'All L1s' ? -1 : b.title === 'All L1s' ? 1 : 0,
  );

  subnetComparisonLoading.value = false;
}
</script>
