<template>
  <div class="flex-1 overflow-hidden rounded-lg" :id="`${randomId}`">
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script setup>
import moment from 'moment';
import { ref, computed, reactive, onMounted, onUnmounted } from 'vue';
import {
  formatNumber,
  titleize,
  percentage,
  colors,
} from '../composeables/filters.js';

const props = defineProps({
  dataSeries: Array,
  config: {
    type: Object,
    default: () => {},
  },
  randomId: {
    type: String,
    default: 'columnGraphContainer',
  },
  tooltipTitlize: {
    type: Boolean,
    default: true,
  },
});
const selectedFilter = ref('1D');
const filterTypes = reactive(['1D', '7D', '30D']);

onMounted(() => {
  window.addEventListener('resize', getDimensions);
  getDimensions();
});

onUnmounted(() => {
  window.removeEventListener('resize', getDimensions);
});

const width = ref();
const height = ref();

function getDimensions() {
  width.value = document.getElementById(props.randomId)?.clientWidth;
  height.value = document.getElementById(props.randomId)?.clientHeight;
}

const chartOptions = computed(() => {
  return {
    chart: {
      type: props.config?.type || 'bar',
      width: props.config?.width || width.value,
      height: props.config?.height || height.value,
      zoomType: '',
      backgroundColor: 'transparent',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    exporting: {
      enabled: false,
    },
    title: props.config?.text || {
      text: '',
    },
    credits: {
      enabled: false,
    },
    yAxis: props.config?.yAxis || {
      gridLineColor: 'transparent',
      title: {
        text: '',
      },
    },
    xAxis: props.config?.xAxis || {
      visible: false,
      reversed: false,
    },
    colors: props.config?.colors || colors(),
    plotOptions: props.config?.plotOptions || {
      bar: {
        groupPadding: 1,
        pointPadding: 0.5,
        borderWidth: 0,
        pointWidth: 5,
      },
      series: {
        animation: false,
      },
      column: {
        colorByPoint: true,
      },
    },
    tooltip: {
      shared: true,
      shadow: false,
      useHTML: true,
      backgroundColor: 'transparent',
      color: '#ffff',
      borderWidth: 0,
      style: {
        color: '#ffff',
      },
      formatter() {
        const datetime = this.points[0].x;
        let parsedDatetime = moment(datetime).format(
          props.config?.useUTC ? 'MMM DD, YYYY' : 'MMM DD, YYYY',
        );

        parsedDatetime = props.config?.onlyMonthYear
          ? moment(datetime).format('MMM YYYY')
          : parsedDatetime;

        const formattedPoints = this.points
          .map((point) => {
            const seriesName = props.tooltipTitlize
              ? titleize(point.series.name)
              : point.series.name;
            return `<div class="py-1">
                <div class="flex items-center w-full">
                  <span style="color:${point.color}">●</span>
                  <span class="text-xxs px-1">${seriesName}</span>
                  <span class=" text-gray-200">${formatNumber(point.y)}</span>
                </div>
              </div>`;
          })
          .join('');

        return `<div class="custom-tooltip"> ${
          datetime > 0
            ? `<div><b>${parsedDatetime}</b></div> <div>${formattedPoints}</div>`
            : `<div>${formattedPoints}</div>`
        }</div>`;
      },
    },
    legend: {
      enabled: props.config?.legend || false,
      align: 'right',
      layout: 'vertical',
      itemMarginTop: 3,
      itemStyle: {
        color: 'white',
        fontWeight: '400',
        fontSize: '12px',
        fontFamily: 'Inter',
        letterSpacing: 0,
        textTransform: 'capitalize',
        border: '1px solid #000',
      },
      itemHoverStyle: {
        color: 'rgba(255,255,255,0.85)',
        border: '1px solid #000',
      },
      itemHiddenStyle: {
        color: 'rgba(255,255,255,0.25)',
      },
      navigation: {
        style: {
          color: 'rgba(255,255,255,0.55)',
        },
      },
    },
    series: props.dataSeries,
    responsive: props.config?.responsive,
  };
});
</script>
