<template>
  <BaseCard class="mx-auto flex flex-wrap">
    <template #header>Top Collections</template>
    <template #controls>
      <BaseRadio
        v-model="selectedFilter"
        multiple
        button-width-class="w-32"
        :options="filterTypes"
        placeholder="Select Type"
      />
    </template>

    <div class="flex w-full flex-shrink flex-grow flex-col space-y-4">
      <div class="relative h-[21.5rem] overflow-x-auto rounded-lg">
        <span
          class="mt-auto flex h-full w-full items-center justify-center"
          v-if="loading"
        >
          <IconLoading class="h-10 w-10" />
        </span>
        <span v-else-if="topCollectionsData?.length > 0">
          <Table
            :config="topCollectionsConfig"
            :data="topCollectionsData"
            class="py-2"
          ></Table>
        </span>
        <span v-else class="mt-auto flex h-full w-full justify-center">
          <NoDataFound />
        </span>
      </div>
    </div>
  </BaseCard>
</template>

<script setup>
import IconLoading from '../../icons/IconLoading.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import Table from '../../Shared/Table.vue';
import { ref, reactive, computed, onMounted, watch, inject } from 'vue';
import { NftApi } from './NftApi';
import BaseCard from '../../Shared/BaseCard.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../Shared/Tooltip.vue';
import BaseRadio from '../../Shared/BaseRadio.vue';
const api = new NftApi();

const loading = ref(true);
const selectedFilter = ref('24H');
const filterTypes = reactive(['24H', '7D', '30D']);

const topCollections = ref([]);

onMounted(() => {
  Promise.all([fetchTopCollections()]);
});

watch(selectedFilter, async () => {
  await fetchTopCollections();
});

async function fetchTopCollections() {
  loading.value = true;
  let selectedTimeFrame =
    selectedFilter.value == '24H'
      ? '1day'
      : selectedFilter.value == '7D'
      ? '7day'
      : '30day';
  topCollections.value = [];
  topCollections.value = await api.fetchTopCollections({
    order_by: selectedTimeFrame,
  });
  loading.value = false;
}

const topCollectionsData = computed(() => {
  let result = [];
  if (topCollections.value?.length > 0) {
    topCollections.value.forEach((data) => {
      if (data.collection_name) {
        result = result.concat({
          collection: data.collection_name,
          link: data.collection,
          floor_price: data.floor_price,
          fp_change: data.change_in_floor,
          owners: data.num_owners,
          avg_sales_usd: avgSales(data),
          avg_sales_avax: data.avrg_price,
          sales_1d: data.num_trxs,
          volume_1d: data.vol,
          image: data.image_url,
        });
      }
    });
  }
  return result;
});

const tokens = inject('universeTokens');
const avax = computed(() => {
  let allTokens = tokens.value || [];
  return allTokens.find((x) => x.ticker == 'AVAX');
});

function avgSales(data) {
  let result = data.avrg_price * (avax.value?.price || 1);
  return result || 0;
}

const topCollectionsConfig = ref({
  cols: [
    {
      type: 'nft',
      name: 'Collection',
      id: 'collection',
      textLeft: 'left',
      link: 'link',
    },
    {
      type: 'dollar',
      name: 'Avg Sales (USD)',
      id: 'avg_sales_usd',
      numbersLimit: 2,
    },
    { type: 'decimal', name: 'Avg Sales (AVAX)', id: 'avg_sales_avax' },
    { type: 'number', name: 'Sales', id: 'sales_1d' },
    { type: 'decimal', name: 'Volume (AVAX)', id: 'volume_1d' },
  ],
  paddingLeftRight: 'px-5',
});
</script>
