<template>
  <!-- <div class="px-4 py-3"> -->
    <!-- <span class="flex space-x-2 font-normal">
      <h2 class="text-lg tracking-tight text-textColor">Social Metrics</h2>
      <Tooltip text="Notable Listings" id="notable_listings">
        <InformationCircleIcon class="h-5 w-5 text-infoIconColor" />
      </Tooltip>
    </span> -->
    <div class="relative h-80 overflow-x-auto">
      <Table
        :config="socialMetricsTableConfig"
        :data="socialMetricsTableData"
      ></Table>
    </div>
  <!-- </div> -->
</template>
<script setup>
import { ref, computed } from 'vue';
import Table from '../../Shared/Table.vue';
import { TokenApi } from './TokenApi';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../Shared/Tooltip.vue';
const api = new TokenApi();
const props = defineProps({
  token: Object,
});
const socialMetricsTableConfig = ref({
  cols: [
    {
      type: 'text',
      name: 'Game',
      id: 'metrics',
      textLeft: 'left',
      style: 'w-full truncate capitalize',
    },
    { type: 'number', name: 'Now', id: 'one_day' },
    { type: 'number', name: '30d Ago', id: 'thirty_day' },
    { type: 'percent', name: 'Change', id: 'change' },
  ],
  paddingLeftRight: 'px-5',
});
const socialMetricsTableData = computed(() => {
  return [
    {
      metrics: 'Tweet Volume',
      one_day: parseFloat(props.token['tweet_volume']) || 0,
      thirty_day: parseFloat(props.token['thirty_day_ago_tweet_volume']) || 0,
      change: api.calculateChange(
        props.token['tweet_volume'],
        props.token['thirty_day_ago_tweet_volume'],
      ),
    },
    {
      metrics: 'Short-Term Sentiment',
      one_day: parseFloat(props.token['hourly_sentiment']) || 0,
      thirty_day:
        parseFloat(props.token['thirty_day_ago_hourly_sentiment']) || 0,
      change: api.calculateChange(
        props.token['hourly_sentiment'],
        props.token['thirty_day_ago_hourly_sentiment'],
      ),
    },
    {
      metrics: 'Long-Term Sentiment',
      one_day: parseFloat(props.token['long_term_sentiment']) || 0,
      thirty_day:
        parseFloat(props.token['thirty_day_ago_long_term_sentiment']) || 0,
      change: api.calculateChange(
        props.token['long_term_sentiment'],
        props.token['thirty_day_ago_long_term_sentiment'],
      ),
    },
  ];
});
</script>
