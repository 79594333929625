<template>
  <div class="">
    <div
      class="flex h-full items-end justify-between space-x-3 2xl:justify-end"
    >
      <!-- Loop through configuration object -->
      <template v-for="(item, key) in configuration" :key="key">
        <div class="flex w-1/5 flex-col rounded xl:w-20 2xl:w-28">
          <div
            class="flex flex-shrink-0 items-center justify-center pb-1.5"
            :class="item.labelColor"
          >
            <p
              class="text-center text-[8px] tracking-widest xs:text-xs"
              :class="item.label == 'SUBZERO' ? 'font-black' : 'font-semibold'"
            >
              {{ item.label }}
            </p>
            <IconSquareCheck
              class="h-4 w-4 flex-shrink-0"
              v-if="
                calculateHeight(
                  currentSnapShot?.current_snapshot?.score,
                  item.scoreRange.min,
                  item.scoreRange.max,
                ) == '100%'
              "
            />
          </div>
          <div
            v-if="
              calculateHeight(
                currentSnapShot?.current_snapshot?.score,
                item.scoreRange.min,
                item.scoreRange.max,
              ) != '100%' &&
              showBars(
                currentSnapShot?.current_snapshot?.score,
                item.nextTier[0],
                item.nextTier[1],
              )
            "
            class="z-30 flex items-center justify-center pb-4"
          >
            <Tooltip
              :text="`${calculateDiff >= 0 ? '+' : ''}${simpleNumberFormatter(
                calculateDiff,
              )} points since ${new Date(
                currentSnapShot?.most_recent_components?.cur_date,
              ).toLocaleDateString('en-US')}`"
            >
              <span
                :class="
                  'w-full rounded bg-opacity-30 px-2 py-1 text-[10px] ' +
                  (calculateDiff >= 0
                    ? 'bg-[#2F954952] text-[#C2F0C2]'
                    : 'bg-[#4b0f0f73] text-[#FF9EA1]')
                "
              >
                {{ calculateDiff >= 0 ? '+' : ''
                }}{{ simpleNumberFormatter(calculateDiff) }}
              </span>
            </Tooltip>
          </div>
          <div
            v-if="
              showBars(
                currentSnapShot?.current_snapshot?.score,
                item.nextTier[0],
                item.nextTier[1],
              )
            "
            class="my-chill-progress-container flex w-full items-end rounded backdrop-blur-lg"
            :class="[
              item.height,
              calculateHeight(
                currentSnapShot?.current_snapshot?.score,
                item.scoreRange.min,
                item.scoreRange.max,
              ) == '100%'
                ? `${item.label.toLowerCase()}-border`
                : 'higher_chillFactor',
            ]"
          >
            <div
              :class="
                'my-chill-progress-bar relative ' +
                item.progressBarColor +
                ' w-full rounded-[2px]'
              "
              :style="{
                height: calculateHeight(
                  currentSnapShot?.current_snapshot?.score,
                  item.scoreRange.min,
                  item.scoreRange.max,
                ),
              }"
            >
              <div
                class="absolute -top-2 z-20 w-full xs:-top-2.5 sm:-top-4 md:-top-[21px] lg:-top-2 xl:-top-2.5 2xl:-top-4"
                v-if="currentTier === item.label.toLowerCase()"
              >
                <img
                  src="../../images/tier_snow.svg"
                  class="tier-drop-shadow w-full object-cover"
                />
              </div>
              <Tooltip
                v-if="currentTier === item.label.toLowerCase()"
                :text="`${simpleNumberFormatter(
                  currentSnapShot.current_snapshot.score,
                )} / ${simpleNumberFormatter(item.scoreRange.max)}`"
              >
                <div
                  class="absolute top-0 h-full w-full"
                  :class="item.height"
                ></div>
              </Tooltip>
            </div>
          </div>
          <div
            v-else
            class="higher_chillFactor items-center rounded backdrop-blur-lg"
            :class="item.height"
          >
            <LockClosedIcon class="h-4 w-4 text-infoIconColor" />
          </div>
          <div class="mt-2 flex flex-col items-center justify-center">
            <div class="text-center">
              <div class="flex w-full items-center justify-center">
                <div
                  :class="`flex space-x-1 rounded ${item.bgTierRange} ${item.minValLabelColor} py-0.5 px-1 text-[8px]`"
                >
                  <img :src="item.valueImageSrc" class="flex-shrink-0" />
                  <p>{{ item.value }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { setTier, simpleNumberFormatter } from '../../composeables/filters';
import LockClosedIcon from '../../icons/LockClosedIcon.vue';
import IconSquareCheck from '../../icons/IconSquareCheck.vue';
import Tooltip from '../../Shared/Tooltip.vue';
import IconWarm from '../../images/warm.svg';
import IconLukewarm from '../../images/lukewarm.svg';
import IconChilly from '../../images/chilly.svg';
import IconFrozen from '../../images/frozen.svg';
import IconSubzero from '../../images/subzero.svg';

const props = defineProps({
  currentSnapShot: {
    type: Object,
  },
  checkpointStatsHistorical: {
    type: Object,
  },
});

const configuration = {
  WARM: {
    height: 'h-6',
    label: 'WARM',
    scoreRange: { min: 0, max: 55000 },
    labelColor: 'text-[#FF802B]',
    minValLabelColor: 'text-[#FF6A00]',
    progressBarColor: 'warm',
    progressBarBorderColor: 'warm-border',
    valueImageSrc: IconWarm,
    value: '0',
    bgTierRange: 'bg-[#FF6A003D]',
    nextTier: ['', 'lukeWarmMin'],
  },
  LUKEWARM: {
    height: 'h-10',
    label: 'LUKEWARM',
    scoreRange: { min: 55000, max: 60000 },
    labelColor: 'text-[#FFE03E]',
    minValLabelColor: 'text-[#FFD600]',
    progressBarColor: 'lukewarm',
    progressBarBorderColor: 'lukewarm-border',
    valueImageSrc: IconLukewarm,
    value: '55,000',
    bgTierRange: 'bg-[#FFD6003D]',
    nextTier: ['lukeWarmMin', 'chillyMin'],
  },
  CHILLY: {
    height: 'h-16',
    label: 'CHILLY',
    scoreRange: { min: 60000, max: 70000 },
    labelColor: 'text-[#19C3AA]',
    minValLabelColor: 'text-[#72E8FF]',
    progressBarColor: 'chilly',
    progressBarBorderColor: 'chilly-border',
    valueImageSrc: IconChilly,
    value: '60,000',
    bgTierRange: 'bg-[#1CFCE23D]',
    nextTier: ['chillyMin', 'frozenMin'],
  },
  FROZEN: {
    height: 'h-28',
    label: 'FROZEN',
    scoreRange: { min: 70000, max: 75000 },
    labelColor: 'text-[#0091FF]',
    minValLabelColor: 'text-[#9AA3FF]',
    progressBarColor: 'frozen',
    progressBarBorderColor: 'frozen-border',
    valueImageSrc: IconFrozen,
    value: '70,000',
    bgTierRange: 'bg-[#3A68FF3D]',
    nextTier: ['frozenMin', 'subzeroMin'],
  },
  SUBZERO: {
    height: 'h-44',
    label: 'SUBZERO',
    scoreRange: { min: 75000, max: 130000 },
    labelColor: 'subzero_text',
    minValLabelColor: 'text-[#CFCDDA]',
    progressBarColor: 'subzero',
    progressBarBorderColor: 'subzero-border',
    valueImageSrc: IconSubzero,
    value: '75,000',
    bgTierRange: 'bg-[#F3F2F63D]',
    nextTier: ['subzeroMin', 'subzeroMax'],
  },
};

const currentTier = computed(() => {
  return props.currentSnapShot?.current_snapshot?.tier?.toLowerCase();
});

function calculateHeight(score, min, max) {
  if (score > max) return '100%';
  const percentageChange = ((score - min) / (max - min)) * 100;
  const roundedPercentage = Math.round(percentageChange * 100) / 100;
  return `${roundedPercentage}%`;
}

function showBars(score, minTierName, currTierName) {
  // tier_cutoffs
  let tierCutOff = JSON.parse(localStorage.getItem('tier_cutoff'));
  if (props.checkpointStatsHistorical?.current_snapshot?.tier_cutoffs) {
    let minThreshold = minTierName ? tierCutOff[minTierName] : -9999;
    let maxThreshold = tierCutOff[currTierName];
    return score > maxThreshold
      ? true
      : score > minThreshold && score <= maxThreshold;
  }
}

const calculateDiff = computed(() => {
  return (
    props.currentSnapShot?.components_historical?.[0]?.score -
    props.currentSnapShot?.components_historical?.[1]?.score
  )?.toFixed(2);
});
</script>

<style scoped>
.tier-drop-shadow {
  filter: drop-shadow(0px 0px 2px rgba(0, 21, 97, 0.2))
    drop-shadow(0px 2px 12px rgba(0, 21, 97, 0.3));
}
</style>
