<template>
  <section
    class="grid grid-cols-1 grid-rows-1 gap-4 space-y-6 lg:grid-cols-3 lg:space-y-0"
  >
    <BaseCard
      class="col-span-2 flex flex-shrink-0 flex-grow flex-col rounded-lg"
    >
      <template #header>Top Projects</template>
      <template #controls>
        <BaseRadio
          v-model="selectedDefiTokensFilter"
          multiple
          button-width-class="w-32"
          :options="defiTokensFilter"
          placeholder="Select Type"
        />
      </template>
      <div class="h-96 lg:h-[29rem]">
        <!-- <div class="flex justify-between px-2 py-2">
        <div class="flex space-x-2 py-4 font-normal">
          <h2 class="text-lg tracking-tight text-textColor">Top Projects</h2>
          <Tooltip :text="tooltipText" id="notable_listings">
            <InformationCircleIcon class="h-5 w-5 text-infoIconColor" />
          </Tooltip>
        </div>
        <div class="flex items-center space-x-4">
          <span class="py-3 text-sm" v-for="tpf in defiTokensFilter" :key="tpf">
            <span
              :class="`cursor-pointer ${
                selectedDefiTokensFilter == tpf
                  ? 'rounded bg-gray-700/50 p-2 text-selectedFilterTextColor'
                  : 'text-gray-500'
              }`"
              @click="selectedDefiTokensFilter = tpf"
              >{{ tpf }}</span
            >
          </span>
        </div>
      </div> -->
        <div
          class="wrap h-full flex-1 overflow-hidden overflow-x-auto overflow-y-auto rounded-lg text-xs shadow"
        >
          <div
            class="h-72 w-full font-semibold md:h-36 lg:h-full"
            style="color: #a3a3a3"
          >
            <Table
              :config="defiTokensTableConfig"
              :data="sortData(defiTokens, 'tvl')"
            ></Table>
          </div>
        </div>
      </div>
    </BaseCard>

    <!-- GraphSection  -->
    <BaseCard
      :control-length="timeframes?.length"
      class="start-col-3 flex w-full flex-shrink flex-grow flex-col overflow-x-auto rounded-lg px-4"
    >
      <template #header
        >TVL
        <Tooltip text="TVL data provided by DeFiLlama" class="ml-1">
          <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
        </Tooltip>
      </template>
      <template #controls>
        <BaseRadio
          v-model="selectedTimeframe"
          multiple
          button-width-class="w-32"
          :options="timeframes"
          placeholder="Select Type"
          :control-length="timeframes?.length"
        />
      </template>
      <div class="flex-1 overflow-hidden rounded-lg pt-8 shadow">
        <IndividualPageLineChart
          :series="tvlFilteredChartData"
          :key="`area_graph_defi_idex_${selectedTimeframe}`"
          type="area"
          :config="chartConfig"
          :complete-data="true"
        />
      </div>
    </BaseCard>
  </section>
</template>
<script setup>
import { ref, onMounted, computed, inject } from 'vue';
import Table from '../../../Shared/Table.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../../Shared/Tooltip.vue';
import IndividualPageLineChart from '../../../Widgets/LineChart.vue';
import {
  filterToDateTime,
  getRandomId,
  localDateEqualToUtc,
  sortData,
} from '../../../composeables/filters.js';
import moment from 'moment';
import _ from 'lodash';
import BaseCard from '../../../Shared/BaseCard.vue';
import BaseRadio from '../../../Shared/BaseRadio.vue';
import { DefiApi } from '../DefiApi.js';
const api = new DefiApi();

onMounted(() => {
  fetchTvlChartData();
});

const universeDefiTokens = inject('universeDefiTokens');
const defiTokensFilter = ref(['24H', '7D', '30D']);
const selectedDefiTokensFilter = ref('24H');
const defiTokens = computed(() => {
  let tokens = universeDefiTokens.value || [];
  let filterValue =
    selectedDefiTokensFilter.value == '24H'
      ? '1d'
      : selectedDefiTokensFilter.value.toLowerCase();

  return tokens.map((t) => {
    return {
      name: t.name,
      price: parseFloat(t.price),
      transactions: t[`num_transactions_${filterValue}`],
      transactions_change: t[`change_txn_${filterValue}`],
      tvl_change: t[`change_${filterValue}`],
      tvl: parseFloat(t.tvl),
      mcap: parseFloat(t.mcap) / parseFloat(t.tvl),
      market_cap: parseFloat(t.mcap),
      link: t.ticker,
      image: t.icon,
    };
  });
});
const defiTokensTableConfig = ref({
  cols: [
    {
      type: 'defi',
      name: 'Projects',
      id: 'name',
      textLeft: 'left',
      openLink: true,
    },
    { type: 'dollar', name: 'Price', id: 'price' },
    {
      type: 'number',
      name: 'Transactions',
      id: 'transactions',
    },
    {
      type: 'percent',
      name: 'Transaction Change',
      id: 'transactions_change',
    },
    {
      type: 'dollar',
      name: 'Mcap',
      id: 'market_cap',
      numbersLimit: 2,
    },
    {
      type: 'dollar',
      name: 'TVL',
      id: 'tvl',
      numbersLimit: 2,
    },
    {
      type: 'percent',
      name: 'TVL Change',
      id: 'tvl_change',
    },
    {
      type: 'decimal',
      name: 'Mcap/Tvl',
      id: 'mcap',
      numbersLimit: 2,
    },
  ],
  paddingLeftRight: 'px-3',
});

const tvlChartData = ref([]);
async function fetchTvlChartData() {
  tvlChartData.value = await api.fetchTvlChartData();
}

const timeframes = ref(['7D', '30D', '3M', '6M', '1Y', 'ALL']);
const selectedTimeframe = ref('30D');
const tvlFilteredChartData = computed(() => {
  let data = [];
  tvlChartData.value.forEach((tcd) => {
    let tmpdata = [];
    tcd.data
      ?.filter((x) =>
        selectedTimeframe.value == 'ALL'
          ? true
          : moment(parseInt(x.date) * 1000).format() >=
            filterToDateTime(selectedTimeframe.value),
      )
      .forEach((x) => {
        tmpdata.push([
          parseInt(x.date) * 1000,
          parseFloat(x.totalLiquidityUSD),
          moment.unix(x.date).format('YYYY-MM-DD'),
        ]);
      });
    let filterData = _.uniqBy(tmpdata, (obj) => obj[2]);
    data.push({ name: tcd.name, data: filterData });
  });
  return data;
});
const chartConfig = ref({
  useUTC: localDateEqualToUtc ? true : false,
  name: 'Total Value Locked (USD)',
  type: 'area',
  opacity: 0.3,
  height: 395,
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
      fillOpacity: 0.8,
    },
    area: {
      stacking: 'normal',
      lineColor: '#666666',
      lineWidth: 1,
      marker: {
        enabled: false,
      },
    },
  },
  colors: [
    '#3fd3f0',
    '#4871F8',
    '#A464E4',
    '#F780D0',
    '#FFa366',
    '#3a68ff',
    '#41da5b',
    '#5146b8',
    '#1ffce3',
    '#913831',
  ],
});
</script>
