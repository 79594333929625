<template>
  <div class="relative flex w-full">
    <div
      class="hidden w-24 flex-shrink-0 flex-col items-center justify-center rounded-lg lg:flex"
    >
      <img
        :src="newsItem.metadata?.image"
        class="w-19 h-[5.5rem] rounded-lg object-cover"
        loading="lazy"
        @error="available = false"
        v-if="newsItem.metadata?.image && available"
      />
      <div
        class="flex h-[5.5rem] w-24 justify-center rounded-lg bg-gray-800/50 py-1 px-1"
        v-else
      >
        <img src="../images/BrokenImage.svg" />
      </div>
    </div>
    <div class="w-1/12 flex-1 self-center py-3 sm:w-full lg:w-1/2 lg:px-3">
      <div
        class="flex justify-between space-x-0.5 font-medium text-whiteSmokeAlpha-56 text-xs sm:space-x-2.5"
      >
        <span
          v-if="newsItem?.sources"
          :class="`w-1/2 line-clamp-1 ${sourceNameClass}`"
          >{{ newsItem?.sources[0]?.name }}</span
        >
        <span>{{ toDateTime(newsItem.date, 'simple') }}</span>
      </div>
      <a :href="newsItem.link" target="_blank">
        <p class="mt-0.5 w-full font-semibold text-white line-clamp-2 text-sm">
          {{ newsItem.translated_headline || newsItem.headline }}
        </p>
      </a>
      <div
        class="hide-scroll mt-2 flex w-full overflow-auto text-secondary text-xs"
      >
        <div
          class="mr-2 flex-shrink-0 font-medium line-clamp-1"
          v-for="tag in newsTags"
          :key="`${tag.name}_${tag.id}`"
        >
          <span
            class="flex whitespace-nowrap rounded-3xl bg-whiteSmokeAlpha-16 py-1 px-2 text-neutral-200 line-clamp-1 sm:px-3"
          >
            {{ tag.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toDateTime } from '../composeables/filters.js';
import { computed, ref, inject } from 'vue';
import { PhotoIcon } from '@heroicons/vue/20/solid';

const props = defineProps({
  newsItem: {
    type: Object,
    default: null,
  },
  sourceNameClass: {
    type: String,
    default: '2xl:w-2/3',
  },
});

const available = ref(true);

const tokens = inject('universeTokens');
const defiTokens = inject('universeDefiTokens');

const tokensFiltering = computed(() => {
  let filters = [];
  let tokensTags = [];
  tokensTags = window.location.href.includes('defi/')
    ? defiTokens.value
    : tokens.value;

  tokensTags?.forEach((e) => {
    filters = filters.concat([
      e.uid?.toLowerCase(),
      e.ticker?.toLowerCase(),
      e.name?.toLowerCase().replace(/\s/g, ''),
    ]);
  });
  return filters;
});

const newsTags = computed(() => {
  let subnetPage = window.location.href.includes('l1' || 'subnets');
  if (subnetPage) {
    return props.newsItem.tags.map((tag) => {
      return { name: tag.name };
    });
  }
  return props.newsItem.tags.filter(tagFilter);
});
function tagFilter(x) {
  return tokensFiltering.value.includes(
    x.name.toLowerCase().replace(/\s/g, ''),
  );
}
</script>
