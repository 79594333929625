<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 9.81592C20 8.60008 19.0067 7.61175 17.7858 7.61175C17.19 7.61175 16.65 7.85008 16.2525 8.23341C14.7442 7.24091 12.7033 6.60925 10.4433 6.52841L11.6792 2.63758L15.0258 3.42175L15.0208 3.47008C15.0208 4.46425 15.8333 5.27258 16.8325 5.27258C17.8308 5.27258 18.6425 4.46425 18.6425 3.47008C18.6425 2.47591 17.83 1.66675 16.8325 1.66675C16.0658 1.66675 15.4125 2.14508 15.1483 2.81591L11.5408 1.97008C11.3833 1.93175 11.2233 2.02258 11.1742 2.17758L9.79583 6.51675C7.43083 6.54508 5.28833 7.18175 3.7125 8.20425C3.3175 7.83925 2.79333 7.61091 2.21333 7.61091C0.9925 7.61091 0 8.60008 0 9.81592C0 10.6242 0.444167 11.3251 1.0975 11.7084C1.05417 11.9434 1.02583 12.1809 1.02583 12.4226C1.02583 15.6817 5.0325 18.3334 9.95833 18.3334C14.8842 18.3334 18.8917 15.6817 18.8917 12.4226C18.8917 12.1942 18.8675 11.9692 18.8292 11.7476C19.5225 11.3751 20 10.6542 20 9.81592ZM5.64667 11.3292C5.64667 10.6059 6.23833 10.0167 6.965 10.0167C7.69167 10.0167 8.2825 10.6059 8.2825 11.3292C8.2825 12.0526 7.69167 12.6409 6.965 12.6409C6.23833 12.6409 5.64667 12.0526 5.64667 11.3292ZM13.1975 15.2201C12.5333 15.8809 11.4908 16.2026 10.0108 16.2026L10 16.2001L9.98917 16.2026C8.50833 16.2026 7.46583 15.8809 6.8025 15.2201C6.68167 15.1001 6.68167 14.9042 6.8025 14.7842C6.92333 14.6634 7.12 14.6634 7.24083 14.7842C7.7825 15.3234 8.68167 15.5851 9.98917 15.5851L10 15.5876L10.0108 15.5851C11.3183 15.5851 12.2175 15.3226 12.7592 14.7834C12.88 14.6626 13.0767 14.6634 13.1975 14.7834C13.3183 14.9042 13.3183 15.0992 13.1975 15.2201ZM13.04 12.6409C12.3133 12.6409 11.7225 12.0526 11.7225 11.3292C11.7225 10.6059 12.3133 10.0167 13.04 10.0167C13.7667 10.0167 14.3575 10.6059 14.3575 11.3292C14.3575 12.0526 13.7667 12.6409 13.04 12.6409Z"
      fill="currentColor"
    />
    <defs>
      <clipPath id="clip0_122_647">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
