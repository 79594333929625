import axios from 'axios';

export class SubnetApi {
  async reqMethod(path, query = {}) {
    try {
      const response = await axios.get(path, { params: query });
      return [response.data, null];
    } catch {
      return [null, 'Unknown error, please try again later.'];
    }
  }

  async fetchData(endpoint, params = {}) {
    const [res, error] = await this.reqMethod(endpoint, params);
    return error ? error : res;
  }

  async fetchStatsData() {
    return this.fetchData(`/subnets/stats_data`);
  }

  async fetchActiveAddresses(subnet, timeframe) {
    return this.fetchData(`/subnets/active_addresses`, { subnet, timeframe });
  }

  async fetchTransactions(subnet, timeframe) {
    return this.fetchData(`/subnets/transactions`, { subnet, timeframe });
  }

  async fetchTotalAddresses(subnet, timeframe) {
    return this.fetchData(`/subnets/total_addresses`, { subnet, timeframe });
  }

  async fetchContracts(subnet, timeframe) {
    return this.fetchData(`/subnets/contracts`, { subnet, timeframe });
  }

  async fetchTpsAndMaxTps(subnet, timeframe) {
    return this.fetchData(`/subnets/tps_and_max_tps`, { subnet, timeframe });
  }

  async fetchGasUsed(subnet, timeframe) {
    return this.fetchData(`/subnets/gas_used`, { subnet, timeframe });
  }

  async fetchActiveSenders(subnet, timeframe) {
    return this.fetchData(`/subnets/active_senders`, { subnet, timeframe });
  }

  async fetchSubnetComparison() {
    return this.fetchData(`/subnets/subnet_comparison`);
  }

  async fetchSubnetNews(subnet, timeframe) {
    return this.fetchData(`/subnets/news`, { subnet, timeframe });
  }

  async fetchSubnetValidatorStats() {
    return this.fetchData(`/subnets/validator_stats`);
  }
}
