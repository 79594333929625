<template>
  <div class="w-full">
    <div class="mt-1 flex w-full animate-pulse items-center py-1">
      <div>
        <div class="h-6 w-6 rounded-3xl bg-gray-700/50"></div>
      </div>
      <div class="flex-1 space-y-2 py-1 px-4">
        <div class="flex justify-between">
          <div class="h-2 w-4/12 rounded bg-gray-700/50"></div>
          <div class="h-2 w-3/12 rounded bg-gray-700/50"></div>
        </div>
        <div class="flex justify-between">
          <div class="h-2 w-2/12 rounded bg-gray-700/50"></div>
          <div class="h-2 w-2/12 rounded bg-gray-700/50"></div>
        </div>
      </div>
    </div>
  </div>
</template>
