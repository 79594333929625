<template>
  <BaseCard
    class="flex h-[31rem] flex-shrink flex-col px-4"
    :control-length="selectedTimeframe?.length"
    :label-length="15"
  >
    <template #header>Activity</template>
    <template #controls>
      <BaseRadio
        v-model="selectedTimeframe"
        multiple
        button-width-class="w-32"
        :options="timeframes"
        placeholder="Select Type"
        :control-length="timeframes?.length"
      />
    </template>
    <div
      class="flex-1 overflow-hidden rounded-lg px-3 pt-4 pb-0 shadow"
      :class="loading ? 'flex' : ''"
    >
      <IconLoading class="m-auto h-8 w-8" v-if="loading" />
      <MultiLineChart
        :series="chartSeries"
        :config="chartConfig"
        :key="selectedTimeframe"
        v-else
      />
    </div>
  </BaseCard>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import MultiLineChart from '../../../Widgets/MultiLineChart.vue';
import { NftApi } from '../NftApi';
import { filterToDateTime, timeToUnix } from '../../../composeables/filters.js';
import moment from 'moment';
import IconLoading from '../../../icons/IconLoading.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../../Shared/Tooltip.vue';
import BaseCard from '../../../Shared/BaseCard.vue';
import BaseRadio from '../../../Shared/BaseRadio.vue';
const loading = ref(true);

const props = defineProps({
  collection: Object,
});
const selectedTimeframe = ref('30D');
const timeframes = ['7D', '30D', '3M', '6M', '1Y', 'ALL'];
const collectionActivtySeries = ref({ avax_price: 0, activities: [] });
const api = new NftApi();

onMounted(async () => {
  await fetchCollectionActivity();
});

async function fetchCollectionActivity() {
  collectionActivtySeries.value = await api.fetchCollectionActivity(
    props.collection?.collection,
  );
  loading.value = false;
}

const chartConfig = ref({
  height: '390',
  useUTC: false,
  yAxis: [
    {
      // Transactions
      labels: {
        style: {
          color: '#3b82f6',
        },
      },
      title: {
        text: '',
      },
      opposite: true,
      gridLineColor: '#262626',
    },
    {
      // Volume
      gridLineWidth: 0,
      title: {
        text: '',
      },
      labels: {
        style: {
          color: '#10b981',
        },
      },
      gridLineColor: '#262626',
    },
    {
      // Wallets
      gridLineWidth: 0,
      title: {
        text: '',
      },
      labels: {
        style: {
          color: '#f59e0b',
        },
      },
      opposite: true,
      gridLineColor: '#262626',
    },
  ],
});

const chartSeries = computed(() => {
  return [
    {
      name: 'Wallets',
      type: 'line',
      yAxis: 1,
      data: collectionWalletSeries.value,
      marker: {
        enabled: false,
      },
      color: '#10b981',
      animation: false,
    },
    {
      name: 'Transactions',
      type: 'line',
      data: collectionTransactionSeries.value,
      marker: {
        enabled: false,
      },
      color: '#3b82f6',
      animation: false,
    },
    {
      name: 'Volume USD',
      type: 'line',
      yAxis: 2,
      data: collectionVolumeSeries.value,
      marker: {
        enabled: false,
      },
      color: '#f59e0b',
      animation: false,
    },
  ];
});
const collectionVolumeSeries = computed(() => {
  return collectionActivtySeries.value.activities
    ?.filter((x) =>
      selectedTimeframe.value == 'ALL'
        ? true
        : moment(x.day).format() >= filterToDateTime(selectedTimeframe.value),
    )
    .map((x) => {
      return [
        timeToUnix(x.day, true),
        parseFloat(x.volume) *
          parseFloat(collectionActivtySeries.value.avax_price),
      ];
    });
});
const collectionTransactionSeries = computed(() => {
  return collectionActivtySeries.value.activities
    ?.filter((x) =>
      selectedTimeframe.value == 'ALL'
        ? true
        : moment(x.day).format() >= filterToDateTime(selectedTimeframe.value),
    )
    .map((x) => {
      return [timeToUnix(x.day, true), x.num_trxs];
    });
});
const collectionWalletSeries = computed(() => {
  return collectionActivtySeries.value.activities
    ?.filter((x) =>
      selectedTimeframe.value == 'ALL'
        ? true
        : moment(x.day).format() >= filterToDateTime(selectedTimeframe.value),
    )
    .map((x) => {
      return [timeToUnix(x.day, true), x.unique_users];
    });
});
</script>
