<template>
  <Teleport to="body">
    <transition name="fade" appear>
      <div class="h-full w-full" v-if="showModal" @click="close()">
        <div class="modal-overlay">
          <div v-if="selectedConfig.id.toLowerCase() == 'warm'">
            <div v-for="index in 5" :key="index">
              <div class="grid h-[20vh] w-full grid-cols-3 gap-0">
                <RainAnimation />
                <RainAnimation />
                <RainAnimation />
              </div>
            </div>
          </div>
          <div v-else>
            <SnowAnimation />
          </div>
        </div>
      </div>
    </transition>
    <transition name="slide" appear>
      <div
        class="modal box-shadow relative flex flex-col items-center rounded-lg border bg-transparent text-base font-normal backdrop-blur"
        id="modal"
        v-if="showModal"
      >
        <div class="absolute -z-10">
          <div v-if="selectedConfig.id.toLowerCase() == 'warm'">
            <div v-for="index in 3" :key="index">
              <div class="grid h-[20vh] w-full grid-cols-3 gap-0">
                <RainAnimation />
                <RainAnimation />
                <RainAnimation />
              </div>
            </div>
          </div>
          <div v-else>
            <div v-for="index in 3" :key="index">
              <div class="grid h-[20vh] w-full grid-cols-3 gap-0">
                <SnowAnimation />
                <SnowAnimation />
                <SnowAnimation />
              </div>
            </div>
          </div>
        </div>
        <div
          id="achievement"
          class="flex h-screen w-full place-items-center items-center justify-center rounded-lg bg-primary uppercase"
        >
          <div
            class="h-full w-full items-center rounded-lg"
            :class="[
              chillFactor ? setBgScoreBaseColor(chillFactor?.score) : '',
              modalBorderColor[selectedConfig?.id],
            ]"
          >
            <div
              v-if="selectedConfig.id != 'warm'"
              class="exclude absolute -left-2.5 -right-2.5 z-20 block"
              :class="[
                snowClass?.[selectedConfig.id],
                ['chilly', 'lukewarm'].includes(selectedConfig?.id)
                  ? '-top-2.5'
                  : '-top-5',
              ]"
            >
              <img
                :src="selectedConfig.imageUrl"
                class="w-full flex-shrink-0"
              />
            </div>
            <div
              class="absolute right-8 top-2 z-30 justify-end pb-4 pt-6 text-2xl font-semibold"
            >
              <div
                :class="`exclude rounded border p-1 ${
                  shareBorderColor[selectedConfig.id]
                } cursor-pointer ${selectedConfig?.buttonBgClass}`"
                @click="close(false)"
              >
                <XMarkIcon
                  :class="`h-4 w-4 cursor-pointer ${selectedConfig?.rangeClass}`"
                />
              </div>
            </div>
            <div
              class="flex w-full flex-col items-center justify-center space-y-4 pt-56 sm:pt-8"
            >
              <div class="text-lg font-black tracking-widest">
                {{ selectedConfig?.header }}
              </div>
              <div>
                <img :src="selectedConfig.textUrl" class="flex-shrink-0" />
              </div>
              <div
                class="text[#f3f2f6b8] text-xs font-medium leading-4 tracking-wide"
              >
                Chill Factor Range
              </div>
            </div>
            <div class="flex items-center justify-center pt-2">
              <button
                class="flex h-7 cursor-default items-center space-x-1 rounded px-4 text-sm"
                :style="selectedConfig?.rangeStyle"
                :class="selectedConfig?.rangeClass"
              >
                <CrownIcon />
                <span>{{ selectedConfig?.range }}</span>
              </button>
            </div>
            <div
              :class="`${setTier(
                chillFactor?.score,
              ).toLowerCase()}-text-gradient py-6 text-center text-4xl font-black`"
            >
              {{ simpleNumberFormatter(chillFactor?.score) }}
            </div>
            <div
              class="exclude hidden items-center justify-center pb-7 sm:flex"
            >
              <button
                :class="[
                  imageLoading ? 'pointer-events-none' : 'cursor-pointer',
                  `flex items-center space-x-1 rounded border px-4 py-1 text-base leading-7 ${
                    selectedConfig?.rangeClass
                  } ${shareBorderColor[selectedConfig.id]} ${
                    selectedConfig.buttonBgClass
                  }`,
                ]"
                @click="shareOnTwitter"
              >
                <IconLoading v-if="imageLoading" class="h-4 w-4" />
                <ShareIcon v-else class="h-4 w-4" />
                <span>Share Achievement</span>
              </button>
            </div>
            <div
              :class="[
                !imageLoading ? 'flex' : 'flex',
                'included items-center justify-center space-x-3 divide-x',
              ]"
            >
              <div>
                <img
                  class="h-2.5"
                  src="../images/frosty_logo.svg"
                  alt="Frosty Metrics Logo"
                />
              </div>
              <div class="px-2">
                <img
                  class="h-3"
                  src="../images/the_tie_logo.svg"
                  alt="The Tie Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import {
  setBgScoreBaseColor,
  setTier,
  simpleNumberFormatter,
  snowClass,
} from '../composeables/filters.js';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import CrownIcon from '../icons/CrownIcon.vue';
import WarmModelSnow from '../images/warm_modal_snow.svg';
import LukewarmModelSnow from '../images/lukewarm_modal_snow.svg';
import ChillyModelSnow from '../images/chilly_modal_snow.svg';
import FrozenModelSnow from '../images/frozen_modal_snow.svg';
import SubzeroModelSnow from '../images/subzero_modal_snow.svg';
import WarmText from '../images/warm_text.svg';
import LukewarmText from '../images/lukewarm_text.svg';
import ChillyText from '../images/chilly_text.svg';
import FrozenText from '../images/frozen_text.svg';
import SubzeroText from '../images/subzero_text.svg';
import ShareIcon from '../icons/ShareIcon.vue';
import {
  makePng,
  imageDataUrl,
  uploadedImageUrl,
} from '~/composeables/shareChillFactor';
import ShareScoreModal from '@/Shared/ShareScoreModal.vue';
import IconLoading from '~/icons/IconLoading.vue';
import SnowAnimation from '../Shared/SnowAnimation.vue';
import RainAnimation from '../Shared/RainAnimation.vue';
import { ChillFactorApi } from '../Pages/ChillFactor/ChillFactorApi';
const cfApi = new ChillFactorApi();

const emit = defineEmits(['close-modal']);
const props = defineProps({
  showModal: Boolean,
  chillFactor: { type: Object, default: null },
});
const generatingLink = ref(false);
const shareBorderColor = ref({
  warm: 'border-[#ffa366]',
  lukewarm: 'border-yellow-300',
  chilly: 'border-[#168D77]',
  frozen: 'border-indigo-400',
  subzero: 'border-[#BC8DEA]',
});

const modalBorderColor = ref({
  warm: 'border-[#ff8545]',
  lukewarm: 'border-[#e9c400]',
  chilly: 'border-[#04e0c7]',
  frozen: 'border-[#45a8ff]',
  subzero: 'border-[#7d0ce0]',
});

function close() {
  emit('close-modal', { showModal: false });
}

watch(
  () => props.chillFactor,
  () => {
    calculateRange();
  },
);

function calculateRange() {
  if (selectedConfig.value) {
    if (selectedConfig.value.id == 'warm') {
      config.value.find((x) => x.id == 'warm').range = `${simpleNumberFormatter(
        props.chillFactor.tier_cutoffs['warm'],
      )}-${simpleNumberFormatter(props.chillFactor.tier_cutoffs['lukewarm'])}`;
    } else if (selectedConfig.value.id == 'lukewarm') {
      config.value.find(
        (x) => x.id == 'lukewarm',
      ).range = `${simpleNumberFormatter(
        props.chillFactor.tier_cutoffs['lukewarm'],
      )}-${simpleNumberFormatter(props.chillFactor.tier_cutoffs['chilly'])}`;
    } else if (selectedConfig.value.id == 'chilly') {
      config.value.find(
        (x) => x.id == 'chilly',
      ).range = `${simpleNumberFormatter(
        props.chillFactor.tier_cutoffs['chilly'],
      )}-${simpleNumberFormatter(props.chillFactor.tier_cutoffs['frozen'])}`;
    } else if (selectedConfig.value.id == 'frozen') {
      config.value.find(
        (x) => x.id == 'frozen',
      ).range = `${simpleNumberFormatter(
        props.chillFactor.tier_cutoffs['frozen'],
      )}-${simpleNumberFormatter(props.chillFactor.tier_cutoffs['subzero'])}`;
    } else if (selectedConfig.value.id == 'subzero') {
      config.value.find(
        (x) => x.id == 'subzero',
      ).range = `${simpleNumberFormatter(
        props.chillFactor.tier_cutoffs['subzero'],
      )}`;
    }
  }
}

const selectedConfig = computed(() => {
  const tier = setTier(props.chillFactor?.score);
  return config.value.find((item) => item.id === tier?.toLowerCase()) || {};
});

// SHARE MODAL
const generatingLinkFor = ref(null);
const openShareModal = ref(false);
const imageBlob = ref(null);
const shareableLink = ref(null);
const imageLoading = ref(null);
const closeShareModal = () => {
  document.body.classList.remove('overflow-y-hidden');
  close();
  openShareModal.value = false;
};

const shareOnTwitter = async () => {
  imageLoading.value = true;
  let linkFor = 'twitter';
  const elem = document.getElementById('achievement');
  let dimensions = { height: elem.clientHeight, width: elem.clientWidth };
  await makePng('achievement', null, false, dimensions);
  // document.body.classList.add('overflow-y-hidden');

  generatingLink.value = true;
  generatingLinkFor.value = linkFor;
  const file = await makeImageFile(uploadedImageUrl.value);
  await uploadChillFactor(file, linkFor);
};

const makeImageFile = async (dataUrl) => {
  let fetchUrl = await fetch(dataUrl);
  imageBlob.value = await fetchUrl.blob();
  return new File([imageBlob.value], 'chillFactor', { type: 'image/png' });
};

const uploadChillFactor = async (file, linkFor) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const data = await cfApi.uploadImage(formData);
    generatingLink.value = false;
    shareableLink.value = data.url;
    switch (linkFor) {
      case 'twitter':
        makeTwitterUrl();
        generatingLinkFor.value = null;
        break;
    }
  } catch (e) {
    console.error(e);
  }
};

const twitterDescription = computed(() => {
  return `I just checked my Avalanche Chill Factor score on FrostyMetrics and am ${props.chillFactor.value?.current_snapshot?.tier.toUpperCase()} with a score of ${
    props.chillFactor.value?.current_snapshot?.score
  }. Are you chiller than me?

To check your Chill Factor visit Frosty Metrics: https://frostymetrics.com/
`;
});

const makeTwitterUrl = () => {
  if (shareableLink.value.length) {
    const twitterPostIntentUrl = new URL('https://twitter.com/intent/tweet?');
    twitterPostIntentUrl.searchParams.append('url', shareableLink.value);
    twitterPostIntentUrl.searchParams.append('text', twitterDescription.value);
    imageLoading.value = false;
    window.open(
      twitterPostIntentUrl.href,
      'Share Chill Factor',
      'width=700,height=650',
    );
  }
};

const config = ref([
  {
    id: 'warm',
    title: 'Warm',
    header: 'You seem a little',
    range: '',
    rangeStyle: 'background-color: rgba(255, 214, 0, 0.12)',
    rangeClass: 'text-[#ffa366]',
    imageUrl: WarmModelSnow,
    textUrl: WarmText,
    buttonBgClass: 'bg-[#ff6a001f] hover:bg-[#ff6a003d]',
  },
  {
    id: 'lukewarm',
    title: 'Luke Warm',
    header: 'You’ve Cooled off to',
    range: '',
    rangeStyle: 'background-color: rgba(255, 214, 0, 0.12);',
    rangeClass: 'text-[#FFD600]',
    imageUrl: LukewarmModelSnow,
    textUrl: LukewarmText,
    buttonBgClass: 'bg-[#FFD6001F] hover:bg-[#FFD6003D]',
  },
  {
    id: 'chilly',
    title: 'Cold',
    header: 'Brrr it’s starting to get',
    range: '',
    rangeStyle: 'background-color: rgba(28, 252, 226, 0.12);',
    rangeClass: 'text-[#1cfce2]',
    imageUrl: ChillyModelSnow,
    textUrl: ChillyText,
    buttonBgClass: 'bg-[#1CFCE21F] hover:bg-[#1CFCE23D]',
  },
  {
    id: 'frozen',
    title: 'Frozen',
    header: 'You Look a little',
    range: '',
    rangeStyle: 'background-color: rgba(58, 104, 255, 0.24);',
    rangeClass: 'text-indigo-400',
    imageUrl: FrozenModelSnow,
    textUrl: FrozenText,
    buttonBgClass: 'bg-[#6BC1FF1F] hover:bg-[#6BC1FF3D]',
  },
  {
    id: 'subzero',
    title: 'Subzero',
    header: 'You are Absolute',
    range: '',
    rangeStyle: 'background-color: rgba(243, 242, 246, 0.24)',
    rangeClass: 'text-[#BC8DEA]',
    imageUrl: SubzeroModelSnow,
    textUrl: SubzeroText,
    buttonBgClass: 'bg-[#CFC3FF1F] hover:bg-[#CFC3FF3D]',
  },
]);
</script>
<style scoped>
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 100%;
  height: 430px;
  max-width: 700px;
  max-height: 500px;
  border-radius: 9px;
  padding: 0px !important;
  box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.04) inset;
  box-shadow: 4px 14px 86.5px 0px rgba(162, 62, 0, 0.32);
}

@media (max-width: 450px) {
  .modal {
    height: 100vh; /* Set height to 100% of viewport height */
    width: 100%;
    max-height: 100vh;
    max-width: none;
    padding: 0px !important;
    box-sizing: border-box; /* Include padding in width calculation */
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  background-color: rgba(15, 20, 38, 0.7);
}

.snowfall {
  background-image: url('../images/snowfall-transparent-backgroun-unscreen.gif');
  background-repeat: repeat-xy;
}
</style>
