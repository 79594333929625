<template>
  <Vue3Marquee
    :duration="pageUrl.includes('nfts') ? 200 : 110"
    :pauseOnHover="true"
    :key="pageUrl"
  >
    <div
      class="mx-auto flex items-center space-x-8 overflow-hidden overflow-x-auto py-4 px-6 text-xs sm:text-sm md:text-sm lg:text-base 2xl:text-lg"
    >
      <template v-if="!pageUrl.includes('nfts')">
        <Link
          :href="`/${pageUrl.includes('defi') ? 'defi' : 'tokens'}/${
            token.ticker
          }`"
          class="flex flex-shrink-0 items-center space-x-2.5"
          v-for="token in tokens"
          :key="token.uid"
        >
          <img :src="token.icon" loading="lazy" class="h-4 rounded-full" />
          <span class="text-xs font-medium text-gray-400">{{
            token.ticker
          }}</span>
          <span class="text-xs font-medium">
            ${{ formatNumber(token.price, 6) }}</span
          >
          <span
            :class="`text-xs font-medium ${
              token.price_return_24_hours > 0
                ? 'text-green-500'
                : 'text-red-800'
            }`"
          >
            {{ percentage(token.price_return_24_hours) }}</span
          >
        </Link>
      </template>
      <template v-else>
        <Link
          :href="`/nfts/${collection?.collection}`"
          class="flex flex-shrink-0 items-center space-x-2.5"
          v-for="collection in topCollections"
          :key="collection?.collection"
        >
          <img
            :src="collection.image_url"
            loading="lazy"
            class="h-4 rounded-full"
            v-if="isValidUrl(collection?.image_url)"
          />
          <span class="mr-2 h-4 w-4 rounded-full bg-gray-800" v-else></span>
          <span class="text-xs font-medium text-gray-400">{{
            collection?.collection_name
          }}</span>
          <span class="text-xs font-medium">
            {{ formatNumber(collection.floor_price, 2) }} AVAX</span
          >
          <span
            :class="`text-xs font-medium ${
              collection['1d_change_in_floor'] > 0
                ? 'text-green-500'
                : 'text-red-500'
            }`"
          >
            {{ percentage(collection['1d_change_in_floor'], 2, true) }}</span
          >
        </Link>
      </template>
    </div>
  </Vue3Marquee>
</template>

<script setup>
import { Link } from '@inertiajs/inertia-vue3';
import { Inertia } from '@inertiajs/inertia';
import { ref, inject, computed, onMounted } from 'vue';
import {
  rounded,
  percentage,
  formatNumber,
  isValidUrl,
} from '../composeables/filters.js';
import { Vue3Marquee } from 'vue3-marquee';
import 'vue3-marquee/dist/style.css';

const universeTokens = inject('universeTokens');
const universeDefiTokens = inject('universeDefiTokens');
const tokens = computed(() => {
  return pageUrl.value.includes('defi')
    ? universeDefiTokens.value
    : universeTokens.value;
});
const topCollections = inject('universeTopCollections');
const pageUrl = ref('defi');

Inertia.on('navigate', (event) => {
  let url = event.detail.page.url.split('/').filter((x) => x)[0];
  pageUrl.value = url == undefined ? 'defi' : url;
});
</script>
