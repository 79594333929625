<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8333 0H4.16667C1.86583 0 0 1.86583 0 4.16667V15.8333C0 18.1342 1.86583 20 4.16667 20H15.8333C18.135 20 20 18.1342 20 15.8333V4.16667C20 1.86583 18.135 0 15.8333 0ZM13.3333 5.83333H11.73C11.2175 5.83333 10.8333 6.04333 10.8333 6.57417V7.5H13.3333L13.135 10H10.8333V16.6667H8.33333V10H6.66667V7.5H8.33333V5.8975C8.33333 4.2125 9.22 3.33333 11.2175 3.33333H13.3333V5.83333Z"
      fill="currentColor"
    />

    <defs>
      <clipPath id="clip0_122_655">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
