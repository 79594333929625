<template>
  <BaseCard class="mx-auto flex flex-wrap">
    <template #header>Trade {{ token.name }}</template>
    <!-- <div class="flex w-full flex-shrink flex-grow flex-col px-4 py-3">
      <div class="flex space-x-2 font-normal">
        <h2 class="text-lg tracking-tight text-textColor">
          Trade {{ token.name }}
        </h2>
        <Tooltip text="Notable Listings" id="notable_listings">
          <InformationCircleIcon class="h-5 w-5 text-infoIconColor" />
        </Tooltip>
      </div> -->
      <div class="relative h-72 overflow-x-auto rounded-lg">
        <span v-if="tradeTableData?.length > 0">
          <Table
            :config="tradeTableConfig"
            :data="_.orderBy(tradeTableData, 'volume', 'desc')"
            class="py-2"
          ></Table>
        </span>
        <span v-else class="mt-auto flex h-full w-full justify-center">
          <NoDataFound />
        </span>
      </div>
    <!-- </div> -->
  </BaseCard>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import Table from '../../Shared/Table.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import { TokenApi } from './TokenApi';
import BaseCard from '../../Shared/BaseCard.vue';
import _ from 'lodash';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../Shared/Tooltip.vue';
const api = new TokenApi();

const props = defineProps({
  token: Object,
});
const tradePairsData = ref([]);
onMounted(async () => {
  tradePairsData.value = await api.fetchTradePairs(props.token.ticker);
});

const tradeTableData = computed(() => {
  let pairs = tradePairsData.value;
  let total_volume = pairs?.reduce(
    (partialSum, a) => partialSum + a.trading_volume_usd_24_hours,
    0,
  );
  return pairs.map((tp) => {
    return {
      exchange: tp.market,
      pair: tp.pair.includes('0X')
        ? `${tp.base_coin}/${tp.target_coin}`
        : tp.pair,
      price: tp.price,
      plus_depth: tp.cost_to_move_up_usd,
      minus_depth: tp.cost_to_move_down_usd,
      trading_volume_usd_24_hours: tp.trading_volume_usd_24_hours,
      volume: tp.trading_volume_usd_24_hours / total_volume,
      trade_link: { name: 'Trade', link: tp.trade_url },
    };
  });
});

const tradeTableConfig = ref({
  cols: [
    { type: 'coin', name: 'Exchange', id: 'exchange', textLeft: 'left' },
    {
      type: 'text',
      name: 'Pair',
      id: 'pair',
      textLeft: true,
      style: 'w-full max-w-[16rem] truncate uppercase',
    },
    { type: 'dollar', name: 'Price', id: 'price' },
    { type: 'dollar', name: '+2 Depth', id: 'plus_depth' },
    { type: 'dollar', name: '-2 Depth', id: 'minus_depth' },
    { type: 'dollar', name: '24 Volume', id: 'trading_volume_usd_24_hours' },
    { type: 'percent', name: 'Volume %', id: 'volume', showColorSymbol: false },
    { type: 'link', name: 'Trade (Link)', id: 'trade_link' },
  ],
  paddingLeftRight: 'px-5',
});
</script>
