<template>
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM5.01641 9.7855H3.65792C3.37246 9.7855 3.23145 9.7855 3.14546 9.73048C3.05261 9.67028 2.99585 9.57055 2.98897 9.46049C2.98382 9.35903 3.05432 9.23522 3.19533 8.98759L6.54964 3.07515C6.69237 2.82408 6.7646 2.69856 6.85574 2.65212C6.95376 2.60227 7.07069 2.60227 7.16871 2.65212C7.25985 2.69856 7.33208 2.82408 7.47481 3.07515L8.16438 4.27889L8.16791 4.28504C8.32206 4.55439 8.40024 4.69097 8.43436 4.83433C8.47219 4.99082 8.47219 5.1559 8.43436 5.31238C8.39998 5.45684 8.32258 5.59442 8.16609 5.86784L6.40417 8.98244L6.3996 8.99042C6.24441 9.26198 6.16578 9.3996 6.05679 9.50349C5.93814 9.61698 5.79541 9.69951 5.63893 9.74595C5.4962 9.7855 5.33627 9.7855 5.01641 9.7855ZM8.44708 9.78566H10.3937C10.6809 9.78566 10.8253 9.78566 10.9113 9.72892C11.0042 9.66872 11.0626 9.56726 11.0678 9.45721C11.0728 9.3591 11.0038 9.24007 10.8686 9.00685C10.864 8.99888 10.8593 8.99081 10.8546 8.98259L9.87954 7.31456L9.86844 7.2958C9.73143 7.06407 9.66223 6.94707 9.57345 6.90185C9.47542 6.85197 9.36022 6.85197 9.2622 6.90185C9.17277 6.94826 9.10054 7.07037 8.95781 7.31627L7.98623 8.9843L7.9829 8.99006C7.84067 9.23557 7.76959 9.35825 7.77472 9.45894C7.7816 9.56899 7.83833 9.67045 7.93121 9.73063C8.01546 9.78565 8.1599 9.78566 8.44708 9.78566Z"
      fill="currentColor"
    />
  </svg>
</template>
<script setup>
</script>
