<template>
  <div class="flex flex-wrap">
    <div
      v-for="tag in tags"
      :key="tag.id"
      @click="toggle(tag)"
      :class="[
        'selected-none rounded font-medium duration-100',
        styleClass,
        tag.customClass,
        disabled ? '' : 'cursor-pointer',
        selected.includes(tag.id)
          ? 'bg-blue-900 text-blue-100'
          : [tagBgClass, tagTextClass, hoverClass].join(' '),
      ]"
    >
      {{ tag.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    tagBgClass: {
      type: String,
      default: 'bg-gray-700/50',
    },
    tagTextClass: {
      type: String,
      default: 'text-gray-300',
    },
    styleClass: {
      type: String,
      default: 'px-2 py-1 text-xs mr-4 mb-2',
    },
    hoverClass: {
      type: String,
      default: 'hover:bg-gray-700/50 hover:text-gray-100',
    },
  },
  data() {
    return {};
  },
  methods: {
    toggle: function (t) {
      if (this.disabled) {
        return;
      }

      this.$emit('toggle', t.id);
    },
  },
};
</script>
