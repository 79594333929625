import axios from 'axios';
export class DefiApi {
  async reqMethod(path, query) {
    try {
      return [(await axios.get(path, { params: query })).data, null];
    } catch {
      return [null, 'Unknown error, please try again later.'];
    }
  }

  async fetchTvlSingleChartData(urlParam) {
    const [res, error] = await this.reqMethod(`/defi/${urlParam}/tvl_chart`);
    return error ? error : res;
  }

  async fetchActivityGrowthChartData(urlParam, query) {
    const [res, error] = await this.reqMethod(
      `/projects/${urlParam}/chart_data`,
      query,
    );

    return error ? error : res;
  }

  async fetchNews(urlParam) {
    const [res, error] = await this.reqMethod(`/tokens/${urlParam}/news`);

    return error ? error : res;
  }

  async fetchTvlChartData() {
    const [res, error] = await this.reqMethod(`/defi/chain_chart`);

    return error ? error : res;
  }

  async fetchDexChartData() {
    const [res, error] = await this.reqMethod(`/defi/dex_data`);

    return error ? error : res;
  }

  async fetchEcosystemActivityData() {
    const [res, error] = await this.reqMethod(`/defi/ecosystem_activity`);

    return error ? error : res;
  }

  async fetchDappActivityData(query) {
    const [res, error] = await this.reqMethod(`/defi/dapp_activity`, query);

    return error ? error : res;
  }

  calculateChange(newPrice, oldPrice) {
    newPrice = parseFloat(newPrice) || 0;
    oldPrice = parseFloat(oldPrice) || 0;
    return oldPrice == 0 ? 0 : (newPrice - oldPrice) / oldPrice;
  }
}
