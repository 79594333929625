<template>
  <div class="flex-1 overflow-hidden rounded-lg shadow" id="pieGraphContainer">
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script setup>
import { ref, computed, reactive, onMounted, onUnmounted } from 'vue';
import {
  percentage,
  titleize,
  colors,
  formatNumber,
  decimals,
} from '../composeables/filters.js';
const props = defineProps({
  dataSeries: Array,
  title: String,
  formatType: String,
  config: Object,
});
onMounted(() => {
  window.addEventListener('resize', getDimensions);
  getDimensions();
});
onUnmounted(() => {
  window.removeEventListener('resize', getDimensions);
});
const width = ref();
const height = ref();
function getDimensions() {
  width.value = document.getElementById('pieGraphContainer').clientWidth;
  height.value = document.getElementById('pieGraphContainer').clientHeight;
}

function tooltipFormat(value) {
  return props.formatType == 'number'
    ? formatNumber(value, '')
    : percentage(value, 2, false, false);
}

const chartOptions = computed(() => {
  return {
    chart: {
      width: width.value,
      height: height.value,
      type: 'pie',
      zoomType: '',
      backgroundColor: 'transparent',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      animation: false,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    series: [
      {
        data: props.dataSeries,
      },
    ],
    legend: {
      enabled: props.config?.legend || false,
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
      itemMarginBottom: 3,
      itemMarginTop: 3,
      width: 140,
      itemStyle: {
        color: 'white',
        fontWeight: '400',
        fontSize: '12px',
        fontFamily: 'Inter',
        letterSpacing: 0,
        textTransform: 'capitalize',
        border: '1px solid #000',
      },
      itemHoverStyle: {
        color: 'rgba(255,255,255,0.85)',
        border: '1px solid #000',
      },
      itemHiddenStyle: {
        color: 'rgba(255,255,255,0.25)',
      },
      navigation: {
        style: {
          color: 'rgba(255,255,255,0.55)',
        },
      },
    },
    plotOptions: {
      pie: {
        opacity: 0.9,
        allowPointSelect: true,
        innerSize: '85%',
        colors: colors(),
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          useHTML: true,
          color: 'white',
          formatter: function () {
            let title = this.key;
            const fromattedPnts = `
                  <span><b>${title}</b><br/> ${tooltipFormat(
              this.point.y,
            )}</span>`;
            return fromattedPnts + '<br/>';
          },
        },
        showInLegend: true,
        borderWidth: 0,
        shadow: false,
      },
      series: {
        animation: false,
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: 'transparent',
      borderWidth: 0,
      style: {
        color: '#fff',
        pointerEvents: 'auto',
      },
      formatter() {
        const title = titleize(this.key, ' ');
        const formattedPoint = `<span style="color:${
          this.point.color
        }; margin-left: 0.2rem; margin-right: 0.2rem;">${tooltipFormat(
          this.point.y,
        )}</span>`;
        const rawValue = `<b>${showRawValue(this.point)}</b>`;
        return `<div class="custom-tooltip">
          <b>${title}: </b>${formattedPoint} ${
          this.point?.length > 0 ? rawValue : ''
        }</div>`;
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
  };
});

function showRawValue(point) {
  let value = '';
  if (point?.rawValue) {
    let valuesArray = point?.rawValue.includes('$')
      ? point?.rawValue.split('$')
      : '';
    value =
      valuesArray && valuesArray[1]
        ? '$ ' + decimals(valuesArray[1])
        : point?.rawValue;
  }
  return value;
}
</script>
