<template>
  <div class="relative z-50">
    <button
      @click="emit('close')"
      v-if="show"
      class="fixed left-0 right-0 top-0 bottom-0 h-full w-full"
    ></button>
    <!-- Dropdown menu -->
    <div
      v-show="show"
      class="absolute right-0 mt-0.5 w-60 overflow-auto rounded-md border border-[#303547] bg-grid text-gray-500 shadow-xl"
      :class="`${position == 'top' ? 'bottom-9' : ''} ${configuration.classes}`"
    >
      <span
        v-for="item in items"
        :key="item.id"
        class="flex cursor-pointer items-center space-x-2 px-4 py-2 text-sm text-gray-500 hover:bg-gray-700/50"
        :class="`${configuration.textColor}`"
        @click="selected(item)"
      >
        <img
          :src="getImage(item.image)"
          class="h-6 w-6 rounded-full"
          v-if="item.image"
        />
        <span>
          {{ item.title }}
        </span>
      </span>
    </div>
  </div>
</template>
<script setup>
import joepegs from '../images/joepegs.png';
import campfire from '../images/campfire.png';
import kalao from '../images/kalao.png';
import nftrade from '../images/nftrade.png';
import opensea from '../images/opensea.png';
import pizzagame from '../images/pizza_game.png';
import cryptoroyale from '../images/crypto_royal.png';
import { ref } from 'vue';
const emit = defineEmits(['selected', 'close']);

const props = defineProps({
  items: {
    type: Object,
    default: null,
  },
  btnClasses: {
    type: String,
    default: '',
  },
  btnName: {
    type: String,
    default: '',
  },
  position: {
    type: String,
    default: 'bottom',
  },
  closeOnSelect: {
    type: Boolean,
    default: true,
  },
  configuration: {
    type: Object,
    default: { classes: 'h-40 overflow-y-auto' },
  },
  show: {
    type: Boolean,
    default: false,
  },
});

function selected(item) {
  if (props.closeOnSelect) {
    emit('close');
  }
  emit('selected', item);
}
function getImage(name) {
  name = name.replace(/\s/g, '');
  return (
    {
      joepegs: joepegs,
      campfire: campfire,
      kalao: kalao,
      nftrade: nftrade,
      opensea: opensea,
      pizzagame: pizzagame,
      cryptoroyale: cryptoroyale,
    }[name] || name
  );
}
</script>
