<template>
  <div class="mx-auto text-sm">
    <IndividualCoinHeader :token="token" />

    <section>
      <div class="mx-auto flex-wrap py-2 lg:flex">
        <span class="w-full md:flex md:space-x-4 lg:space-x-4">
          <div
            class="flex flex-shrink flex-col py-2 sm:w-full md:w-4/12 lg:w-1/3"
            v-for="item in statsItems"
            :key="item.id"
            id="lineGraphContainer"
          >
            <StatsWidget
              :statItem="item"
              :dollar-sign="item.dollar"
              :show-loading="chartLoading"
              height=""
              class="h-[7rem]"
            />
          </div>
        </span>
      </div>
    </section>

    <section
      class="grid grid-cols-1 space-y-4 lg:grid-cols-2 lg:gap-4 lg:space-y-0 xl:grid-cols-3"
    >
      <!-- line chart section -->
      <BaseCard class="xl:col-span-2" tooltipText="Token Price Chart">
        <template #header>Price Chart</template>
        <template #controls>
          <BaseRadio
            v-model="selectedTimeframe"
            multiple
            button-width-class="w-32"
            :options="timeframes"
            placeholder="Select Type"
          />
        </template>
        <div class="h-96 flex-1 overflow-hidden rounded-lg shadow">
          <IndividualPageLineChart
            :series="chartData"
            :key="`${token.price}_${selectedTimeframe}`"
          />
        </div>
      </BaseCard>

      <!-- NewsSection  -->
      <BaseCard class="">
        <template #header>Latest News</template>
        <div class="overflow-x-auto px-4 sm:py-3">
          <div class="wrap h-96 space-y-2 overflow-y-auto">
            <div v-if="newsLoading">
              <div v-for="i in 5" :key="i">
                <NewsTemplate />
              </div>
            </div>
            <div v-if="newsItems.length > 0">
              <div
                v-for="item in newsItems"
                :key="item.id"
                class="flex h-[7rem] items-center border-b border-gray-700"
              >
                <NewsWidget :news-item="item" />
              </div>
            </div>
            <div
              v-else-if="!newsLoading && newsItems"
              class="mt-auto flex h-full w-full justify-center"
            >
              <NoDataFound />
            </div>
          </div>
        </div>
      </BaseCard>
    </section>

    <section
      class="grid grid-cols-1 space-y-4 py-4 lg:grid-cols-2 lg:gap-4 lg:space-y-0 xl:grid-cols-3"
    >
      <!-- Price Return Section -->
      <BaseCard class="col-span-1">
        <template #header>Price Return</template>
        <PriceReturn :token="token" :avaxToken="avaxToken" />
      </BaseCard>
      <!-- Social Metrics Section -->
      <BaseCard class="col-span-1">
        <template #header>Social Metrics</template>
        <SocialMetrics :token="token" />
      </BaseCard>
      <!-- Key Metrics Section -->
      <BaseCard class="col-span-1">
        <template #header>Key Metrics</template>
        <div class="relative h-80 overflow-x-auto">
          <CoinsKeyMetricsVue :token="token" />
        </div>
        <!-- </div> -->
      </BaseCard>
    </section>

    <section>
      <TradePairs :token="token" />
    </section>
  </div>
</template>

<script setup>
import StatsWidget from '../../Widgets/Stats.vue';
import NewsWidget from '../../Widgets/News.vue';
import IndividualCoinHeader from '../../Shared/IndividualCoinHeader.vue';
import { ref, watch, onMounted, computed, inject } from 'vue';
import IndividualPageLineChart from '../../Widgets/LineChart.vue';
import Table from '../../Shared/Table.vue';
import { TokenApi } from './TokenApi';
import NewsTemplate from '../../load_templates/NewsTemplateCard.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import CoinsKeyMetricsVue from './CoinsKeyMetrics.vue';
import PriceReturn from './PriceReturn.vue';
import SocialMetrics from './SocialMetrics.vue';
import TradePairs from './TradePairs.vue';
import BaseCard from '../../Shared/BaseCard.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../Shared/Tooltip.vue';
import BaseRadio from '../../Shared/BaseRadio.vue';

const timeframes = ['7D', '30D', '3M', '6M', '1Y', 'ALL'];
const selectedTimeframe = ref('30D');
const api = new TokenApi();

const props = defineProps({
  current_token: Object,
  avax_token: Object,
  token_id: String,
});
const allTokens = inject('universeTokens');
const token = computed(() => {
  return (
    allTokens.value?.find((x) => x.ticker == props.token_id) ||
    props.current_token
  );
});

const avaxToken = computed(() => {
  return allTokens.value?.find((x) => x.ticker == 'AVAX') || props.avax_token;
});

watch(
  () => token.value,
  (newVal, oldVal) => {
    if (chartData.value.length > 0) {
      chartData.value[0][1] = parseFloat(token.value.price);
    }
  },
);

const newsItems = ref([]);
const chartData = ref([]);
const statsData = ref({});

onMounted(() => {
  Promise.all([fetchChartData(), fetchNews(), fetchStats()]);
});

watch(selectedTimeframe, async (newVal, oldVal) => {
  chartData.value = [];
  await fetchChartData();
});

async function fetchChartData() {
  let response = await api.fetchChartData(token.value.uid, {
    timeframe: selectedTimeframe.value,
  });
  chartData.value = typeof response == 'string' ? [] : response;
}
const newsLoading = ref('true');
async function fetchNews() {
  newsItems.value = await api.fetchNews(token.value.ticker);
  newsLoading.value = false;
}

const chartLoading = ref(true);
async function fetchStats() {
  statsData.value = await api.fetchStats(token.value.ticker);
  chartLoading.value = false;
}

const statsItems = computed(() => {
  let stats = [
    {
      id: 1,
      title: 'Trading Volume',
      total: 0,
      from: 0,
      growth: 0,
      dollar: true,
      usd: true,
    },
    { id: 2, title: 'Twitter Volume', total: 0, from: 0, growth: 0 },
    {
      id: 3,
      title: 'On-Chain Volume',
      total: 0,
      from: 0,
      growth: 0,
      dollar: true,
      usd: true,
    },
  ];
  let res = statsData.value;
  if (Object.keys(res).length > 0) {
    let last = res.stats[res.stats.length - 1];
    stats[0].total = parseFloat(token.value.trading_volume || 0);
    stats[0].from = parseFloat(last.trading_volume || 0);
    stats[0].growth = api.calculateChange(stats[0].total, stats[0].from);
    stats[0].chart = res.stats.map((x, i) => {
      return [
        new Date(x.datetime).getTime(),
        parseFloat(i == 0 ? token.value.trading_volume : x.trading_volume) || 0,
      ];
    });

    stats[1].total = parseFloat(token.value.tweet_volume || 0);
    stats[1].from = parseFloat(last.tweet_volume || 0);
    stats[1].growth = api.calculateChange(stats[1].total, stats[1].from);
    stats[1].chart = res.stats.map((x, i) => {
      return [
        new Date(x.datetime).getTime(),
        parseFloat(i == 0 ? token.value.tweet_volume : x.tweet_volume) || 0,
      ];
    });

    stats[2].total = res.onchain[0]?.volume_usd;
    stats[2].from = res.onchain[res.onchain?.length - 1]?.volume_usd;
    stats[2].growth = api.calculateChange(stats[2].total, stats[2].from);
    stats[2].chart = res.onchain?.map((x) => {
      return [new Date(x.date).getTime(), parseFloat(x.volume_usd) || 0];
    });
  }
  return stats;
});
</script>
