<template>
  <div class="w-full">
    <div class="mt-1 flex w-full animate-pulse items-center py-1">
      <div>
        <div class="h-2 w-28 rounded bg-gray-700/50"></div>
      </div>
      <div class="flex-1 space-y-2 px-4 py-1">
        <div class="flex justify-between">
          <div class="h-2 w-7/12 rounded bg-gray-700/50"></div>
          <div class="h-2 w-4/12 rounded bg-gray-700/50"></div>
        </div>
      </div>
    </div>
  </div>
</template>
