<template>
  <div class="w-full">
    <div class="mt-1 flex w-full animate-pulse items-center py-1">
      <div class="flex-1 space-y-2 py-1 px-4">
        <div class="h-2 w-10/12 rounded bg-gray-700/50"></div>
        <div class="h-2 w-2/4 rounded bg-gray-700/50"></div>
        <div class="flex">
          <div class="h-2 w-2/12 rounded bg-gray-700/50"></div>
          <div class="ml-2 h-2 w-1/12 rounded bg-gray-700/50"></div>
        </div>
      </div>
      <div class="h-12 w-4/12 rounded bg-gray-700/50"></div>
    </div>
  </div>
</template>
