<template>
  <Teleport to="body">
    <transition name="fade" appear>
      <div class="h-full w-full" v-if="isOpen" @click="emits('close')">
        <div class="connect-wallet-overlay backdrop-blur-lg">
          <template v-if="tier?.toLowerCase() == 'warm'">
            <RainAnimation />
          </template>

          <template v-else-if="tier?.toLowerCase() != 'warm'">
            <SnowAnimation />
          </template>
        </div>
      </div>
    </transition>
    <transition name="slide" appear>
      <div
        class="connect-wallet box-shadow relative flex flex-col items-center space-y-3 rounded-lg border border-[#5268B880] bg-transparent p-1 px-4 text-base font-normal backdrop-blur"
        :style="[maxWidth ? 'max-width: fit-content !important' : '']"
        id="modal"
        v-if="isOpen"
      >
        <div class="absolute -top-4 -left-[11.5px] z-20 hidden sm:block">
          <img src="../images/share_modal_snow.svg" class="flex-shrink-0" />
        </div>
        <div class="flex w-full flex-col space-y-3 py-4 px-4 sm:py-8">
          <div
            class="flex items-center justify-between px-16 pb-4 font-semibold xs:px-0 sm:px-0"
          >
            <div class="text-medium md:text-2xl">Share Chill Factor!</div>
            <div
              class="cursor-pointer rounded border border-[#FFFFFF3D] p-1"
              @click="emits('close')"
            >
              <XMarkIcon class="h-4 w-4 cursor-pointer text-[#f3f2f6e0]" />
            </div>
          </div>
          <div
            class="sm:text-light w-fit px-16 text-xs xs:px-0 sm:px-0 md:text-lg"
          >
            I just checked my Avalanche Chill Factor score on FrostyMetrics and
            am <span class="font-bold uppercase"> {{ tier }} </span> with a
            score of {{ simpleNumberFormatter(score.toFixed(0)) }} Are you
            Chiller than me? <br />
            To check your Chill Factor visit
            <a href="https://frostymetrics.com/" class="underline"
              >Frosty Metrics</a
            >
          </div>
          <div
            :class="[
              maxWidth ? '' : 'px-16',
              'relative flex w-full flex-col space-y-8',
            ]"
          >
            <div
              id="share-image-wrapper"
              class="relative flex w-full items-center justify-center"
            >
              <img id="shared-image" class="w-full" :src="dataUrl" />
            </div>
            <div class="z-30 flex items-center justify-center space-x-2">
              <div>
                <Button
                  style="min-width: 104px"
                  :css-classes="[
                    'flex items-center justify-center bg-[#1132643D] text-[#0091FF] shadow-md rounded-md py-1 px-3 text-xs min-h-8 min-w-8',
                  ]"
                  @click="getShareLink('twitter')"
                  :disabled="generatingLink"
                >
                  <div v-if="generatingLinkFor !== 'twitter'">X (Twitter)</div>
                  <div v-if="generatingLinkFor === 'twitter'">
                    <IconLoading class="h-4 w-4" />
                  </div>
                </Button>
              </div>
            </div>
            <div id="copy-message" class="hidden items-center text-center">
              Copied!
            </div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script setup>
import { onMounted, ref, watch, nextTick, computed } from 'vue';
import {
  XMarkIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/vue/24/outline';
import { ChillFactorApi } from '@/Pages/ChillFactor/ChillFactorApi';
import Button from '@/Shared/Button.vue';
import IconLoading from '@/icons/IconLoading.vue';
import {
  imageDimensions,
  uploadedImageUrl,
} from '@/composeables/shareChillFactor';
import SnowAnimation from '../Shared/SnowAnimation.vue';
import RainAnimation from '../Shared/RainAnimation.vue';
import { simpleNumberFormatter } from '../composeables/filters';
const api = new ChillFactorApi();

const props = defineProps({
  isOpen: { type: Boolean, default: false },
  dataUrl: { type: String, default: null },
  maxWidth: { type: Boolean, default: false },
  tier: { type: String, default: '' },
  score: { type: String, default: '' },
});

const emits = defineEmits(['close']);

const opened = ref(props.isOpen);

watch(
  () => props.isOpen,
  () => {
    opened.value = props.isOpen;
  },
);

// GENERATE LINK
const generatingLink = ref(false);
const shareableLink = ref(null);
const imageBlob = ref(null);
const generatingLinkFor = ref(null);

const getShareLink = async (linkFor = 'twitter') => {
  generatingLink.value = true;
  generatingLinkFor.value = linkFor;
  const file = await makeImageFile(uploadedImageUrl.value);
  await uploadChillFactor(file, linkFor);
};

const makeImageFile = async (dataUrl) => {
  let fetchUrl = await fetch(dataUrl);
  imageBlob.value = await fetchUrl.blob();
  return new File([imageBlob.value], 'chillFactor', { type: 'image/png' });
};

const uploadChillFactor = async (file, linkFor) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const data = await api.uploadImage(formData);
    generatingLink.value = false;
    shareableLink.value = data.url;
    switch (linkFor) {
      case 'twitter':
        makeTwitterUrl();
        generatingLinkFor.value = null;
        break;
      case 'telegram':
        makeTelegramUrl();
        generatingLinkFor.value = null;
        break;
      case 'reddit':
        makeRedditUrl();
        generatingLinkFor.value = null;
        break;
    }
  } catch (e) {
    console.error(e);
  }
};

const twitterDescription = computed(() => {
  return `I just checked my Avalanche Chill Factor score on FrostyMetrics and am ${props.tier.toUpperCase()} with a score of ${props.score.toFixed(
    0,
  )}. Are you chiller than me?

To check your Chill Factor visit Frosty Metrics: https://frostymetrics.com/
`;
});

const makeTwitterUrl = () => {
  if (shareableLink.value.length) {
    const twitterPostIntentUrl = new URL('https://twitter.com/intent/tweet?');
    twitterPostIntentUrl.searchParams.append('url', shareableLink.value);
    twitterPostIntentUrl.searchParams.append('text', twitterDescription.value);
    window.open(
      twitterPostIntentUrl.href,
      'Share Chill Factor',
      'width=700,height=650',
    );
  }
};

const makeTelegramUrl = () => {
  if (shareableLink.value.length) {
    const telegramShareUrl = new URL('https://t.me/share/url?');
    telegramShareUrl.searchParams.append('url', shareableLink.value);
    window.open(
      telegramShareUrl.href,
      'Share Chill Factor',
      'width=700,height=650',
    );
  }
};

const makeRedditUrl = () => {
  if (shareableLink.value) {
    const redditShareUrl = new URL('https://www.reddit.com/submit?');
    redditShareUrl.searchParams.append('url', shareableLink.value);
    window.open(
      redditShareUrl.href,
      'Share Chill Factor',
      'width=700,height=650',
    );
  }
};

const copyImageData = async () => {
  try {
    let fetchUrl = await fetch(props.dataUrl);
    imageBlob.value = await fetchUrl.blob();
    await window.navigator.clipboard.write([
      new ClipboardItem({
        'image/png': imageBlob.value,
      }),
    ]);
    showCopyMessage();
  } catch (e) {
    console.error(e);
  }
};

const showCopyMessage = () => {
  let elem = document.getElementById('copy-message');
  elem.classList.remove('hidden');
  setTimeout(function () {
    let elem = document.getElementById('copy-message');
    elem.classList.add('hidden');
  }, 3000);
};

const applyDimensions = (media) => {
  if (media.matches) {
    let node = document.getElementById('share-image-wrapper');
    const snowfall = document.getElementById('captured-snowfall');
    const sharedImage = document.getElementById('shared-image');

    if (node) {
      if (imageDimensions.value.height < 400) {
        node.setAttribute(
          'style',
          `height: ${imageDimensions.value.height}px; width: ${imageDimensions.value.width}px`,
        );
        sharedImage.setAttribute('style', `width: 100%; height: inherit;`);
      } else {
        node.setAttribute('style', `height: calc(100vh - 250px)`);
        sharedImage.setAttribute(
          'style',
          `width: ${imageDimensions.value.width}px; height: inherit;`,
        );
      }
      snowfall.setAttribute('style', `width: 75%`);
    }
  }
};

onMounted(() => {
  nextTick(() => {
    let media = window.matchMedia('(max-width: 450px)');
    applyDimensions(media);
  });
});
</script>

<style scoped>
.snowfall {
  background-image: url('../images/snowfall-transparent-backgroun-unscreen.gif');
  background-repeat: repeat-xy;
}

.connect-wallet-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  opacity: 0.8;
  background: rgba(19, 26, 48, 0.72);
  backdrop-filter: blur(4px);
}

.connect-wallet {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: fit-content;
  max-width: 600px;
  /* min-width: 550px; */
  border-radius: 16px;
  background-color: #02060b;
}

@media (max-width: 450px) {
  .connect-wallet {
    height: 100vh; /* Set height to 100% of viewport height */
    min-width: 550px;
    max-height: 100vh;
    max-width: 600px;
    padding: 20px; /* Adjust padding for smaller screens */
    box-sizing: border-box; /* Include padding in width calculation */
  }
}
</style>
