<template>
  <a
    href="https://bit.ly/Coredownload"
    target="_blank"
    class="rounded border border-[#5268B8] w-fit h-10 "
  >
    <button
      id="navAction"
      class="flex font-semibold items-center lg:mt-0 lg:mx-0 px-4 rounded h-full justify-center"
      >
      <span
        class="flex items-center space-x-2 text-sm font-normal text-[#8B9FEA]"
      >
        CORE
        <img
          src="../images/puzzle_piece.svg"
          class="ml-1.5 w-4 flex-shrink-0"
        />
      </span>
    </button>
  </a>
</template>
