<template>
  <Table :config="config" :data="coinKeyMetricsTableData"></Table>
</template>
<script setup>
import {
  percentage,
  formatNumber,
  decimals,
  formatPrice,
  roundSmallPercent,
  rounded,
  titleize,
} from '../../composeables/filters.js';
import { ref, watch, onMounted, computed } from 'vue';
import Table from '../../Shared/Table.vue';

const props = defineProps({
  token: Object,
});

const config = ref({
  cols: [
    { type: 'text', name: 'Metrics', id: 'metrics', textLeft: 'left' },
    { type: 'key_metrics', name: 'Value', id: 'value' },
  ],
  paddingLeftRight: 'px-5',
});

const totalSupplyValue = computed(() => {
  return props.token.max_supply
    ? parseFloat(props.token.max_supply)
    : parseFloat(props.token.total_supply);
});

const coinKeyMetricsTableData = computed(() => {
  return [
    {
      metrics: 'Circulating Market Cap',
      value:
        parseFloat(props.token.circulating_supply) *
        parseFloat(props.token.price),
      type: 'dollar',
    },
    {
      metrics: 'Fully Diluted Market Cap',
      value:
        parseFloat(props.token.price) *
        (parseFloat(props.token.max_supply) ||
          parseFloat(props.token.total_supply)),
      type: 'dollar',
    },
    {
      metrics: '% of Supply in Circulation',
      value:
        parseFloat(props.token.circulating_supply) / totalSupplyValue.value,
      type: 'percent',
    },
    {
      metrics: 'Trading Volume',
      value: parseFloat(props.token.trading_volume),
      type: 'dollar',
    },
    {
      metrics: '30d Average Trading Volume',
      value: parseFloat(props.token.trading_volume_30_day_avg),
      type: 'dollar',
    },
    {
      metrics: 'Relative Trading Volume',
      value: parseFloat(props.token.relative_trade_volume),
      type: 'percent',
    },
  ];
});
</script>
