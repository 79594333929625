<template>
  <section
    class="mt-6 w-full gap-4 space-y-4 md:grid md:grid-cols-2 md:space-y-0 lg:grid-cols-3 xl:mt-0"
  >
    <!-- Social Activity -->
    <BaseCard>
      <template #header>Social Activity</template>
      <template #controls>
        <BaseRadio
          v-model="selectedSocialFilter"
          multiple
          button-width-class="w-32"
          :options="filterList"
          placeholder="Select Type"
          :control-length="timeframes?.length"
        />
      </template>
      <div class="col-span-6 flex flex-shrink flex-grow flex-col rounded">
        <div class="flex-1 pt-4 pb-0">
          <span
            class="mt-auto flex h-[23rem] w-full items-center justify-center"
            v-if="socailStatChartLoader"
          >
            <IconLoading class="h-10 w-10" />
          </span>
          <span v-else>
            <MultiLineChart
              :series="chartSeries"
              :config="socialChartConfig"
              :key="selectedSocialFilter"
            />
          </span>
        </div>
      </div>
    </BaseCard>
    <!-- Development -->
    <BaseCard>
      <template #header>Development Activity</template>
      <template #controls>
        <BaseRadio
          v-model="selectedDevelopmentFilter"
          multiple
          button-width-class="w-32"
          :options="filterList"
          placeholder="Select Type"
          :control-length="timeframes?.length"
        />
      </template>
      <div
        class="from-bgBlueColor col-span-6 flex flex-shrink flex-grow flex-col rounded"
      >
        <div class="flex-1 px-3 pt-4 pb-0 shadow">
          <span
            class="mt-auto flex h-[23rem] w-full items-center justify-center"
            v-if="developmentStatChartLoader"
          >
            <IconLoading class="h-10 w-10" />
          </span>
          <span v-else>
            <MultiLineChart
              :series="developmentChartSeries"
              :config="developmentChartConfig"
              :key="selectedDevelopmentFilter"
            />
          </span>
        </div>
      </div>
    </BaseCard>
    <!-- TVL -->
    <BaseCard class="h-full">
      <template #header>TVL
        <Tooltip text="TVL data provided by DeFiLlama" class="ml-1">
          <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
        </Tooltip>
      </template>
      <template #controls>
        <BaseRadio
          v-model="selectedTvlFilter"
          multiple
          button-width-class="w-32"
          :options="filterList"
          placeholder="Select Type"
          :control-length="timeframes?.length"
        />
      </template>
      <div class="col-span-6 flex flex-shrink flex-grow flex-col rounded">
        <div class="flex-1 px-3 pt-4 pb-0 shadow lg:pt-8">
          <span
            class="mt-auto flex h-[23rem] w-full items-center justify-center"
            v-if="tvlChartLoader"
          >
            <IconLoading class="h-10 w-10" />
          </span>
          <span v-else>
            <IndividualPageLineChart
              :series="tvlFilteredChartDataTest"
              :key="`area_graph_dashboard_${selectedTvlFilter}`"
              type="area"
              :config="chartConfig"
              :complete-data="true"
            />
          </span>
        </div>
      </div>
    </BaseCard>
  </section>
</template>
<script setup>
import { ref, onMounted, watch, reactive, computed } from 'vue';
import MultiLineChart from '../../Widgets/MultiLineChart.vue';
import IndividualPageLineChart from '../../Widgets/LineChart.vue';
import Filter from '../../Shared/Filter.vue';
import moment from 'moment';
import IconLoading from '../../icons/IconLoading.vue';
import {
  filterToDateTime,
  localDateEqualToUtc,
} from '../../composeables/filters';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../Shared/Tooltip.vue';
import GraphCard from '../../Shared/GraphCard.vue';
import _ from 'lodash';
import { DefiApi } from '../Defis/DefiApi';
const apiDeFi = new DefiApi();
import { DashboardApi } from '../Dashboard/DashboardApi';
import BaseCard from '../../Shared/BaseCard.vue';
import BaseRadio from '../../Shared/BaseRadio.vue';
const api = new DashboardApi();

onMounted(() => {
  Promise.all([
    fetchTvlChartData(),
    fetchSocialStatChartData(),
    fetchDevelopmentStatChartData(),
  ]);
});
const timeframes = ref([]);
// FILTER
const filterList = ref(['7D', '30D', '90D']);

// SOCIAL ACTIVITY
const selectedSocialFilter = ref('7D');
const socialChartConfig = computed(() => {
  return {
    yAxis: [
      {
        // Twitter
        labels: {
          style: {
            color: '#3b82f6',
          },
        },
        title: {
          text: '',
        },
        opposite: true,
        gridLineColor: '#262626',
      },
      {
        // Youtube
        gridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#10b981',
          },
        },
        gridLineColor: '#262626',
      },
      {
        // 4Chan
        gridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#f59e0b',
          },
        },
        opposite: true,
        gridLineColor: '#262626',
      },
    ],
    useUTC: true,
    // tickInterval: selectedSocialFilter.value == '7D' ? 24 * 3600 * 1000 : '',
    height: 420,
  };
});
const socailStatChartLoader = ref(true);
const socailStatChartData = ref({});
async function fetchSocialStatChartData() {
  let result = await api.fetchSocialStatChartData();
  let today = moment().add(1, 'days').format('YYYY-MM-DD');
  socailStatChartData.value = result.map((x) => {
    if (
      x &&
      x.datetime &&
      moment(today).isAfter(moment(x.datetime).format('YYYY-MM-DD'))
    )
      return x;
  });
  socailStatChartLoader.value = false;
}
const chartSeries = computed(() => {
  let tmpData = { twitter: [], youtube: [], fourChan: [] };
  socailStatChartData.value
    ?.filter((x) => x?.datetime >= filterToDateTime(selectedSocialFilter.value))
    .forEach((x) => {
      tmpData.twitter.push([
        moment(x.datetime).unix() * 1000,
        parseFloat(x?.tweet_volume || 0),
      ]);
      tmpData.youtube.push([
        moment(x.datetime).unix() * 1000,
        parseFloat(x?.youtube_view_count || 0),
      ]);
      tmpData.fourChan.push([
        moment(x.datetime).unix() * 1000,
        parseFloat(x?.fourchan_post_count || 0),
      ]);
    });
  return [
    {
      name: 'Twitter',
      type: 'line',
      yAxis: 1,
      data: tmpData.twitter,
      tooltip: {
        valueSuffix: '',
      },
      marker: {
        enabled: false,
      },
      color: '#0091FF',
      animation: false,
    },
    {
      name: 'Youtube',
      type: 'line',
      data: tmpData.youtube,
      tooltip: {
        valueSuffix: '',
      },
      marker: {
        enabled: false,
      },
      color: '#E6206F',
      animation: false,
    },
    {
      name: '4chan',
      type: 'line',
      yAxis: 2,
      data: tmpData.fourChan,
      marker: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: '',
      },
      color: '#CCAB00',
      animation: false,
    },
  ];
});

// DEVELOPMENT ACTIVITY
const selectedDevelopmentFilter = ref('7D');
const developmentStatChartData = ref({});
const developmentStatChartLoader = ref(true);
const developmentChartConfig = computed(() => {
  return {
    yAxis: [
      {
        // Active Developers
        labels: {
          style: {
            color: '#3b82f6',
          },
        },
        title: {
          text: '',
        },
        opposite: true,
        gridLineColor: '#262626',
      },
      {
        // Commits
        gridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#10b981',
          },
        },
        gridLineColor: '#262626',
      },
    ],
    useUTC: localDateEqualToUtc ? true : false,
    tickInterval:
      selectedDevelopmentFilter.value == '7D' ? 24 * 3600 * 1000 : '',
    height: 420,
  };
});

async function fetchDevelopmentStatChartData() {
  developmentStatChartData.value = await api.fetchDevelopmentStatChartData();
  developmentStatChartLoader.value = false;
}
const developmentChartSeries = computed(() => {
  let activeDevelopers = developmentStatChartData.value?.developers?.filter(
    (x) => x?.date >= filterToDateTime(selectedDevelopmentFilter.value),
  );
  let commitsCount = developmentStatChartData.value?.commits?.filter(
    (x) => x?.date >= filterToDateTime(selectedDevelopmentFilter.value),
  );
  let tmpData = { developerActivity: [], commitsCount: [] };
  activeDevelopers?.forEach((x) => {
    tmpData.developerActivity.push([
      moment(x.date).unix() * 1000,
      parseFloat(x?.author_count_30d || 0),
    ]);
  });

  commitsCount.forEach((x) => {
    tmpData.commitsCount.push([
      moment(x.date).unix() * 1000,
      parseFloat(x?.commit_count_30d || 0),
    ]);
  });
  return [
    {
      name: 'Active Developers',
      type: 'line',
      yAxis: 1,
      data: tmpData.developerActivity,
      tooltip: {
        valueSuffix: '',
      },
      marker: {
        enabled: false,
      },
      color: '#5DF2E1',
      animation: false,
    },
    {
      name: 'Commits Count',
      type: 'line',
      data: tmpData.commitsCount,
      tooltip: {
        valueSuffix: '',
      },
      marker: {
        enabled: false,
      },
      color: '#FF85AB',
      animation: false,
    },
  ];
});

// TVL
const tvlChartLoader = ref(true);
const tvlChartData = ref({});
async function fetchTvlChartData() {
  tvlChartData.value = await apiDeFi.fetchTvlChartData();
  tvlChartLoader.value = false;
}

const selectedTvlFilter = ref('7D');
const chartConfig = computed(() => {
  return {
    useUTC: localDateEqualToUtc ? true : false,
    name: 'Total Value Locked (USD)',
    type: 'area',
    opacity: 0.3,
    height: 350,
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        fillOpacity: 0.8,
      },
      area: {
        stacking: 'normal',
        lineColor: '#666666',
        lineWidth: 1,
        marker: {
          enabled: false,
        },
      },
    },
    colors: [
      '#3fd3f0',
      '#4871F8',
      '#A464E4',
      '#F780D0',
      '#FFa366',
      '#3a68ff',
      '#41da5b',
      '#5146b8',
      '#1ffce3',
      '#913831',
    ],
  };
});
const tvlFilteredChartDataTest = computed(() => {
  let data = [];
  if (Array.isArray(tvlChartData.value) && tvlChartData.value.length > 0) {
    tvlChartData.value?.forEach((tcd) => {
      let tmpdata = [];
      tcd.data
        ?.filter(
          (x) =>
            moment(parseInt(x.date) * 1000).format() >=
            filterToDateTime(selectedTvlFilter.value),
        )
        .forEach((x) => {
          tmpdata.push([
            parseInt(x.date) * 1000,
            parseFloat(x.totalLiquidityUSD),
          ]);
        });
      data.push({ name: tcd.name, data: tmpdata });
    });
  } else {
    console.log(tvlChartData.value);
  }
  return data;
});
</script>
