<template>
  <div class="mx-auto flex flex-wrap py-2">
    <div class="w-full flex-col space-y-4 py-2">
      <div class="mr-3 flex items-center">
        <h2 class="text-lg font-bold tracking-tight text-white">
          Market Places
        </h2>
        <div class="space-x-4 px-4 text-xs font-medium text-neutral-800">
          <span
            v-for="filter in filterTypes"
            :key="filter"
            :class="` ${
              filter == selectedFilter
                ? 'rounded bg-selectedFilterBgColor px-2 py-1 font-normal text-blue-400'
                : 'text-textColor'
            } `"
          >
            <button @click="selectedFilter = filter">{{ filter }}</button>
          </span>
        </div>
      </div>

      <div class="w-full sm:space-y-8 lg:flex lg:space-x-4 lg:space-y-0">
        <!-- Line Chart -->
        <section
          class="h-full w-full max-w-full space-y-2 space-x-0 rounded sm:max-w-full md:space-y-7 lg:flex lg:max-w-md lg:flex-col lg:space-x-0 xl:max-w-md"
          id="lineGraphContainer"
        >
          <StatsWidget
            :stat-item="statItemVolume"
            :use-utc="false"
            :time-format="timeFormat"
            :show-loading="marketPlacesOverviewChartLoading"
            :dollar-sign="true"
          />
          <StatsWidget
            :stat-item="statItemTransactions"
            :use-utc="false"
            :time-format="timeFormat"
            :show-loading="marketPlacesOverviewChartLoading"
          />
        </section>

        <!-- Table  -->
        <BaseCard class="mt-3 w-full rounded-lg lg:mt-0">
          <div class="relative h-56 overflow-x-auto">
            <span
              id="box-shadow"
              class="mt-auto flex h-full w-full items-center justify-center"
              v-if="marketPlacesLoading"
            >
              <IconLoading class="h-10 w-10" />
            </span>
            <span v-else-if="marketPalaceData?.length > 0">
              <Table
                :config="marketPalaceConfig"
                :data="_.orderBy(marketPalaceData, 'volume_avax', 'desc')"
                class="py-2"
              ></Table>
            </span>
            <span
              v-else-if="!marketPlacesLoading && marketPalaceData"
              class="mt-auto flex h-full w-full justify-center"
            >
              <NoDataFound />
            </span>
          </div>
        </BaseCard>

      </div>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash';
import moment from 'moment';
import IconLoading from '../../icons/IconLoading.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import StatsWidget from '../../Widgets/Stats.vue';
import Table from '../../Shared/Table.vue';
import { ref, reactive, computed, onMounted, watch, inject } from 'vue';
import { NftApi } from './NftApi';
import BaseCard from '../../Shared/BaseCard.vue';
const api = new NftApi();
const timeFormat = ref(null);

const selectedFilter = ref('24H');
const filterTypes = reactive(['24H', '7D', '30D']);
const marketPlacesOverviewChart = ref([]);
const marketPlacesOverviewChartLoading = ref(true);
const marketPlaces = ref([]);
const marketPlacesLoading = ref(true);
const showMinutes = ref(false);

onMounted(() => {
  Promise.all([fetchMarketPlaces(), fetchMarketPlacesOverviewGraph()]);
});

watch(selectedFilter, async () => {
  marketPlacesLoading.value = true;
  marketPlacesOverviewChartLoading.value = true;
  await fetchMarketPlaces();
  await fetchMarketPlacesOverviewGraph();
});

async function fetchMarketPlacesOverviewGraph() {
  let selectedTimeFrame =
    selectedFilter.value == '24H'
      ? '1day'
      : selectedFilter.value == '7D'
      ? '7day'
      : '30day';
  marketPlacesOverviewChart.value = await api.fetchMarketPlacesOverviewGraph({
    order_by: selectedTimeFrame,
  });
  marketPlacesOverviewChartLoading.value = false;
}

const statItemVolume = computed(() => {
  let result = {
    id: 1,
    title: 'Total Volume',
    selectedFilter: selectedFilter.value,
    total: 0,
    from: 0,
    growth: 0,
    chart: [],
    usd: true,
  };
  if (marketPlacesOverviewChart.value?.length > 0) {
    assembleData(result, 'totalVolume');
  }
  return result;
});

function assembleData(result, title) {
  let firstSum = 0;
  let secondSum = 0;

  let firstHalf = [];
  let secondHalf = [];

  let filterData = [];
  let requiredColumn =
    title == 'totalVolume' ? 'secondary_sales_volume' : 'transaction_count';

  if (marketPlacesOverviewChartLoading.value == false) {
    let middleIndex = Math.ceil(marketPlacesOverviewChart.value.length / 2);
    firstHalf = marketPlacesOverviewChart.value.slice().splice(0, middleIndex);
    secondHalf = marketPlacesOverviewChart.value.slice().splice(-middleIndex);

    firstHalf.forEach((data) => (firstSum = firstSum + data[requiredColumn]));
    secondHalf.forEach(
      (data) => (secondSum = secondSum + data[requiredColumn]),
    );

    result.total =
      title == 'totalVolume' ? firstSum * avax.value?.price : firstSum;
    result.from =
      title == 'totalVolume' ? secondSum * avax.value?.price : secondSum;
    result.growth = (result.total - result.from) / result.from;

    if (selectedFilter.value !== '24H') {
      timeFormat.value = null;
      filterData = [];

      firstHalf = firstHalf.sort(function (x, y) {
        return new Date(x['hour']).getTime() - new Date(y['hour']).getTime();
      });

      let grouped = _.groupBy(firstHalf, (rec) =>
        new Date(rec['hour']).toLocaleDateString(),
      );

      Object.keys(grouped).forEach(function (key) {
        filterData.push({
          hour: grouped[key][0]['hour'],
          requiredColumn: grouped[key].map((innerData) => {
            return innerData[requiredColumn];
          }),
        });
      });

      let chartData = [];

      if (filterData) {
        filterData.forEach((data) => {
          if (data.hour) {
            chartData.push([
              moment.utc(data.hour).valueOf(),
              title == 'totalVolume'
                ? sumDayBaseData(data.requiredColumn) * avax.value?.price
                : sumDayBaseData(data.requiredColumn),
            ]);
          }
        });
        result.chart = chartData;
      }
    } else {
      timeFormat.value = 'MMM DD, YYYY HH:mm';
      let chartData = [];
      firstHalf.forEach((data) => {
        if (data.hour) {
          chartData.push([
            moment.utc(data.hour).valueOf(),
            title == 'totalVolume'
              ? data[requiredColumn] * avax.value?.price
              : data[requiredColumn],
          ]);
        }
        result.chart = chartData;
      });
    }
  }

  return result;
}

function sumDayBaseData(data) {
  return data.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);
}

const statItemTransactions = computed(() => {
  let result = {
    id: 1,
    title: 'Total Sales',
    selectedFilter: selectedFilter.value,
    total: 0,
    from: 0,
    growth: 0,
    chart: [],
  };

  if (marketPlacesOverviewChart.value?.length > 0) {
    assembleData(result, 'totalSales');
  }
  return result;
});

async function fetchMarketPlaces() {
  let selectedTimeFrame =
    selectedFilter.value == '24H'
      ? '1day'
      : selectedFilter.value == '7D'
      ? '7day'
      : '30day';
  marketPlaces.value = await api.fetchMarketPlaces({
    order_by: selectedTimeFrame,
  });
  marketPlacesLoading.value = false;
}

const marketPalaceConfig = ref({
  cols: [
    {
      type: 'market_place',
      name: 'Market Place',
      id: 'market_place',
      textLeft: 'left',
    },
    { type: 'dollar', name: 'Volume (USD)', id: 'volume_usd', numbersLimit: 2 },
    { type: 'decimal', name: 'Volume (AVAX)', id: 'volume_avax' },
    { type: 'number', name: 'Active Users', id: 'active_users' },
    {
      type: 'number',
      name: 'Number of Transactions',
      id: 'number_of_transactions',
    },
  ],
  paddingLeftRight: 'px-5',
});

const tokens = inject('universeTokens');
const avax = computed(() => {
  let allTokens = tokens.value || [];
  return allTokens.find((x) => x.ticker == 'AVAX');
});

const marketPalaceData = computed(() => {
  let result = [];
  if (marketPlaces.value?.length > 0) {
    marketPlaces.value?.forEach((data) => {
      result = result.concat({
        market_place: data.router_used,
        volume_usd: data.secondary_sales_volume * (avax.value?.price || 1),
        volume_avax: data.secondary_sales_volume,
        active_users: data.unique_users,
        number_of_transactions: data.num_trxs,
      });
    });
  }

  return result;
});
</script>
