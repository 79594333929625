<template>
  <BaseCard class="flex h-[500px] flex-shrink flex-col">
    <template #header>
      <div class="flex space-x-2">
        <div>L1 Validator Stats</div>
        <Tooltip
          text="A comparison of the number of validators and their staking weights for all L1s."
          :id="1"
        >
          <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
        </Tooltip>
      </div>
    </template>
    <div class="flex h-[28rem] flex-shrink-0 flex-grow flex-col rounded">
      <div
        class="wrap flex-1 overflow-hidden overflow-x-auto overflow-y-auto rounded-lg text-sm shadow"
      >
        <div v-if="loading" class="flex h-full items-center justify-center">
          <IconLoading class="m-auto h-8 w-8" />
        </div>
        <div v-else-if="tableData?.length > 0" class="w-full">
          <Table :config="tableConfig" :data="tableData"></Table>
        </div>
        <span
          v-else-if="!loading"
          class="mt-auto flex h-full w-full justify-center"
        >
          <NoDataFound />
        </span>
      </div>
    </div>
  </BaseCard>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import NoDataFound from '../../../load_templates/NoDataFound.vue';
import Table from '../../../Shared/Table.vue';
import { SubnetApi } from '../SubnetApi.js';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../../Shared/Tooltip.vue';
import IconLoading from '../../../icons/IconLoading.vue';
import BaseCard from '../../../Shared/BaseCard.vue';
import { sortData } from '../../../composeables/filters';

const subnetApi = new SubnetApi();
const loading = ref(false);
const tableData = ref([]);

async function getSubnetValidatorStats() {
  loading.value = true;
  tableData.value = await subnetApi.fetchSubnetValidatorStats();
  tableData.value = sortData(tableData.value, 'validator_count');
  loading.value = false;
}

onMounted(() => {
  getSubnetValidatorStats();
});

const tableConfig = ref({
  cols: [
    { type: 'subnets', name: 'L1', id: 'name', textLeft: 'left' },
    { type: 'number', name: 'Validator Count', id: 'validator_count' },
    {
      type: 'avax_price',
      name: 'Validator Weight',
      id: 'validator_weight',
      hideIcon: true,
      colClass: 'justify-end',
    },
  ],
  paddingLeftRight: 'px-5',
});
</script>
