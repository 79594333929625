<template>
  <RadioGroup
    v-model="radioValue"
    :class="[
      'flex flex-row flex-wrap items-center gap-2 text-textColor',
      'cursor-pointer',
      'text-xs',
      `${controlLength > 4 ? '' : ''}`,
    ]"
  >
    <!-- flex justify-around pt-3 -->
    <RadioGroupLabel v-if="$slots.default"><slot /></RadioGroupLabel>
    <div
      class="relative flex flex-row rounded text-textColor"
      id="belowLabel"
      :style="`${
        controlLength > 4 || labelLength > 14
          ? 'display: flex;width: 100%;justify-content: end;'
          : ''
      }`"
    >
      <div
        :class="[
          'absolute z-0 h-full',
          'transition-all duration-300 ease-out',
          'rounded',
        ]"
        :style="positioningStyles"
      />
      <RadioGroupOption
        v-for="(option, index) of options"
        :key="index"
        ref="optionRefs"
        :value="option"
        :class="[
          'z-10',
          'flex flex-row',
          'select-none font-medium tracking-widest',
          'rounded',
          radioValue == option
            ? 'bg-[#f3f2f629] text-blue-400'
            : 'hover:bg-neutral-800',
          'py-1 px-3',
        ]"
      >
        {{ option }}
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>

<script setup lang="ts">
import { ref, type PropType, computed, onMounted } from 'vue';
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';
const props = defineProps({
  modelValue: {
    type: [String],
    required: true,
  },
  options: {
    type: Array as PropType<string[]>,
    required: true,
  },
  controlLength: { type: Number, default: 0 },
  labelLength: { type: Number, default: 0 },
});
const optionRefs = ref([]);
const optionWidths = ref([]);
const selectedIndex = computed(() =>
  props.options.findIndex((o) => o == radioValue.value),
);
const positioningStyles = computed(() => {
  const leftPixels = optionWidths.value
    .slice(0, selectedIndex.value)
    .reduce((a, b) => a + b, 0);
  const rightPixels = optionWidths.value
    .slice(selectedIndex.value + 1)
    .reduce((a, b) => a + b, 0);
  return `left: ${leftPixels}px;
            right: ${rightPixels}px;`;
});
onMounted(() => {
  optionRefs.value.forEach((option) => {
    // @ts-expect-error: doesn't recognize option.el as HTML Element
    optionWidths.value.push(option.el.clientWidth);
  });
});
const emit = defineEmits(['update:modelValue']);
const radioValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});
</script>
