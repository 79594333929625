<template>
  <div class="h-full w-full">
    <img src="../images/Rain-Animation.gif" v-if="enable == 'true'" />
  </div>
</template>
<script setup>
import { ref } from 'vue';
const enable = ref(
  localStorage.getItem('animationEffect')
    ? localStorage.getItem('animationEffect')
    : true,
);
</script>
