<template>
  <svg
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.79998 2.3999C8.58781 2.3999 8.38432 2.48419 8.23429 2.63422C8.08426 2.78425 7.99998 2.98773 7.99998 3.1999C7.99998 3.41208 8.08426 3.61556 8.23429 3.76559C8.38432 3.91562 8.58781 3.9999 8.79998 3.9999H10.8688L5.83438 9.0343C5.75797 9.1081 5.69703 9.19638 5.6551 9.29398C5.61317 9.39158 5.5911 9.49656 5.59018 9.60278C5.58926 9.70901 5.6095 9.81435 5.64972 9.91267C5.68995 10.011 5.74935 10.1003 5.82446 10.1754C5.89958 10.2505 5.9889 10.3099 6.08722 10.3502C6.18553 10.3904 6.29088 10.4106 6.3971 10.4097C6.50332 10.4088 6.6083 10.3867 6.7059 10.3448C6.80351 10.3029 6.89178 10.2419 6.96558 10.1655L12 5.1311V7.1999C12 7.41208 12.0843 7.61556 12.2343 7.76559C12.3843 7.91562 12.5878 7.9999 12.8 7.9999C13.0122 7.9999 13.2156 7.91562 13.3657 7.76559C13.5157 7.61556 13.6 7.41208 13.6 7.1999V3.1999C13.6 2.98773 13.5157 2.78425 13.3657 2.63422C13.2156 2.48419 13.0122 2.3999 12.8 2.3999H8.79998Z"
      fill="#A3A3A3"
    />
    <path
      d="M4.00002 4C3.57568 4 3.16871 4.16857 2.86865 4.46863C2.5686 4.76869 2.40002 5.17565 2.40002 5.6V12C2.40002 12.4243 2.5686 12.8313 2.86865 13.1314C3.16871 13.4314 3.57568 13.6 4.00002 13.6H10.4C10.8244 13.6 11.2313 13.4314 11.5314 13.1314C11.8315 12.8313 12 12.4243 12 12V9.6C12 9.38783 11.9157 9.18434 11.7657 9.03431C11.6157 8.88429 11.4122 8.8 11.2 8.8C10.9879 8.8 10.7844 8.88429 10.6343 9.03431C10.4843 9.18434 10.4 9.38783 10.4 9.6V12H4.00002V5.6H6.40002C6.6122 5.6 6.81568 5.51571 6.96571 5.36569C7.11574 5.21566 7.20002 5.01217 7.20002 4.8C7.20002 4.58783 7.11574 4.38434 6.96571 4.23431C6.81568 4.08429 6.6122 4 6.40002 4H4.00002Z"
      fill="#A3A3A3"
    />
  </svg>
</template>
