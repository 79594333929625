<template>
  <div class="mx-auto flex flex-wrap">
    <div class="flex w-full flex-shrink flex-grow flex-col">
      <span class="py-2 text-base font-semibold text-gray-100 md:text-lg">
        Trade {{ token.name }}
      </span>
      <BaseCard>
        <div class="relative h-72 overflow-x-auto rounded-lg">
          <Table
            :config="tradeTableConfig"
            :data="tradeTableData"
            class="py-2"
          ></Table>
        </div>
      </BaseCard>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import Table from '../../Shared/Table.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import { TokenApi } from '../Tokens/TokenApi';
import BaseCard from '../../Shared/BaseCard.vue';

import _ from 'lodash';
const api = new TokenApi();

const props = defineProps({
  token: Object,
});
const tradePairsData = ref([]);
onMounted(async () => {
  tradePairsData.value = await api.fetchTradePairs(props.token.ticker);
});

const tradeTableData = computed(() => {
  let pairs = tradePairsData.value;
  pairs = _.orderBy(
    pairs,
    [(data) => data['trading_volume_usd_24_hours']],
    'desc',
  );
  let total_volume = pairs?.reduce(
    (partialSum, a) => partialSum + a.trading_volume_usd_24_hours,
    0,
  );
  return pairs.map((tp) => {
    return {
      exchange: tp.market,
      pair: tp.pair.includes('0X')
        ? `${tp.base_coin}/${tp.target_coin}`
        : tp.pair,
      price: tp.price,
      plus_depth: tp.cost_to_move_up_usd,
      minus_depth: tp.cost_to_move_down_usd,
      trading_volume_usd_24_hours: tp.trading_volume_usd_24_hours,
      volume: tp.trading_volume_usd_24_hours / total_volume,
      trade_link: { name: 'Trade', link: tp.trade_url },
    };
  });
});

const tradeTableConfig = ref({
  cols: [
    { type: 'coin', name: 'Exchanges', id: 'exchange', textLeft: 'left' },
    {
      type: 'text',
      name: 'Pair',
      id: 'pair',
      textLeft: true,
      style: 'w-full max-w-[16rem] truncate uppercase',
    },
    { type: 'dollar', name: 'Price', id: 'price' },
    {
      type: 'dollar',
      name: '24 Volume',
      id: 'trading_volume_usd_24_hours',
      numbersLimit: 2,
    },
    { type: 'percent', name: 'Volume %', id: 'volume', showColorSymbol: false },
    { type: 'link', name: 'Trade (Link)', id: 'trade_link' },
  ],
  paddingLeftRight: 'px-5',
});
</script>
