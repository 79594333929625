<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3458 2.65344C13.3425 2.44844 6.65333 2.44927 3.65417 2.65344C0.406667 2.8751 0.0241667 4.83677 0 10.0001C0.0241667 15.1543 0.403333 17.1243 3.65417 17.3468C6.65417 17.5509 13.3425 17.5518 16.3458 17.3468C19.5933 17.1251 19.9758 15.1634 20 10.0001C19.9758 4.84594 19.5967 2.87594 16.3458 2.65344ZM7.5 13.3334V6.66677L14.1667 9.99427L7.5 13.3334Z"
      fill="currentColor"
    />
    <defs>
      <clipPath id="clip0_122_651">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
