<template>
  <BaseCard>
    <template #header>{{ title }}</template>
    <template #controls>
      <BaseRadio
        v-model="selectedFilter"
        multiple
        button-width-class="w-32"
        :options="filterTypes"
        placeholder="Select Type"
      />
    </template>
    <div
      class="flex flex-shrink flex-col rounded-lg"
      :class="`h-${config.height}`"
    >
      <PieGraphWidget
        :format-type="config.pieFormatType"
        :data-series="series"
        :dollar-sign="config['dollar-sign']"
        v-if="type == 'pie'"
        :config="config"
      />
      <BarGraphWidget
        :data-series="series"
        v-else-if="type == 'bar'"
        :random-id="getRandomId()"
        :config="{ legend: true }"
      />
    </div>
  </BaseCard>
</template>

<script setup>
import _ from 'lodash';
import PieGraphWidget from '../Widgets/PieChart.vue';
import BarGraphWidget from '../Widgets/BarChart.vue';
import { ref, computed, reactive, watch } from 'vue';
import {
  getRandomId,
  coinBaseColors,
  filtersArray,
} from '../composeables/filters.js';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../Shared/Tooltip.vue';
import BaseCard from './BaseCard.vue';
import BaseRadio from '../Shared/BaseRadio.vue';
const props = defineProps({
  dataSeries: Array,
  title: String,
  type: String,
  page: String,
  tooltipText: String,
  config: {
    Type: Object,
    default: {
      showFilters: true,
      height: '72',
      pieFormatType: `percentage`,
      legend: true,
    },
  },
  sortBy: {
    type: Object,
    default: {
      column: 'name',
      order: 'asc',
    },
  },
});
const dataSeries = ref(props.dataSeries);
watch(
  () => props.dataSeries,
  (newValue, oldValue) => {
    dataSeries.value = newValue;
  },
);

const selectedFilter = ref('24H');
const filterTypes = reactive(['24H', '7D', '30D']);

const emit = defineEmits(['filterChangedEmit']);

function filterChanged(filterVal) {
  selectedFilter.value = filterVal;
  emit('filterChangedEmit', {
    filterVal: filterVal,
    title: props.title,
  });
}

watch(
  () => selectedFilter.value,
  (newValue, oldValue) => {
    filterChanged(newValue);
  },
);

const series = computed(() => {
  let series = [];

  if (props.page == 'nft' && dataSeries.value?.length > 0) {
    dataSeries.value?.forEach((data) => {
      if (data.collection_name?.length > 0) {
        if (props.title == 'Transactions') {
          series.push({
            day: data.day,
            y: data?.num_trxs_change,
            collection: data?.collection,
            name: data?.collection_name,
            rawValue: data?.num_trxs_change?.toString() || '',
          });
        } else if (props.title == 'Unique Wallets') {
          series.push({
            day: data.day,
            y: data?.unique_users_change,
            collection: data?.collection,
            name: data?.collection_name,
            rawValue: data?.unique_users_change?.toString(),
          });
        } else if (props.title == 'Market Cap' || props.title == 'Volume ($)') {
          series.push({
            day: data.day,
            y: data?.volume_change,
            collection: data?.collection,
            name: data?.collection_name,
            rawValue: '$ ' + data.volume,
          });
        } else if (props.title == 'Market Cap') {
          series.push({
            day: data.day,
            y: data?.volume_change,
            collection: data?.collection,
            name: data?.collection_name,
            rawValue: '$ ' + data.volume_change,
          });
        }
      }
    });
  } else if (props.page == 'defi' || props.page == 'dashboard') {
    series = dataSeries.value;
  } else if (props.page == 'gaming') {
    series = dataSeries.value;
  } else {
    let day =
      selectedFilter.value == '1D'
        ? 'one'
        : selectedFilter.value == '7D'
        ? 'seven'
        : 'thirty';
    let type =
      props.title == 'Total Transactions'
        ? `share_of_num_transactions_${day}_day`
        : props.title == 'Total Wallets'
        ? `unique_wallets_${day}_day`
        : `share_of_volume_usd_${day}_day`;
    dataSeries.value?.forEach((e) => {
      if (props.type == 'bar') {
        series.push({
          name: e.game,
          data: [parseFloat(e[type])],
          value: parseFloat(e[type]),
          color: coinBaseColors(e.game),
        });
      } else {
        series.push({
          name: e.game,
          y: parseFloat(e[type]),
          value: parseFloat(e[type]),
          color: coinBaseColors(e.game),
        });
      }
    });
  }

  let series1 = series.filter((x) => x.name != 'Others');
  let otherSeries = series.find((x) => x.name == 'Others') || [];
  series1 = _.orderBy(series1, props.sortBy.column, props.sortBy.order);

  return series1.concat(otherSeries);
});
</script>
