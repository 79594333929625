<template>
  <div
    class="box-shadow relative flex flex-col overflow-hidden rounded-lg border border-grid p-1 px-4 text-base font-normal text-textColor"
    style="background: rgba(82, 104, 184, 0.04)"
  >
    <div v-if="loading" class="absolute inset-0 z-10">
      <div
        class="flex h-full flex-col items-center justify-center backdrop-blur-md"
      >
        <!-- <BaseLoader /> -->
      </div>
    </div>
    <div
      v-if="headerVisible"
      :class="`w-full flex-row items-center justify-between py-3 ${
        controlLength > 4 || labelLength > 14 ? 'sm:flex' : 'flex'
      }`"
    >
      <div
        class="flex w-full items-center overflow-hidden text-ellipsis whitespace-nowrap 2xl:flex-1"
      >
        <slot name="header" />
        <Tooltip :text="tooltipText" class="ml-1" v-if="tooltipText">
          <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
        </Tooltip>
      </div>
      <div>
        <slot name="controls" />
      </div>
    </div>
    <div class="flex w-full flex-1 flex-col">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { computed, useSlots } from 'vue';
import Tooltip from './Tooltip.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
defineProps({
  loading: { type: Boolean, default: false },
  header: { type: Boolean, default: false },
  classes: { type: String, default: '' },
  tooltipText: { type: String, default: '' },
  controlLength: { type: Number, default: 0 },
  labelLength: { type: Number, default: 0 },
});

const $slots = useSlots();
const headerVisible = computed(() => {
  return $slots.header != undefined;
});
</script>
