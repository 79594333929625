<template>
  <Teleport to="body">
    <transition name="fade" appear>
      <div class="h-full w-full" v-if="showModal" @click="close()">
        <div class="connect-wallet-overlay">
          <span v-if="tier?.toLowerCase() == 'warm'">
            <div v-for="index in 5" :key="index">
              <div class="grid h-[20vh] w-full grid-cols-3 gap-0">
                <RainAnimation />
                <RainAnimation />
                <RainAnimation />
              </div>
            </div>
          </span>
          <span v-else-if="tier?.toLowerCase() != 'warm'">
            <SnowAnimation />
          </span>
        </div>
      </div>
    </transition>
    <transition name="slide" appear>
      <div
        class="connect-wallet box-shadow relative flex flex-col items-center rounded-lg border border-grid bg-transparent p-1 px-4 text-base font-normal backdrop-blur"
        id="modal"
        v-if="showModal"
      >
        <div class="w-full">
          <div class="absolute -left-2.5 -top-2.5 z-20">
            <img src="../images/footer_snow_left.svg" class="flex-shrink-0" />
          </div>
          <div
            class="flex w-full cursor-pointer justify-end px-2 py-4 sm:hidden"
            @click="close(false)"
          >
            <XMarkIcon class="block h-4 w-4 cursor-pointer text-[#f3f2f6e0]" />
          </div>
          <div
            class="mt-[50%] flex items-center justify-between pb-4 text-2xl font-semibold sm:mt-0"
          >
            <span>Apply Address</span>
            <div
              class="hidden cursor-pointer rounded border border-[#FFFFFF3D] p-1 sm:block"
              @click="close(false)"
            >
              <XMarkIcon class="h-4 w-4 cursor-pointer text-[#f3f2f6e0]" />
            </div>
          </div>
          <form class="rounded shadow-md" @submit.prevent="handleSubmit">
            <div class="mb-4">
              <label class="mb-2 block text-sm font-medium" for="username">
                Search an Avalanche address
              </label>
              <div class="sm:flex sm:space-x-2">
                <div
                  @focus="focusInput()"
                  class="focus:shadow-outline flex w-full appearance-none rounded border border-gray-700 px-3 leading-tight text-gray-300 shadow focus:outline-none"
                >
                  <MagnifyingGlassIcon class="w-5 text-gray-500" />
                  <input
                    class="h-8 w-full border-none bg-transparent pl-2 text-xs placeholder-indigo-400 placeholder-opacity-40 outline-none focus:border-none"
                    placeholder="ie. 0x2c013e0c5B5f06883E9fb375316cddbc91253520"
                    v-model="address"
                    @keyup.enter="handleSubmit"
                    @focus="searchFocused = true"
                    data-id="search-input"
                  />
                </div>
                <button
                  @click="handleSubmit"
                  class="mt-2 w-full rounded bg-gradient-to-br from-[#31E0FF] to-[#45A8FF] px-4 py-2 text-sm text-black sm:mt-0 sm:w-1/5 sm:py-0"
                >
                  APPLY
                </button>
              </div>
            </div>
          </form>
          <div class="space-y-4" v-if="false">
            <div>
              or connect a wallet
              <span class="text-indigo-400/90">(coming soon)</span>
            </div>
            <div class="space-y-3 text-gray-400/80">
              <div
                class="flex items-center space-x-3 rounded bg-gray-900/50 px-6 py-4"
              >
                <img
                  src="../images/metamask.svg"
                  class="flex-shrink-0 rounded-full"
                />
                <span>MetaMask</span>
              </div>
              <div
                class="flex items-center space-x-3 rounded bg-gray-900/50 px-6 py-4"
              >
                <img
                  src="../images/okx_wallet.svg"
                  class="flex-shrink-0 rounded-full"
                />
                <span>OKX Wallet</span>
              </div>
              <div
                class="flex items-center space-x-3 rounded bg-gray-900/50 px-6 py-4"
              >
                <img
                  src="../images/wallet_connect.svg"
                  class="flex-shrink-0 rounded-full"
                />
                <span>Wallet Connect</span>
              </div>
              <div
                class="flex items-center space-x-3 rounded bg-gray-900/50 px-6 py-4"
              >
                <img
                  src="../images/coin_base.svg"
                  class="flex-shrink-0 rounded-full"
                />
                <span>Coinbase Wallet</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
<script setup>
import { ref } from 'vue';
import _ from 'lodash';
const emit = defineEmits(['close-modal', 'enter-address']);
import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import SnowAnimation from '../Shared/SnowAnimation.vue';
import RainAnimation from '../Shared/RainAnimation.vue';
const props = defineProps({
  showModal: Boolean,
  tier: {
    type: String,
    default: () => '',
  },
});

function close() {
  emit('close-modal', { showModal: false });
}

const address = ref('');
const handleSubmit = () => {
  localStorage.setItem(
    'my_address',
    JSON.stringify(address.value.replaceAll(/\s/g, '')),
  );
  emit('entered-address', { address: address.value.replaceAll(/\s/g, '') });
  close(false);
};
const searchFocused = ref(false);
function focusInput() {
  searchFocused.value = true;
  setTimeout(
    () => document.querySelector('[data-id="search-input"]').focus(),
    10,
  );
  window.addEventListener('keydown', keydownEventsAfterOpen);
}
</script>
<style scoped>
.snowfall {
  background-image: url('../images/snowfall-transparent-backgroun-unscreen.gif');
  background-repeat: repeat-xy;
  opacity: 0.7;
}

.connect-wallet-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  background-color: rgba(15, 20, 38, 0.7);
}

.connect-wallet {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 100%;
  max-width: 473px;
  border-radius: 16px;
  height: 200px;
  max-height: 250px;
  background-color: #02060b;
  padding: 35px;
}

@media (max-width: 450px) {
  .connect-wallet {
    height: 100vh; /* Set height to 100% of viewport height */
    width: 100%;
    max-height: 100vh;
    max-width: none;
    padding: 20px; /* Adjust padding for smaller screens */
    box-sizing: border-box; /* Include padding in width calculation */
    background-color: #f3f2f60a;
  }
}
</style>
