<template>
  <!-- <div class="px-4 py-3">
    <span class="flex space-x-2 font-normal">
      <h2 class="text-lg tracking-tight text-textColor">Price Return</h2>
      <Tooltip text="Notable Listings" id="notable_listings">
        <InformationCircleIcon class="h-5 w-5 text-infoIconColor" />
      </Tooltip>
    </span> -->
    <div class="text-large relative h-80 overflow-x-auto">
      <Table
        :config="priceReturnTableConfig"
        :data="priceReturnTableData"
      ></Table>
    </div>
  <!-- </div> -->
</template>
<script setup>
import { ref, computed } from 'vue';
import Table from '../../Shared/Table.vue';
import { TokenApi } from './TokenApi';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../../Shared/Tooltip.vue';
const api = new TokenApi();

const props = defineProps({
  token: Object,
  avaxToken: Object,
});

const priceReturnTableConfig = ref({
  cols: [
    { type: 'text', name: 'Time Frame', id: 'timeframe', textLeft: 'left' },
    { type: 'percent', name: 'VS. USD', id: 'usd' },
    { type: 'percent', name: 'VS. AVAX', id: 'avax' },
  ],
  paddingLeftRight: 'px-5',
});
const priceReturnTableData = computed(() => {
  let priceReturns = [
    {
      timeframe: '1 Hour',
      usd: parseFloat(props.token['price_return_1_hour']),
      avax: api.calculateChangeWithOtherCoin(
        props.token['price_return_1_hour'],
        props.avaxToken['price_return_1_hour'],
      ),
    },
    {
      timeframe: '24 Hours',
      usd: parseFloat(props.token['price_return_24_hours']),
      avax: api.calculateChangeWithOtherCoin(
        props.token['price_return_24_hours'],
        props.avaxToken['price_return_24_hours'],
      ),
    },
    {
      timeframe: '7 Days',
      usd: parseFloat(props.token['price_return_7_days']),
      avax: api.calculateChangeWithOtherCoin(
        props.token['price_return_7_days'],
        props.avaxToken['price_return_7_days'],
      ),
    },
    {
      timeframe: '30 Days',
      usd: parseFloat(props.token['price_return_30_days']),
      avax: api.calculateChangeWithOtherCoin(
        props.token['price_return_30_days'],
        props.avaxToken['price_return_30_days'],
      ),
    },
  ];
  if (props.token.one_year_ago_price > 0) {
    priceReturns.push({
      timeframe: '1 Year',
      usd: api.calculateChange(
        props.token.price,
        props.token.one_year_ago_price,
      ),
      avax: api.calculateChangeWithOtherCoin(
        api.calculateChange(props.token.price, props.token.one_year_ago_price),
        api.calculateChange(
          props.avaxToken.price,
          props.avaxToken.one_year_ago_price,
        ),
      ),
    });
  }
  return priceReturns;
});
</script>
