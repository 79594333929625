<template>
  <div
    class="h-[25rem] w-full space-y-3 sm:flex sm:h-auto sm:space-x-3 sm:space-y-0"
  >
    <div class="flex w-full sm:w-1/2">
      <div class="w-full rounded-lg bg-grid sm:h-[21.5rem]">
        <div class="w-full animate-pulse items-center p-1">
          <div
            class="bg-gray-700/50/50 mb-2 w-full rounded-lg sm:h-[21.5rem]"
          ></div>
        </div>
      </div>
    </div>
    <div class="flex w-full sm:w-1/2">
      <div class="flex w-full flex-col space-y-4">
        <div class="flex h-[7.3rem] flex-col space-y-3 rounded-lg bg-grid p-4">
          <div class="flex w-full animate-pulse justify-between text-sm">
            <span class="h-3 w-5/12 bg-gray-700/50"></span>
            <span class="flex h-3 w-5/12 space-x-1 bg-gray-700/50"> </span>
          </div>
          <div class="flex w-full animate-pulse justify-between text-sm">
            <span class="h-3 w-5/12 bg-gray-700/50"></span>
            <span class="flex h-3 w-5/12 space-x-1 bg-gray-700/50"> </span>
          </div>
          <div class="flex w-full animate-pulse justify-between text-sm">
            <span class="h-3 w-5/12 bg-gray-700/50"></span>
            <span class="flex h-3 w-5/12 space-x-1 bg-gray-700/50"> </span>
          </div>
        </div>
        <div
          class="flex h-[13.3rem] flex-col space-y-3 overflow-auto rounded-lg bg-grid p-4"
        >
          <div class="flex w-full animate-pulse justify-between text-sm">
            <span class="h-3 w-5/12 bg-gray-700/50"></span>
            <span class="flex h-3 w-5/12 space-x-1 bg-gray-700/50"> </span>
          </div>
          <div class="flex w-full animate-pulse justify-between text-sm">
            <span class="h-3 w-5/12 bg-gray-700/50"></span>
            <span class="flex h-3 w-5/12 space-x-1 bg-gray-700/50"> </span>
          </div>
          <div class="flex w-full animate-pulse justify-between text-sm">
            <span class="h-3 w-5/12 bg-gray-700/50"></span>
            <span class="flex h-3 w-5/12 space-x-1 bg-gray-700/50"> </span>
          </div>
          <div class="flex w-full animate-pulse justify-between text-sm">
            <span class="h-3 w-5/12 bg-gray-700/50"></span>
            <span class="flex h-3 w-5/12 space-x-1 bg-gray-700/50"> </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex w-full justify-end"></div>
</template>
