<template>
  <section
    class="grid grid-cols-1 grid-rows-1 space-y-4 py-4 lg:grid-cols-3 lg:gap-4 lg:space-y-0"
  >
    <!-- Dex -->
    <BaseCard
      class="col-span-1 h-96 overflow-y-hidden rounded text-textColor shadow-inner shadow-gray-500/10 text-lg"
    >
      <template #header
        >DEX
        <Tooltip text="DEX data provided by DeFiLlama" class="ml-1">
          <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
        </Tooltip>
      </template>
      <div>
        <div
          class="wrap flex h-full flex-shrink-0 flex-grow flex-col rounded-lg text-xs"
        >
          <div class="max-h-80 flex-1 overflow-x-auto overflow-y-auto shadow">
            <div class="font-semibold" style="color: #a3a3a3">
              <Table
                :config="dexConfig"
                :data="_.orderBy(filteredDexData, 'trading_volume', 'desc')"
                :key="selectedDexFilter"
              ></Table>
            </div>
          </div>
        </div>
      </div>
    </BaseCard>
    <!-- Dex chart -->
    <BaseCard
      class="col-span-2 rounded text-textColor shadow-inner shadow-gray-500/10"
    >
      <template #header
        >DEX Trading Volume
        <Tooltip
          text="DEX Trading Volume data provided by DeFiLlama"
          class="ml-1"
        >
          <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
        </Tooltip>
      </template>
      <template #controls>
        <BaseRadio
          v-model="selectedDexFilter"
          multiple
          button-width-class="w-32"
          :options="dexFilter"
          placeholder="Select Type"
        />
      </template>
      <div
        class="flex h-80 flex-shrink flex-grow flex-col overflow-x-auto rounded-lg"
        :class="stackLoading ? 'flex' : ''"
      >
        <IconLoading class="m-auto h-8 w-8" v-if="stackLoading" />
        <BarGraph
          :data-series="dexTradingChartData"
          :config="stackChartConfig"
          :tooltip-titlize="false"
          v-else
        />
      </div>
    </BaseCard>
  </section>
</template>
<script setup>
import { ref, onMounted, computed, inject } from 'vue';
import Table from '../../../Shared/Table.vue';
import Tooltip from '../../../Shared/Tooltip.vue';
import BarGraph from '../../../Widgets/BarChart.vue';
import IconLoading from '../../../icons/IconLoading.vue';
import BaseCard from '../../../Shared/BaseCard.vue';
import BaseRadio from '../../../Shared/BaseRadio.vue';
import {
  filterToDateTime,
  getRandomId,
} from '../../../composeables/filters.js';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import moment from 'moment';
import _ from 'lodash';
import { DefiApi } from '../DefiApi';
const api = new DefiApi();

onMounted(() => {
  fetchDexChartData();
});

const universeDefiTokens = inject('universeDefiTokens');

const dexFilter = ref(['7D', '30D', '3M']);
const selectedDexFilter = ref('30D');

const dexConfig = computed(() => {
  return {
    cols: [
      {
        type: 'defi',
        name: 'DEX',
        id: 'name',
        textLeft: 'left',
        openLink: true,
      },
      {
        type: 'dollar',
        name: 'Trading Volume',
        id: 'trading_volume',
        numbersLimit: 2,
      },
      {
        type: 'percent',
        name: `Change ${selectedDexFilter.value}`,
        id: 'trading_volume_percent',
        numbersLimit: 2,
      },
    ],
    paddingLeftRight: 'px-3',
  };
});

const dexData = ref([]);
async function fetchDexChartData() {
  dexData.value = await api.fetchDexChartData();
}
const dexTokens = computed(() => {
  let data = [];
  dexData.value?.forEach((x) => {
    data = [...Object?.keys(x[1]), ...data];
  });
  return _.uniq(data);
});
const filteredDexData = computed(() => {
  let data =
    filteredDexChartData(filterToDateTime(selectedDexFilter.value, true) * 2) ||
    [];
  return data.map((x) => {
    // let new_tv = x.data.slice(-1)[0][1];
    // let old_tv = x.data[0][1];
    let new_tv = x.data
      .slice(-filterToDateTime(selectedDexFilter.value, true))
      ?.reduce((partialSum, a) => partialSum + a[1], 0);
    let old_tv = x.data
      .slice(0, filterToDateTime(selectedDexFilter.value, true))
      ?.reduce((partialSum, a) => partialSum + a[1], 0);
    return {
      name: x?.name,
      image: x?.image,
      trading_volume: new_tv,
      trading_volume_percent: new_tv / old_tv - 1,
      link: x.ticker,
    };
  });
});

const stackLoading = ref(false);
const stackChartConfig = ref({
  useUTC: true,
  plotOptions: {
    series: {
      stacking: 'normal',
      animation: false,
    },
    column: {
      pointPadding: 0.1,
      borderWidth: 0,
    },
  },
  type: 'column',
  height: 296,
  legend: {
    enabled: false,
  },
  colors: [
    '#3fd3f0',
    '#4871F8',
    '#A464E4',
    '#F780D0',
    '#FFa366',
    '#3a68ff',
    '#41da5b',
    '#5146b8',
    '#1ffce3',
    '#913831',
  ],
  xAxis: {
    labels: {
      enabled: true,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
      },
      formatter: function () {
        return moment(this.value).utc().format('MMM DD');
      },
    },
    type: 'datetime',
    lineWidth: 0,
    tickLength: 0,
  },
  yAxis: {
    gridLineColor: '#262626',
    title: {
      text: '',
    },
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 350,
        },
        chartOptions: {
          plotOptions: {
            column: {
              pointPadding: 0.1,
              borderWidth: 0,
            },
          },
        },
      },
    ],
  },
});
const dexTradingChartData = computed(() => {
  return filteredDexChartData(filterToDateTime(selectedDexFilter.value, true));
});
function filteredDexChartData(recordLength) {
  let tokens = universeDefiTokens.value || [];
  let data = dexTokens.value?.map((x) => {
    let token = tokens.find(t => {
      let uid = t.defi_uid == "trader-joe" ? "lfj" : t.defi_uid == "curve_finance" ? "curve" : t.defi_uid
      if (uid == x) return t
    });
    return {
      uid: x,
      name: token?.name,
      image: token?.icon,
      ticker: token?.ticker,
      data: [],
    };
  });
  dexData.value?.slice(-recordLength).forEach((x) => {
    Object.keys(x[1]).forEach((y) => {
      let token = data.find((t) => t.uid == y);
      token.data.push([parseInt(x[0]) * 1000, parseFloat(x[1][y])]);
    });
  });
  return data;
}
</script>
