<template>
  <BaseCard>
    <div class="grid grid-cols-4 justify-center rounded-lg py-3">
      <div class="col-span-1 pt-6 2xl:pt-0">
        <img
          :src="collection.image_url"
          @error="available = false"
          v-if="collection?.image_url && available"
          class="rounded-lg md:w-10/12"
        />
        <span
          v-else
          class="flex h-full w-full items-center justify-center rounded-full bg-grid"
        >
          <PhotoIcon class="h-8 w-8 text-gray-500" />
        </span>
      </div>
      <div class="col-span-3">
        <div>
          <div
            class="my-auto flex items-center justify-end space-x-1 md:space-x-3"
          >
            <a
              v-for="item in marketplaceLists"
              :key="item.id"
              class="cursor-pointer"
              :href="item.url"
              target="_blank"
            >
              <img :src="item.image" alt="" class="h-6 w-6 rounded-full" />
            </a>
          </div>
        </div>
        <div class="2x:pb-0 pb-3">
          <div class="flex justify-between">
            <div
              class="text-2xl font-semibold capitalize leading-none text-gray-100"
            >
              {{ collection.collection_name }}
            </div>
          </div>
          <span
            class="mt-1 flex space-x-1 text-sm font-medium text-gray-400"
            v-if="collection?.collection"
          >
            <span>{{ address }}</span>
            <a
              :href="`https://snowtrace.io/address/${collection.collection}`"
              class="flex"
              target="_blank"
            >
              <ArrowTopRightOnSquareIcon class="w-4 cursor-pointer" />
            </a>
          </span>
          <p
            class="mt-1 flex font-semibold line-clamp-2"
            :title="collection.collection_description"
          >
            {{ collection.collection_description }}
          </p>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script setup>
import IconCoin from '../icons/IconCoin.vue';
import { PhotoIcon } from '@heroicons/vue/20/solid';
import {
  rounded,
  percentage,
  formatNumber,
  truncate,
} from '../composeables/filters.js';
import { ref, markRaw, computed } from 'vue';
import SocialIcons from './SocialIcons.vue';
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid';
import Dropdown from './Dropdown.vue';
import { useStore } from 'vuex';
import BaseCard from './BaseCard.vue';
const store = useStore();

const available = ref(true);
const props = defineProps({
  collection: {
    type: Object,
    default: null,
  },
});
const address = computed(() => {
  let address = props.collection.collection;
  return truncate(address, 5) + address?.slice(address.length - 3);
});
const dropdownItems = ref([
  {
    id: 1,
    title: 'Joepegs',
    url: `https://joepegs.com/collections/${props.collection?.collection}`,
    image: store.getters.marketplaceImageBaseLink + 'joepegs.png',
  },
  {
    id: 2,
    title: 'Campfire',
    url: `https://campfire.exchange/collections/${props.collection?.collection}`,
    image: store.getters.marketplaceImageBaseLink + 'campfire.png',
  },
  {
    id: 3,
    title: 'Kalao',
    url: `https://marketplace.kalao.io/collection/${props.collection?.collection}`,
    image: store.getters.marketplaceImageBaseLink + 'kalao.png',
  },
  {
    id: 4,
    title: 'NFTrade',
    url: `https://nftrade.com/assets/avalanche/${props.collection?.collection}`,
    image: store.getters.marketplaceImageBaseLink + 'nftrade.png',
  },
  {
    id: 5,
    title: 'Opensea',
    url: `https://opensea.io/assets?search[query]=${props.collection?.collection}`,
    image: store.getters.marketplaceImageBaseLink + 'opensea.png',
  },
  {
    id: 6,
    title: 'Hyperspace',
    url: `https://avax.hyperspace.xyz/collection/avax/${props.collection?.collection}`,
    image: store.getters.marketplaceImageBaseLink + 'hyperspace.png',
  },
]);

const marketplaceLists = computed(() => {
  if (dropdownItems.value && props.collection?.collection_name == 'Chikn') {
    dropdownItems.value.push({
      id: 6,
      title: 'Chikn',
      url: `https://chikn.farm`,
      image: store.getters.marketplaceImageBaseLink + 'chikn.png',
    });
  }
  return dropdownItems.value;
});
</script>
