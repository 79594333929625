<template>
  <div class="flex space-x-4 justify-end" v-if="!dummy">
    <IconLink
      class="cursor-pointer text-gray-400/80 hover:text-gray-300"
      @click="openUrl(links?.homepage)"
      v-if="links?.homepage"
    />
    <IconDiscord
      class="cursor-pointer text-gray-400/80 hover:text-gray-300"
      @click="openUrl(getDiscordLink(links))"
      v-if="getDiscordLink(links)"
    />
    <IconReddit
      class="cursor-pointer text-gray-400/80 hover:text-gray-300"
      @click="openUrl(links?.subreddit_url)"
      v-if="links?.subreddit_url"
    />
    <IconTelegram
      class="cursor-pointer text-gray-400/80 hover:text-gray-300"
      @click="
        openUrl(
          links?.telegram_channel_identifier
            ? `https://t.me/${links.telegram_channel_identifier}`
            : '',
        )
      "
      v-if="links?.telegram_channel_identifier"
    />
    <IconYoutube
      class="cursor-pointer text-gray-400/80 hover:text-gray-300"
      v-if="links?.youtube"
    />
    <IconInsta
      class="cursor-pointer text-gray-400/80 hover:text-gray-300"
      v-if="links?.insta"
    />
    <IconFacebook
      class="cursor-pointer text-gray-400/80 hover:text-gray-300"
      @click="
        openUrl(
          links?.facebook_username
            ? `https://facebook.com/${links.facebook_username}`
            : '',
        )
      "
      v-if="links?.facebook_username"
    />
  </div>
  <div class="flex space-x-4 justify-end" v-else>
    <IconLink class="cursor-pointer text-gray-400/80 hover:text-gray-300" />
    <IconDiscord class="cursor-pointer text-gray-400/80 hover:text-gray-300" />
    <IconReddit class="cursor-pointer text-gray-400/80 hover:text-gray-300" />
    <IconTelegram class="cursor-pointer text-gray-400/80 hover:text-gray-300" />
    <IconYoutube class="cursor-pointer text-gray-400/80 hover:text-gray-300" />
    <IconInsta class="cursor-pointer text-gray-400/80 hover:text-gray-300" />
    <IconFacebook class="cursor-pointer text-gray-400/80 hover:text-gray-300" />
  </div>
</template>
<script setup>
import IconLink from '../icons/IconLink.vue';
import IconDiscord from '../icons/IconDiscord.vue';
import IconTelegram from '../icons/IconTelegram.vue';
import IconYoutube from '../icons/IconYoutube.vue';
import IconInsta from '../icons/IconInsta.vue';
import IconFacebook from '../icons/IconFacebook.vue';
import IconReddit from '../icons/IconReddit.vue';

const props = defineProps({
  links: {
    type: Object,
    default: {},
  },
  dummy: {
    type: Boolean,
    default: false,
  },
});
function openUrl(link) {
  if (link) {
    window.open(link, '_blank');
  }
}
function getDiscordLink(links) {
  return links?.chat_url && links.chat_url?.includes('discord')
    ? links?.chat_url
    : links?.official_forum_url?.includes('discord')
    ? links.official_forum_url
    : '';
}
</script>
