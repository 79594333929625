import axios from 'axios';
export class TokenApi {
  async reqMethod(path, query) {
    try {
      return [(await axios.get(path, { params: query })).data, null];
    } catch {
      return [null, 'Unknown error, please try again later.'];
    }
  }

  async fetchChartData(urlParam, query) {
    const [res, error] = await this.reqMethod(
      `/tokens/${urlParam}/chart_data`,
      query,
    );

    return error ? error : res;
  }

  async fetchNews(urlParam) {
    const [res, error] = await this.reqMethod(`/tokens/${urlParam}/news`);

    return error ? error : res;
  }

  async fetchTradePairs(urlParam) {
    const [res, error] = await this.reqMethod(
      `/tokens/${urlParam}/trade_pairs`,
    );

    return res || [];
  }

  async fetchStats(urlParam) {
    const [res, error] = await this.reqMethod(`/tokens/${urlParam}/stats`);
    return res || [];
  }

  calculateChange(newPrice, oldPrice) {
    newPrice = parseFloat(newPrice) || 0;
    oldPrice = parseFloat(oldPrice) || 0;
    return oldPrice == 0 ? 0 : (newPrice - oldPrice) / oldPrice;
  }

  calculateChangeWithOtherCoin(tokenPrice, otherTokenPrice) {
    tokenPrice = parseFloat(tokenPrice) || 0;
    otherTokenPrice = parseFloat(otherTokenPrice) || 0;
    return (1 + tokenPrice) / (1 + otherTokenPrice) - 1;
  }
}
