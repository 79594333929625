// This file is automatically compiled by Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { createApp, h } from 'vue/dist/vue.esm-bundler';

import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
InertiaProgress.init();

import HighchartsVue from 'highcharts-vue';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import Vue3Marquee from 'vue3-marquee';
import 'vue3-marquee/dist/style.css';
import store from '../store/index';
import VueGtag from 'vue-gtag';
import Vue3Lottie from 'vue3-lottie';

const pages = import.meta.globEagerDefault('../Pages/**/*.vue');
import App from '../Shared/App.vue';

const googleAnalyticsId =
  window.location.hostname == 'frostymetrics.com' ||
  window.location.hostname == 'avalanche.thetie.io'
    ? 'G-86229JCK2W' // production
    : 'G-9RCESD3M94'; // staging

createInertiaApp({
  resolve: (name) => {
    const component = pages[`../Pages/${name}.vue`];
    if (!component)
      throw new Error(
        `Unknown page ${name}. Is it located under Pages with a .vue extension?`,
      );
    component.layout = component.layout || App;
    return component;
  },

  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(store)
      .use(Vue3Marquee)
      .use(plugin)
      .use(HighchartsVue)
      .use(FloatingVue)
      .use(Vue3Lottie)
      .use(VueGtag, {
        config: { id: googleAnalyticsId },
      })
      .mount(el);
  },
});
