import axios from 'axios';
export class DashboardApi {
  async reqMethod(path, query = {}) {
    try {
      return [(await axios.get(path, { params: query })).data, null];
    } catch {
      return [null, 'Unknown error, please try again later.'];
    }
  }

  async fetchEcosystemData() {
    const [res, error] = await this.reqMethod(`/gaming/ecosystem_data`);

    return error ? error : res;
  }

  async fetchGamingData() {
    const ignoreList = ['paradise tycoon', 'castle crush', 'gunfire'];
    const [res, error] = await this.reqMethod(`/gaming/gaming_data`);
    return error
      ? error
      : res.filter((game) => !ignoreList.includes(game.game));
  }

  async fetchGamingMarketCapData() {
    const ignoreList = ['paradise tycoon', 'castle crush', 'gunfire'];
    const [res, error] = await this.reqMethod(`/gaming/gaming_market_cap`);
    return error ? error : res;
  }

  async fetchNftData() {
    const ignoreList = ['paradise tycoon', 'castle crush', 'gunfire'];
    const [res, error] = await this.reqMethod(`/gaming/nft_data`);
    return error ? error : res.filter((x) => !ignoreList.includes(x.game));
  }

  async fetchNews() {
    const [res, error] = await this.reqMethod(`/gaming/news`);

    return error ? error : res;
  }

  async fetchEcosystemActivityData() {
    const [res, error] = await this.reqMethod(`/dashboard/ecosystem_activity`);

    return error ? error : res;
  }

  async fetchEcosystemHighlightsData() {
    const [res, error] = await this.reqMethod(
      `/dashboard/ecosystem_highlights`,
    );

    return error ? error : res;
  }

  async fetchTvlChartData() {
    const [res, error] = await this.reqMethod(`/dashboard/mini_tvl_chart`);

    return error ? error : res;
  }

  async fetchSocialStatChartData() {
    const [res, error] = await this.reqMethod(`/dashboard/social_stats_chart`);

    return error ? error : res;
  }

  async fetchDevelopmentStatChartData() {
    const [res, error] = await this.reqMethod(
      `/dashboard/development_stats_chart`,
    );

    return error ? error : res;
  }
}
