<template>
  <svg
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon" filter="url(#filter0_d_1951_57887)">
      <path
        id="Vector"
        opacity="0.2"
        d="M15.5 3.5V13.5C15.5 13.6326 15.4473 13.7598 15.3536 13.8536C15.2598 13.9473 15.1326 14 15 14H5C4.86739 14 4.74021 13.9473 4.64645 13.8536C4.55268 13.7598 4.5 13.6326 4.5 13.5V3.5C4.5 3.36739 4.55268 3.24021 4.64645 3.14645C4.74021 3.05268 4.86739 3 5 3H15C15.1326 3 15.2598 3.05268 15.3536 3.14645C15.4473 3.24021 15.5 3.36739 15.5 3.5Z"
        fill="currentColor"
      />
      <path
        id="Vector_2"
        d="M12.8538 6.64625C12.9002 6.69269 12.9371 6.74783 12.9623 6.80853C12.9874 6.86923 13.0004 6.93429 13.0004 7C13.0004 7.06571 12.9874 7.13077 12.9623 7.19147C12.9371 7.25217 12.9002 7.30731 12.8538 7.35375L9.35375 10.8538C9.30731 10.9002 9.25217 10.9371 9.19147 10.9623C9.13077 10.9874 9.06571 11.0004 9 11.0004C8.93429 11.0004 8.86923 10.9874 8.80853 10.9623C8.74783 10.9371 8.69269 10.9002 8.64625 10.8538L7.14625 9.35375C7.05243 9.25993 6.99972 9.13268 6.99972 9C6.99972 8.86732 7.05243 8.74007 7.14625 8.64625C7.24007 8.55243 7.36732 8.49972 7.5 8.49972C7.63268 8.49972 7.75993 8.55243 7.85375 8.64625L9 9.79313L12.1462 6.64625C12.1927 6.59976 12.2478 6.56288 12.3085 6.53772C12.3692 6.51256 12.4343 6.49961 12.5 6.49961C12.5657 6.49961 12.6308 6.51256 12.6915 6.53772C12.7522 6.56288 12.8073 6.59976 12.8538 6.64625ZM16 3.5V13.5C16 13.7652 15.8946 14.0196 15.7071 14.2071C15.5196 14.3946 15.2652 14.5 15 14.5H5C4.73478 14.5 4.48043 14.3946 4.29289 14.2071C4.10536 14.0196 4 13.7652 4 13.5V3.5C4 3.23478 4.10536 2.98043 4.29289 2.79289C4.48043 2.60536 4.73478 2.5 5 2.5H15C15.2652 2.5 15.5196 2.60536 15.7071 2.79289C15.8946 2.98043 16 3.23478 16 3.5ZM15 13.5V3.5H5V13.5H15Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1951_57887"
        x="-2"
        y="0.5"
        width="24"
        height="24"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.01 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1951_57887"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1951_57887"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
