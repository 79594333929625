<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.0002 5H11.0002V3.5C11.0002 2.70435 10.6842 1.94129 10.1216 1.37868C9.55896 0.81607 8.79589 0.5 8.00024 0.5C7.20459 0.5 6.44153 0.81607 5.87892 1.37868C5.31631 1.94129 5.00024 2.70435 5.00024 3.5V5H3.00024C2.73503 5 2.48067 5.10536 2.29314 5.29289C2.1056 5.48043 2.00024 5.73478 2.00024 6V13C2.00024 13.2652 2.1056 13.5196 2.29314 13.7071C2.48067 13.8946 2.73503 14 3.00024 14H13.0002C13.2655 14 13.5198 13.8946 13.7074 13.7071C13.8949 13.5196 14.0002 13.2652 14.0002 13V6C14.0002 5.73478 13.8949 5.48043 13.7074 5.29289C13.5198 5.10536 13.2655 5 13.0002 5ZM6.00024 3.5C6.00024 2.96957 6.21096 2.46086 6.58603 2.08579C6.9611 1.71071 7.46981 1.5 8.00024 1.5C8.53068 1.5 9.03938 1.71071 9.41446 2.08579C9.78953 2.46086 10.0002 2.96957 10.0002 3.5V5H6.00024V3.5ZM13.0002 13H3.00024V6H13.0002V13ZM8.75024 9.5C8.75024 9.64834 8.70626 9.79334 8.62385 9.91668C8.54143 10.04 8.4243 10.1361 8.28726 10.1929C8.15021 10.2497 7.99941 10.2645 7.85393 10.2356C7.70844 10.2066 7.5748 10.1352 7.46991 10.0303C7.36502 9.92544 7.29359 9.7918 7.26466 9.64632C7.23572 9.50083 7.25057 9.35003 7.30733 9.21299C7.3641 9.07594 7.46023 8.95881 7.58357 8.8764C7.7069 8.79399 7.85191 8.75 8.00024 8.75C8.19916 8.75 8.38992 8.82902 8.53057 8.96967C8.67123 9.11032 8.75024 9.30109 8.75024 9.5Z"
      fill="#F3F2F6"
      fill-opacity="0.24"
    />
  </svg>
</template>
