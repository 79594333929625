import axios from 'axios';
export class NftApi {
  async reqMethod(path, query = {}) {
    try {
      return [(await axios.get(path, { params: query })).data, null];
    } catch {
      return [null, 'Unknown error, please try again later.'];
    }
  }

  async fetchTopTrades() {
    const [res, error] = await this.reqMethod(`/nfts/top_trades`);

    return error ? error : res;
  }

  async fetchTopNfts(query) {
    const [res, error] = await this.reqMethod(`/nfts/top_nfts`, query);

    return error ? error : res;
  }

  async fetchMarketPlacesPie(query) {
    const [res, error] = await this.reqMethod(`/nfts/market_places_pie`, query);

    return error ? error : res;
  }

  async fetchMarketPlacesOverviewGraph(query) {
    const [res, error] = await this.reqMethod(
      `/nfts/market_places_overview_graph`,
      query,
    );

    return error ? error : res;
  }

  async fetchTopCollections(query) {
    const [res, error] = await this.reqMethod(`/nfts/top_collections`, query);

    return error ? error : res;
  }

  async fetchSearch(query) {
    const [res, error] = await this.reqMethod(`/nfts/search`, query);

    return error ? error : res;
  }

  async fetchHolderDistribution(collection) {
    const [res, error] = await this.reqMethod(
      `/nfts/${collection}/collection_holder_distribution`,
    );

    return error ? error : res;
  }

  async fetchCollectionLatestTraders(collection) {
    const [res, error] = await this.reqMethod(
      `/nfts/${collection}/collection_latest_traders`,
    );
    return error ? error : res;
  }

  async fetchCollectionTopHolders(collection) {
    const [res, error] = await this.reqMethod(
      `/nfts/${collection}/collection_top_holders`,
    );
    return error ? error : res;
  }

  async fetchTks(collection, params) {
    const [res, error] = await this.reqMethod(
      `/nfts/${collection}/tks`,
      params,
    );

    return error ? error : res;
  }

  async fetchCollectionActivity(collection) {
    const [res, error] = await this.reqMethod(
      `/nfts/${collection}/collection_activity_n_floor`,
    );

    return error ? error : res;
  }

  async fetchCollectionTokens(collection, params) {
    const [res, error] = await this.reqMethod(
      `/nfts/${collection}/collection_tokens`,
      params,
    );
    return error ? error : res;
  }
  async fetchMarketPlaces(query) {
    const [res, error] = await this.reqMethod(`/nfts/market_places`, query);
    return error ? error : res;
  }
}
