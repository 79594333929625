<template>
  <div
    class="flex items-center justify-center px-4"
    :style="`height: ${innerHeight}px`"
  >
    <div class="flex h-32 w-full flex-col rounded-lg p-4 sm:w-1/3 md:w-1/5">
      <span
        class="flex items-center space-x-1 font-bold text-gray-300 text-2xl"
      >
        <InformationCircleIcon class="h-6 w-6" /> <span>Opps...</span>
      </span>
      <span class="text-gray-400"> {{ message }}</span>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { InformationCircleIcon } from '@heroicons/vue/20/solid';
const props = defineProps({
  message: String,
});
const innerHeight = ref(window.innerHeight);
onMounted(async () => {
  innerHeight.value -= 270;
});
</script>
