<template>
  <div>
    <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { formatNumber, formatBigPricing } from '../composeables/filters.js';
import { min, max } from 'lodash';
import moment from 'moment';

const props = defineProps({
  series: {
    type: Object,
    default: () => {},
  },
  config: {
    type: Object,
    default: () => {},
  },
  type: {
    type: String,
    default: () => 'line',
  },
  completeData: {
    type: Boolean,
    default: () => false,
  },
});
const chartOptions = computed(() => {
  return {
    chart: {
      backgroundColor: 'transparent',
      type: props.config?.type || 'line',
      animation: false,
      height: props.config?.height || '365',
    },
    title: {
      text: '',
    },
    series: props.completeData
      ? props.series
      : [
          {
            name: props.config?.name || 'Price',
            data: props.series,
            animation: false,
          },
        ],
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        formatter: function () {
          return formatBigPricing(this.value);
        },
      },
      gridLineColor: '#262626',
      min: axisMinMaxValue.value.min,
      max: axisMinMaxValue.value.max,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        enabled: true,
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
        formatter: function () {
          return props.config?.useUTC
            ? moment(this.value).utc().format('MMM DD')
            : moment(this.value).format('MMM DD');
        },
      },
      lineWidth: 0,
      tickLength: 0,
    },
    colors: props.config?.colors || {},
    legend: legend.value,
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: 'transparent',
      borderWidth: 0,
      color: '#ffff',
      style: {
        color: '#ffff',
      },
      formatter() {
        let title = this.x;
        title = typeof title === 'string' ? parseInt(title) : title;
        let datetime = props.config?.useUTC
          ? moment(title).utc().format('MMM DD, YYYY')
          : moment(title).format('MMM DD, YYYY');
        const tipHtml = `<div><b>${datetime}</b></div>`;
        const formattedPoints = this.points
          .map((point) => {
            const roundTo = point.y > 1 ? 2 : 6;
            return `<div class="py-1">
                      <span>${point.series.name}:</span>
                      <span style="color:${
                        point.color
                      }; margin-left: 0.5rem;">${formatNumber(
              point.y,
              roundTo,
            )}</span>
            </div>`;
          })
          .join('');
        return `<div class='custom-tooltip'> ${
          tipHtml + formattedPoints
        }</div>`;
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: props.config?.plotOptions || {
      series: {
        color: props.config?.lineColor || '#3B82F6',
        marker: {
          enabled: false,
        },
        fillOpacity: props.config?.opacity || 0,
        fillColor: props.config?.fillColor || '#3B82F6',
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 350,
          },
          chartOptions: {
            chart: {
              height: '290',
            },
          },
        },
      ],
    },
  };
});
const legend = computed(() => {
  return (
    props.config?.legend || {
      layout: 'none',
      align: 'right',
      verticalAlign: 'middle',
      enabled: false,
    }
  );
});
const axisMinMaxValue = computed(() => {
  let sortedData =
    props.series?.length > 0 ? props.series?.map((x) => x[1]) : null;
  return {
    min: sortedData ? min(sortedData) : null,
    max: sortedData ? max(sortedData) : null,
  };
});
</script>
