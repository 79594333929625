<template>
  <div class="space-y-4 py-4" id="topNFTsContainer">
    <TopNft />

    <TopCollection />

    <MarketPlace class="border-y border-gray-800/50 py-4" />

    <section>
      <div
        class="w-full gap-4 space-y-4 md:grid md:grid-cols-2 md:space-y-0 lg:grid-cols-3"
      >
        <GraphCard
          type="pie"
          title="Transactions"
          :sort-by="{ column: 'y', order: 'desc' }"
          :data-series="marketPlacesTransactions"
          page="nft"
          tooltipText="Transactions"
          @filter-changed-emit="newFilterVal"
        />
        <GraphCard
          type="pie"
          title="Unique Wallets"
          :sort-by="{ column: 'y', order: 'desc' }"
          :data-series="marketPlacesUniqeWallets"
          page="nft"
          tooltipText="Unique Wallets"
          @filter-changed-emit="newFilterVal"
        />
        <GraphCard
          type="pie"
          title="Volume ($)"
          :sort-by="{ column: 'y', order: 'desc' }"
          :data-series="marketPlacesVolume"
          page="nft"
          tooltipText="Volume ($)"
          @filter-changed-emit="newFilterVal"
        />
      </div>
    </section>
  </div>
</template>
<script setup>
import TopNft from './TopNft.vue';
import { ref, onMounted, watch, computed } from 'vue';
import GraphCard from '../../Shared/GraphCard.vue';
import MarketPlace from './MarketPlace.vue';
import TopCollection from './TopCollection.vue';
import { NftApi } from '../Nfts/NftApi';
const api = new NftApi();

const marketPlacesTransactions = ref([]);
const marketPlacesUniqeWallets = ref([]);
const marketPlacesVolume = ref([]);
const marketPlacesPie = ref([]);
const selectedFilter = ref('1D');
const graphTitle = ref('');

onMounted(() => {
  Promise.all([fetchMarketPlacesPie()]);
});

watch([selectedFilter, graphTitle], async () => {
  await fetchMarketPlacesPie();
});

async function fetchMarketPlacesPie() {
  let selectedTimeFrame =
    selectedFilter.value == '1D'
      ? '1day'
      : selectedFilter.value == '7D'
      ? '7day'
      : '30day';
  marketPlacesPie.value = await api.fetchMarketPlacesPie({
    order_by: selectedTimeFrame,
  });
  if (graphTitle.value?.length > 0) {
    if (graphTitle.value == 'Transactions') {
      marketPlacesTransactions.value = pieChartData('num_trxs');
    } else if (graphTitle.value == 'Unique Wallets') {
      marketPlacesUniqeWallets.value = pieChartData('unique_users');
    } else {
      marketPlacesVolume.value = pieChartData('volume');
    }
  } else {
    marketPlacesTransactions.value = pieChartData('num_trxs');
    marketPlacesUniqeWallets.value = pieChartData('unique_users');
    marketPlacesVolume.value = pieChartData('volume');
  }
}

function pieChartData(column) {
  let notNullSortData = marketPlacesPie.value.filter(
    (arg) => arg.collection_name,
  );
  notNullSortData = notNullSortData.sort(function (x, y) {
    return y[column] - x[column];
  });

  let topTen = notNullSortData.slice(0, 9);
  let others = {
    collection_name: 'Others',
    num_trxs: 0,
    volume: 0,
    unique_users: 0,
  };

  notNullSortData.slice(10, notNullSortData.length).forEach((data) => {
    others[column] = others[column] + data[column];
  });
  topTen.push(others);
  const sum = topTen.reduce((a, object) => {
    return a + object[column];
  }, 0);
  topTen = topTen.map((x) => {
    x[`${column}_change`] = x[column] / sum;
    return x;
  });
  return topTen;
}

function newFilterVal(e) {
  selectedFilter.value = e.filterVal;
  graphTitle.value = e.title;
}
</script>
