<template>
  <BaseCard>
    <div class="flex h-full w-full flex-shrink justify-between rounded-lg py-4">
      <div class="flex">
        <span
          class="flex items-center space-x-2.5 text-base font-medium text-gray-100"
          :class="`${clickable ? 'cursor-pointer' : ''}`"
          @click="openLink"
        >
          <img
            :src="token.icon"
            class="h-5 rounded-full"
            loading="lazy"
            alt=""
            v-if="token.icon"
          />
          <IconCoin class="my-auto flex" v-else />
          <div class="flex flex-col">
            <span class="whitespace-nowrap text-base">{{ token.name }}</span>
            <span class="text-sm text-gray-400">
              {{ token.ticker }}
            </span>
          </div>
        </span>
      </div>
      <div class="flex flex-col">
        <span class="flex justify-end text-base font-semibold text-gray-100"
          >${{ formatNumber(token.price, 6) }}</span
        >
        <span
          :class="`flex justify-end text-sm font-medium ${
            token.price_return_24_hours > 0 ? 'text-green-400' : 'text-red-400'
          }`"
          >{{ percentage(token.price_return_24_hours) }}</span
        >
      </div>
    </div>
  </BaseCard>
</template>
<script setup>
import { Inertia } from '@inertiajs/inertia';
import { formatNumber, rounded, percentage } from '../composeables/filters.js';
import IconCoin from '../icons/IconCoin.vue';
import BaseCard from '../Shared/BaseCard.vue';
function openLink() {
  if (props.clickable) {
    Inertia.visit('/tokens/' + props.token.ticker);
  }
}

const props = defineProps({
  token: Object,
  clickable: {
    type: Boolean,
    default: true,
  },
});
</script>
